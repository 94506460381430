import { Dispatch, FC, SetStateAction, useEffect } from 'react'
import {
  UseFormWatch,
  UseFormSetValue,
  UseFormResetField,
  UseFormClearErrors,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Row, Checkbox } from 'antd'
import { NewCaseFormSchemaType } from '@signifyd/http'
import { SelectField, TextField } from '../Fields'
import FormCard from './FormCard'
import { ReactHookFormTypes } from './Card.types'
import { FieldNameType } from '../Fields/Fields.types'
import { CountryOptions } from '../Fields/utils'

const billingFields: Array<Partial<FieldNameType>> = [
  'card.cardHolderName',
  'userAccount.phone',
  'card.billingAddress.streetAddress',
  'card.billingAddress.unit',
  'card.billingAddress.city',
  'card.billingAddress.provinceCode',
  'card.billingAddress.postalCode',
  'card.billingAddress.countryCode',
]

const shippingFields: Array<Partial<FieldNameType>> = [
  'recipient.fullName',
  'recipient.confirmationPhone',
  'recipient.deliveryAddress.streetAddress',
  'recipient.deliveryAddress.unit',
  'recipient.deliveryAddress.city',
  'recipient.deliveryAddress.provinceCode',
  'recipient.deliveryAddress.postalCode',
  'recipient.deliveryAddress.countryCode',
]

interface Props extends ReactHookFormTypes {
  sameShipping: boolean
  setSameShipping: Dispatch<SetStateAction<boolean>>
  watch: UseFormWatch<NewCaseFormSchemaType>
  setValue: UseFormSetValue<NewCaseFormSchemaType>
  resetField: UseFormResetField<NewCaseFormSchemaType>
  clearErrors: UseFormClearErrors<NewCaseFormSchemaType>
}

export const ShippingCard: FC<Props> = ({
  sameShipping,
  setSameShipping,
  watch,
  setValue,
  resetField,
  clearErrors,
  control,
  errors,
}) => {
  const { t } = useTranslation()

  const subscription = watch(billingFields)

  useEffect(() => {
    if (sameShipping) {
      subscription.forEach((value, index) =>
        setValue(shippingFields[index], value)
      )
    }
  }, [sameShipping, setValue, subscription])

  return (
    <FormCard
      title={t('pages.newCase.cards.shipping')}
      extra={
        <Checkbox
          checked={sameShipping}
          onChange={() => {
            setSameShipping(!sameShipping)

            // reset shipping fields if different to billing
            if (sameShipping) {
              shippingFields.forEach((field) => resetField(field))
            } else {
              clearErrors(shippingFields)
            }
          }}
          data-analytics-id="same-shipping-checkbox"
          data-test-id="sameShippingCheckbox"
        >
          {t('pages.newCase.cards.shippingCheckbox')}
        </Checkbox>
      }
    >
      <Row>
        <TextField
          fieldName="recipient.fullName"
          span={12}
          required
          disabled={sameShipping}
          control={control}
          errors={errors}
        />

        <TextField
          fieldName="recipient.confirmationPhone"
          span={12}
          disabled={sameShipping}
          control={control}
          errors={errors}
        />
      </Row>

      <Row>
        <TextField
          fieldName="recipient.deliveryAddress.streetAddress"
          span={20}
          required
          disabled={sameShipping}
          control={control}
          errors={errors}
        />

        <TextField
          fieldName="recipient.deliveryAddress.unit"
          span={4}
          disabled={sameShipping}
          control={control}
          errors={errors}
        />
      </Row>

      <Row>
        <TextField
          fieldName="recipient.deliveryAddress.city"
          span={10}
          required
          disabled={sameShipping}
          control={control}
          errors={errors}
        />

        <TextField
          fieldName="recipient.deliveryAddress.provinceCode"
          span={4}
          disabled={sameShipping}
          control={control}
          errors={errors}
        />

        <TextField
          fieldName="recipient.deliveryAddress.postalCode"
          span={4}
          disabled={sameShipping}
          control={control}
          errors={errors}
        />

        <SelectField
          fieldName="recipient.deliveryAddress.countryCode"
          span={6}
          required
          disabled={sameShipping}
          control={control}
          errors={errors}
          options={CountryOptions}
        />
      </Row>
    </FormCard>
  )
}

export default ShippingCard
