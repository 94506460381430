import { InvestigationInfo } from '@signifyd/http'
import { CaseEntriesResolvedState } from 'store/currentCase/types/case.entries.types'

export enum MENU_SECTION {
  none = 0,
  address,
  accounts,
  phone,
  people,
  network,
  occupants,
  airportLocations,
}

export enum MENU_SECTION_TYPE {
  none = 'none',
  deliveryAddress = 'delivery',
  billingAddress = 'billing',
  confirmationEmail = 'confirmationEmail',
  accountEmail = 'accountEmail',
  cardHolder = 'cardHolder',
  deliveryRecipient = 'deliveryRecipient',
  accountPhone = 'accountPhone',
  confirmationPhone = 'confirmationPhone',
  addressOccupant = 'addressOccupant',
  seller = 'seller',
  leadPassenger = 'leadPassenger',
  passenger = 'passenger',
  airport = 'airport',
}

export enum MENU_ICON {
  GoogleMapIcon = 'GoogleMapIcon',
  GoogleSearchIcon = 'GoogleSearchIcon',
  SigLogoSmall = 'SigLogoSmall',
  PiplIcon = 'PiplIcon',
  EkataIcon = 'EkataIcon',
  WhitepagesIcon = 'WhitepagesIcon',
  LinkedinIcon = 'LinkedinIcon',
}

export type MenuItem = {
  icon: MENU_ICON
  text: string
  url: string
}

export interface DeepLinkFactoryProps {
  caseEntries: CaseEntriesResolvedState
  caseDetails?: InvestigationInfo | null
  type?: MENU_SECTION_TYPE
  value?: string
  hasPowersearch?: boolean
}

export type DeepLinkFactory<T = DeepLinkFactoryProps> = (
  props: T
) => Array<MenuItem>

export interface PeopleDeepLinkProps {
  caseEntries: Pick<
    CaseEntriesResolvedState,
    | 'cardHolder'
    | 'accountHolderPhone'
    | 'cardHolderAddress'
    | 'confirmationPhone'
    | 'deliveryRecipient'
    | 'deliveryRecipientAddress'
    | 'confirmationEmail'
    | 'purchaseIP'
  >
  type?: MENU_SECTION_TYPE
  hasPowersearch?: boolean
}
