import { FC, useState } from 'react'
import { Tabs } from 'antd'
import { StretchToPageBottom } from '@signifyd/components'
import { useTranslation } from 'react-i18next'
import { useStoreState } from 'store'
import styles from './TabbedCaseDisplay.less'
import OrderDetails from '../OrderDetails'
import ReturnDetails from '../ReturnDetails'

const { TabPane } = Tabs

type CaseTabs = 'order' | 'returns'

const TabbedCaseDisplay: FC = () => {
  const { t } = useTranslation()
  const hasReturns = useStoreState(
    (store) => !!store.currentCase.details?.returns?.length
  )
  const [tab, setTab] = useState<CaseTabs>('order')

  return (
    <Tabs
      activeKey={tab}
      onTabClick={(activeKey) => {
        setTab(activeKey as CaseTabs)
      }}
      className={styles.tabs}
    >
      <TabPane tab={t('pages.caseReview.caseTabs.order')} key="order">
        <StretchToPageBottom className={styles.tabContent}>
          <OrderDetails />
        </StretchToPageBottom>
      </TabPane>
      {hasReturns && (
        <TabPane tab={t('pages.caseReview.caseTabs.return')} key="return">
          <StretchToPageBottom className={styles.tabContent}>
            <ReturnDetails />
          </StretchToPageBottom>
        </TabPane>
      )}
    </Tabs>
  )
}

export default TabbedCaseDisplay
