import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { CLAIM_STATUS, CLAIM_DISPOSITION } from '@signifyd/http'
import SummaryItem from '../SummaryItem'

interface Props {
  values: Array<CLAIM_STATUS | CLAIM_DISPOSITION>
}

const ClaimStatusItem: FC<Props> = ({ values }) => {
  const { t } = useTranslation()

  return (
    <SummaryItem
      label={t('filters.claimStatus.label')}
      value={values.map((item) => t(`filters.claimStatus.${item}`)).join(', ')}
    />
  )
}

export default ClaimStatusItem
