import { FC } from 'react'
import { Text } from '@signifyd/components'
import { formatCurrencyNumber } from '@signifyd/utils'
import styles from './ProductDetails.less'
import { OrderProduct } from '../OrderSummary'

interface ProductDetailsProps {
  product: OrderProduct
  showShippingColumn?: boolean
}
const ProductDetails: FC<ProductDetailsProps> = ({
  product,
  showShippingColumn,
}) => {
  const {
    itemName = 'product',
    itemPrice,
    itemQuantity,
    shippingMethod,
  } = product
  const price = formatCurrencyNumber(itemPrice ?? '')

  return (
    <div className={styles.productDetails}>
      <Text
        weight="semibold"
        className={styles.item}
        data-test-id={`price-${itemName}-${itemQuantity}`}
      >
        {price}
      </Text>
      <Text
        weight="semibold"
        className={styles.item}
        data-test-id={`quantity-${itemName}`}
      >
        {itemQuantity}
      </Text>
      {showShippingColumn && (
        <Text
          weight="semibold"
          className={styles.item}
          data-test-id={`shippingColumn-${product.itemName}`}
        >
          {shippingMethod}
        </Text>
      )}
    </div>
  )
}

export default ProductDetails
