import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Modal } from 'antd'
import moment, { Moment } from 'moment-timezone'
import { FULFILLMENT_STATUS, SHIPPING_CARRIER } from '@signifyd/http'
import { FormComponentProps } from '@ant-design/compatible/lib/form'
import { COMMON_MODAL_PROPS } from 'core/constants'
import { useCreateFulfillment } from 'core/hooks/useCreateFulfillment'
import CaseClaimsBannerFulfillmentForm from './CaseClaimsBannerFulfillmentForm'

interface FormValues extends FormComponentProps {
  trackingNumbers: string
  shippingCarrier: SHIPPING_CARRIER
  fulfillmentStatus: FULFILLMENT_STATUS
  createdAt: Moment
}

interface Props {
  orderId: string
  investigationId: number
}

export const CaseClaimsBannerFulfillment: FC<Props> = ({
  orderId,
  investigationId,
}) => {
  const { t } = useTranslation()

  const [showModal, setShowModal] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean>(false)
  const [hasChanges, setHasChanges] = useState<boolean>(false)
  const [formRef, setFormRef] = useState<{ props: FormValues }>()

  const resetModal = (): void => {
    setHasErrors(false)
    setHasChanges(false)
    setShowModal(false)
  }

  const { mutate, isLoading } = useCreateFulfillment(resetModal)

  const onSubmitHandler = (): void => {
    return formRef?.props.form.validateFields((err, values) => {
      if (err) {
        return setHasErrors(true)
      }

      return mutate({
        investigationId,
        fulfillmentPayload: {
          fulfillments: [
            {
              id: Math.round(Date.now() / 1000), // Generate ID based on Number of Seconds Since Epoch
              orderId,
              fulfillmentStatus: values.fulfillmentStatus,
              trackingNumbers: [values.trackingNumbers],
              shippingCarrier: values.shippingCarrier,
              createdAt: moment(values.createdAt).format(),
            },
          ],
          /* https://signifyd.slack.com/archives/C04M2NV7V32/p1721309553476939 hard-coded currently unused optional
          parameter from backend. Included it in case it does get used in the future
          */
          fulfillmentsSource: 'FULFILLMENT_FORM',
        },
      })
    })
  }

  return (
    <>
      <Button
        onClick={() => setShowModal(true)}
        type="primary"
        data-test-id="submitFulfillmentInfoButton"
      >
        {t('claimsBanner.fulfillment.submitInfo')}
      </Button>
      <Modal
        {...COMMON_MODAL_PROPS}
        confirmLoading={isLoading}
        title={t('claimsBanner.fulfillment.modal.title')}
        open={showModal}
        okType="link"
        okButtonProps={{ disabled: hasErrors || !hasChanges }}
        okText={t('claimsBanner.fulfillment.modal.okButton')}
        onOk={onSubmitHandler}
        onCancel={() => setShowModal(false)}
      >
        <CaseClaimsBannerFulfillmentForm
          setHasErrors={setHasErrors}
          setHasChanges={setHasChanges}
          wrappedComponentRef={setFormRef}
        />
      </Modal>
    </>
  )
}
export default CaseClaimsBannerFulfillment
