import { useQuery, UseQueryResult } from '@tanstack/react-query'
import {
  FlexibleSearchRequest,
  getAllCanonicalEvents,
  getInvestigationSearch,
  InvestigationInfo,
  SEARCH_FIELD,
  SEARCH_OPERATOR,
  Event,
  INV_SEARCH_VIEW,
} from '@signifyd/http'

interface InvestigationData {
  externalInvestigation: InvestigationInfo
  events: Array<Event>
}

const useGetInvestigationData = (
  investigationId?: string,
  isAdmin?: boolean
): UseQueryResult<InvestigationData> => {
  return useQuery<InvestigationData>(
    ['getInvestigationEvents', investigationId],
    async () => {
      const searchRequest: FlexibleSearchRequest<INV_SEARCH_VIEW.INVESTIGATION> =
        {
          query: {
            field: {
              fieldName: SEARCH_FIELD.investigationId,
              operator: SEARCH_OPERATOR.EQUAL,
              values: investigationId!,
            },
          },
        }
      const investigationPromise = await getInvestigationSearch(searchRequest)
      const eventPromise = await getAllCanonicalEvents(+investigationId!)

      const [
        {
          data: { investigations },
        },
        {
          data: { events },
        },
      ] = await Promise.all([investigationPromise, eventPromise])

      return { externalInvestigation: investigations[0], events }
    },
    {
      enabled: !!investigationId && !!isAdmin,
    }
  )
}

export default useGetInvestigationData
