import { FC } from 'react'
import styles from './TextOverflowWrapper.less'

interface CellProps {
  children: React.ReactNode
  title?: StrOrNum
  maxWidth?: number
  'data-test-id'?: string
}

const TextOverflowWrapper: FC<CellProps> = ({
  children,
  title,
  maxWidth,
  'data-test-id': dataTestId,
}) => {
  return (
    <div
      className={styles.tableCell}
      style={{ maxWidth }}
      title={title?.toString()}
      data-test-id={dataTestId}
    >
      {children}
    </div>
  )
}

export default TextOverflowWrapper
