import { FC } from 'react'
import { CaretUpOutlined } from '@ant-design/icons'
import { Text } from '@signifyd/components'
import { useTranslation } from 'react-i18next'
import styles from './ProductDetails.less'

interface TitleProps {
  setDescending: (descending: boolean) => void
  descending?: boolean
  showShippingColumn: boolean
}
const ProductTitleDetails: FC<TitleProps> = ({
  setDescending,
  descending,
  showShippingColumn,
}) => {
  const { t } = useTranslation()

  return (
    <div className={styles.productDetails}>
      <Text weight="normal" className={styles.item} data-test-id="priceHeader">
        <span
          onClick={() => setDescending(!descending)}
          className={styles.orderSpan}
          role="button"
          tabIndex={0}
        >
          {t('caseSummary.order.price')}

          <CaretUpOutlined
            className={styles.carat}
            rotate={descending ? 180 : 0}
          />
        </span>
      </Text>
      <Text
        weight="normal"
        className={styles.item}
        data-test-id="quantityHeader"
      >
        {t('caseSummary.order.quantity')}
      </Text>
      {showShippingColumn && (
        <Text
          weight="normal"
          className={styles.item}
          data-test-id="shippingColumnHeader"
        >
          {t('caseSummary.order.shippingMethod')}
        </Text>
      )}
    </div>
  )
}

export default ProductTitleDetails
