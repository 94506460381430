import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { HomeOutlined } from '@ant-design/icons'
import { useStoreActions, useStoreState } from 'store'
import { useToggle } from 'core/utils/useToggle'
import { UpdateAddressRequiredFields } from 'store/caseActions'
import UpdateAddressModal from '../../containers/UpdateAddressModal'
import ActionButton from '../ActionButton'

interface Props {
  caseDetails: UpdateAddressRequiredFields
}

export const UpdateAddressButton: FC<Props> = ({ caseDetails }) => {
  const { t } = useTranslation()
  const {
    value: modalVisible,
    enable: showModal,
    disable: hideModal,
  } = useToggle()

  const isLoading = useStoreState(
    (state) => state.caseActions.updateAddress.isLoading
  )
  const { canPerformAction } = useStoreActions(
    (actions) => actions.caseActions.updateAddress
  )
  const actionState = useMemo(
    () => canPerformAction(caseDetails),
    [caseDetails, canPerformAction]
  )

  return (
    <>
      <ActionButton
        onClick={showModal}
        actionState={actionState}
        isLoading={isLoading}
        icon={<HomeOutlined />}
        text={t('caseActions.updateAddress.updateAddressButton')}
        tooltip={t('caseActions.updateAddress.updateAddressButton')}
      />

      <UpdateAddressModal
        caseDetails={caseDetails}
        visible={modalVisible}
        closeModal={hideModal}
      />
    </>
  )
}

export default UpdateAddressButton
