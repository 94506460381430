import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@signifyd/components'
import { DeleteOutlined } from '@ant-design/icons'
import { Tooltip, Popconfirm } from 'antd'
import {
  CaseNote,
  InvestigationInfo,
  NOTE_TYPE,
  USER_TEAM_ROLE,
} from '@signifyd/http'
import { useStoreActions, useStoreState } from 'store'
import FormattedDate from 'core/components/FormattedDate'
import styles from './NoteTimeline.less'

interface Props {
  note: CaseNote
  caseDetails: InvestigationInfo
}

export const NoteTimelineHeader: FC<Props> = ({
  note: { createdByTeam, noteType, createdBy, createdAt, noteId },
  caseDetails,
}) => {
  const { t } = useTranslation()
  const { removeNote } = useStoreActions((actions) => actions.currentCase)
  const hasTeamRole = useStoreState((state) => state.user.hasTeamRole)

  const [pendingDelete, setPendingDelete] = useState(false)

  const getTitle = (type: NOTE_TYPE): string => {
    if (type === NOTE_TYPE.SIGNIFYD_EVENTS) {
      return t('caseActivity.signifyd')
    }
    if (type === NOTE_TYPE.AGENT_NOTES) {
      return t('caseActivity.agent')
    }
    const text = createdByTeam ? createdByTeam.teamName : createdBy

    return text || ''
  }

  const isDeletable =
    noteType === NOTE_TYPE.CUSTOMER_NOTES &&
    !hasTeamRole(caseDetails.teamId, USER_TEAM_ROLE.REVIEWER)

  const titleText = getTitle(noteType)

  const iconClass = (): string => {
    if (pendingDelete) {
      return styles.pendingDelete
    }
    if (isDeletable) {
      return styles.deleteIcon
    }

    return styles.hiddenIcon
  }

  return (
    <div className={styles.itemHeader}>
      <Tooltip placement="topLeft" title={titleText}>
        <Text size="xs" className={styles.left} ellipsis type="secondary">
          {titleText}
        </Text>
      </Tooltip>
      <Tooltip placement="top" title={<FormattedDate date={createdAt} />}>
        <Text size="xs" className={styles.right} type="secondary">
          <FormattedDate date={createdAt} />
        </Text>
      </Tooltip>

      <Popconfirm
        overlayClassName="sig-popconfirm"
        overlayStyle={{ maxWidth: '250px' }}
        placement="left"
        title={
          <>
            {t('caseActivity.confirmDeleteTitle')}
            <br /> {t('caseActivity.undoneWarning')}
          </>
        }
        onConfirm={() =>
          removeNote({ caseId: caseDetails.investigationId, noteId })
        }
        onOpenChange={(open) => setPendingDelete(open)}
        okText={t('caseActivity.deleteNote')}
        cancelText={t('caseActivity.cancelDeleteNote')}
        icon={null}
      >
        <Tooltip placement="top" title={t('caseActivity.deleteNote')}>
          <DeleteOutlined
            className={iconClass()}
            onClick={() => setPendingDelete(true)}
          />
        </Tooltip>
      </Popconfirm>
    </div>
  )
}

export default NoteTimelineHeader
