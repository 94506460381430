import { useEffect, useState } from 'react'
import { getOrdersUrl } from '@signifyd/components'

export const useIsShiftKeyPressed = (): {
  isShiftPressed: boolean
} => {
  const [isShiftPressed, setIsShiftPressed] = useState(false)

  useEffect(() => {
    const handleShiftKeyPress = (e: KeyboardEvent): void => {
      setIsShiftPressed(e.shiftKey)
    }

    document.addEventListener('keydown', handleShiftKeyPress)
    document.addEventListener('keyup', handleShiftKeyPress)

    return () => {
      document.removeEventListener('keydown', handleShiftKeyPress)
      document.removeEventListener('keyup', handleShiftKeyPress)
    }
  }, [])

  return {
    isShiftPressed,
  }
}

export const getInvestigationUrl = (investigationId: number): string => {
  return `${getOrdersUrl()}/${investigationId}`
}
