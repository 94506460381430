import { FC } from 'react'
import { CaseEntriesItem, CaseEntryLocationDetails } from '@signifyd/http'
import { Text } from '@signifyd/components'

interface Props {
  ipGeo: CaseEntriesItem<CaseEntryLocationDetails> | undefined
}

const IPGeo: FC<Props> = ({ ipGeo }) => {
  return <Text>{ipGeo?.entityName}</Text>
}

export default IPGeo
