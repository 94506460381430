export default {
  labels: {
    alternatePhone: 'Alternate phone',
    carrier: 'Carrier',
    phoneType: 'Phone type',
    prepaid: 'Prepaid',
    commercial: 'Commercial',
    id: 'Id',
    name: 'Name',
    type: 'Type',
    age_range: 'Age range',
    gender: 'Sex',
    link_to_phone_start_date: 'Tied since',
    relation: 'Relation',
    unknown: 'Unknown',
    address: 'Address',
    addresses: 'Addresses',
    associatedPeople: 'Associated people',
  },
  data: {
    booleanConversion: {
      true: 'Yes',
      false: 'No',
    },
  },
}
