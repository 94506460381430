import { FC } from 'react'
import { Form } from '@ant-design/compatible'
import { InputNumber } from 'antd'
import { useTranslation } from 'react-i18next'
import { SEARCH_FIELD } from '@signifyd/http'
import { Text } from '@signifyd/components'
import { isNil } from 'lodash'
import { FilterState, RangeFilter } from 'store/search'
import styles from '../FiltersContainer.less'

interface Props {
  currentFilter: RangeFilter<number>
  updateFilterFn: (update: Partial<FilterState>) => void
}

const minLimit = 0
const maxLimit = 1000

const ScoreFilter: FC<Props> = ({
  currentFilter: { min: minScore, max: maxScore },
  updateFilterFn,
}) => {
  const { t } = useTranslation()

  const handleChange = (
    value: number | null | undefined,
    isMin: boolean
  ): void => {
    const normalizedValue = isNil(value) ? null : value

    updateFilterFn({
      [SEARCH_FIELD.signifydScore]: {
        min: isMin ? normalizedValue : minScore,
        max: !isMin ? normalizedValue : maxScore,
      },
    })
  }

  return (
    <Form.Item label={t('filters.score.label')} className={styles.rangeInputs}>
      <InputNumber
        data-test-id="scoreFilterMin"
        data-analytics-id="score-min-filter"
        className={styles.InputNumber}
        placeholder={t('filters.score.min', { minLimit })}
        value={isNil(minScore) ? undefined : minScore}
        onChange={(value) => handleChange(value, true)}
        min={minLimit}
        max={maxLimit}
      />
      <Text disabled className={styles.rangeDivider}>
        {t('filters.placeholder.to')}
      </Text>
      <InputNumber
        data-test-id="scoreFilterMax"
        data-analytics-id="score-max-filter"
        className={styles.InputNumber}
        placeholder={t('filters.score.max', { maxLimit })}
        value={isNil(maxScore) ? undefined : maxScore}
        onChange={(value) => handleChange(value, false)}
        min={minLimit}
        max={maxLimit}
      />
    </Form.Item>
  )
}

export default ScoreFilter
