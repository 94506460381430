import { action, computed } from 'easy-peasy'
import { CaseEntries } from '@signifyd/http'
import { CaseEntriesModel } from './types/case.entries.types'
import {
  findRoleInSection,
  findRolesInSection,
} from './utils/case.entries.utils'

const defaultEntriesState: CaseEntries = {
  accounts: [],
  deviceInfo: [],
  networkLocations: [],
  organizations: [],
  people: [],
  phones: [],
  physicalLocations: [],
}

const caseEntriesModel: CaseEntriesModel = {
  // State
  entries: {
    ...defaultEntriesState,
    // Computed State
    cardHolder: computed((state) => {
      return findRoleInSection(state.people, 'cardHolder')
    }),
    confirmationEmail: computed((state) => {
      return findRoleInSection(state.accounts, 'confirmationEmail')
    }),
    accountEmail: computed((state) => {
      return findRoleInSection(state.accounts, 'accountEmail')
    }),
    confirmationPhone: computed((state) => {
      return findRoleInSection(state.phones, 'confirmationPhone')
    }),
    purchaseIP: computed((state) => {
      return findRoleInSection(state.networkLocations, 'purchaseIP')
    }),
    ipDomain: computed((state) => {
      return findRoleInSection(state.networkLocations, 'ipDomain')
    }),
    emailDomains: computed((state) => {
      return findRolesInSection(state.networkLocations, 'emailDomain')
    }),
    accountHolderPhone: computed((state) => {
      return findRoleInSection(state.phones, 'accountPhone')
    }),
    cardHolderAddress: computed((state) => {
      return findRoleInSection(state.physicalLocations, 'billingAddress')
    }),
    deliveryRecipientAddress: computed((state) => {
      return findRoleInSection(state.physicalLocations, 'deliveryAddress')
    }),
    deliveryRecipient: computed((state) => {
      return findRoleInSection(state.people, 'deliveryRecipient')
    }),
    creditCardIssuer: computed((state) => {
      return findRoleInSection(state.organizations, 'creditCardIssuer')
    }),
    asnCarrier: computed((state) => {
      return findRoleInSection(state.organizations, 'asnCarrier')
    }),
    ipRegistree: computed((state) => {
      return findRoleInSection(state.organizations, 'ipRegistree')
    }),
  },

  clearCaseEntries: action((state) => {
    Object.assign(state.entries, defaultEntriesState)
  }),
}

export default caseEntriesModel
