import { FC } from 'react'
import { Space } from '@signifyd/components'
import { useStoreState } from 'store'
import CaseClaimsBannerFulfillmentModal from '../CaseClaimsBannerFulfillment'
import CaseClaimsBannerUpload from '../CaseClaimsBannerUpload'

export interface ClaimsBannerContentProps {
  children?: React.ReactElement
  hasFileUpload?: boolean
  hasFulfillment?: boolean
  subtitle?: string
}

export const ClaimsBannerContent: FC<ClaimsBannerContentProps> = ({
  children,
  hasFileUpload = false,
  hasFulfillment = false,
  subtitle,
}) => {
  const caseDetails = useStoreState((state) => state.currentCase.details)

  if (!caseDetails) {
    return null
  }

  return (
    <>
      {!!subtitle && (
        <strong data-test-id="claimsBannerContentSubtitle">{`${subtitle} `}</strong>
      )}
      {children}
      {hasFulfillment && (
        <CaseClaimsBannerFulfillmentModal
          orderId={caseDetails.orderExternalId}
          investigationId={caseDetails.investigationId}
        />
      )}
      {hasFileUpload && (
        <>
          <Space size="sm" />
          <CaseClaimsBannerUpload
            investigationId={caseDetails.investigationId}
          />
        </>
      )}
    </>
  )
}

export default ClaimsBannerContent
