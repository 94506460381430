import { FC, useEffect } from 'react'
import { Layout, notification } from 'antd'
import cx from 'classnames'
import moment from 'moment-timezone'
import {
  AppAnalytics,
  CustomerNavBar,
  useAppCues,
  ShadowUserBanner,
  TrialBanner,
  i18nInstance,
  initLocalization,
  useCurrentAppLocale,
  defaultQueryClientConfig,
  AppConfigProvider,
  QAInfoPopover,
  ProdWarningModal,
} from '@signifyd/components'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { BrowserRouter } from 'react-router-dom'
import { ordersTokens } from '@signifyd/sig-keys'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import { legacyTheme, thirdGenTheme } from '@signifyd/ant'
import { PageSizeProvider } from 'core/components/PageSizeContext'
import OnboardingScreen from 'core/components/OnboardingScreen'
import locales from 'locales'
import AppRoutes from 'AppRoutes'
import { useStoreActions, useStoreState } from 'store'
import useHomepageRedirect from 'core/hooks/useHomepageRedirect'
import styles from './App.less'

initLocalization(locales)

const { Content } = Layout

export const queryClient = new QueryClient(defaultQueryClientConfig)

const theme = process.env.THIRD_GEN === 'true' ? thirdGenTheme : legacyTheme
const generation = process.env.THIRD_GEN === 'true' ? 'thirdGen' : 'secondGen'

export const App: FC = () => {
  // We awaited user loading in index, so we can safely non null assert here
  const currentUser = useStoreState((state) => state.user.currentUser)!
  const userConfigs = useStoreState((state) => state.user.userConfigs)!

  const { selectedLocale } = useCurrentAppLocale(currentUser)
  const { updateUserUIState } = useStoreActions((store) => store.user)

  useAppCues({
    user: currentUser,
    appName: 'customer-case-console',
    allowedBuildEnvs: window.isRegressionTest ? [] : ['staging', 'production'],
    buildEnv: process.env.BUILD_ENV,
  })

  // Updated plugin notification message
  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const pluginUpdated = urlParams.get('plugin_updated')

    if (pluginUpdated?.toLowerCase() === 'shopify') {
      notification.success({
        message: i18nInstance.t('pluginUpdatedNotification.message'),
        description: i18nInstance.t('pluginUpdatedNotification.description'),
        duration: 0,
      })
    }
  }, [])

  const userTimeZone = currentUser?.uiState?.timeZone?.name

  useEffect(() => {
    if (userTimeZone) {
      moment.tz.setDefault(userTimeZone)
    }
  }, [userTimeZone])

  useHomepageRedirect()

  const classNames = cx([styles.appWrapper, generation])

  return (
    <div className={classNames}>
      <BrowserRouter>
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <QueryClientProvider client={queryClient}>
            <AppConfigProvider
              theme={theme}
              localization={{ appName: 'orders' }}
              user={currentUser}
              userConfigs={userConfigs}
            >
              <AppAnalytics
                user={currentUser}
                logRocketId={ordersTokens.logRocket}
                heapAppId={ordersTokens.heapAppId}
                enabled={process.env.BUILD_ENV === 'production'}
              />
              <OnboardingScreen
                username={currentUser.username}
                key={selectedLocale}
              >
                <CustomerNavBar
                  user={currentUser}
                  userConfigs={userConfigs}
                  onLanguageSelectionChange={(selectedLocale) => {
                    updateUserUIState({
                      locale: selectedLocale,
                    })
                  }}
                />
                <Content>
                  <PageSizeProvider>
                    <AppRoutes />
                    <ShadowUserBanner currentUser={currentUser} />
                  </PageSizeProvider>
                  <QAInfoPopover />
                </Content>
                <TrialBanner user={currentUser} />
              </OnboardingScreen>
            </AppConfigProvider>
            <ProdWarningModal />
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </QueryParamProvider>
      </BrowserRouter>
    </div>
  )
}

export default App
