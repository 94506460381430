import { z } from 'zod'

const Schema = z.enum(['default', 'validate'])

type SchemaTypes = z.infer<typeof Schema>

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const getClaimReimbursementFormSchema = (mode: SchemaTypes) => {
  const setDefaultByMode = <T extends z.ZodTypeAny>(
    schema: T,
    defaultValue: T['_type'] | undefined
  ): T | z.ZodOptional<T> | z.ZodDefault<z.ZodOptional<T>> => {
    if (mode === Schema.enum.validate) {
      return schema
    }

    if (mode === Schema.enum.default && defaultValue) {
      return schema.optional().default(defaultValue)
    }

    return schema.optional()
  }

  return z
    .object({
      trackingNumber: z.string().min(1),
      shipper: z.string().min(1),
      chargebackRefId: z.string().min(1),
      issuerReportedDate: setDefaultByMode(z.string().min(1), undefined),
      processorReasonDescription: z.string().min(1),
      chargebackAmount: z.object({
        amount: setDefaultByMode(z.number().nonnegative(), undefined),
        currencyCode: z.string(),
      }),
      chargebackFees: z.object({
        amount: setDefaultByMode(z.number().nonnegative(), undefined),
        currencyCode: z.string(),
      }),
      chargebackNotice: setDefaultByMode(
        z.array(z.any()).nonempty(),
        undefined
      ),
      comments: z.string().optional(),
    })
    .passthrough()
}

const ClaimReimbursementFormDefaultSchema = getClaimReimbursementFormSchema(
  Schema.enum.default
)
export const ClaimReimbursementFormValidateSchema =
  getClaimReimbursementFormSchema(Schema.enum.validate)

export type DefaultClaimReimbursementFormSchemaType = z.infer<
  typeof ClaimReimbursementFormDefaultSchema
>

export const ClaimReimbursementFormDefaultValues: DefaultClaimReimbursementFormSchemaType =
  {
    trackingNumber: '',
    shipper: '',
    chargebackRefId: '',
    issuerReportedDate: undefined,
    processorReasonDescription: '',
    chargebackAmount: { amount: undefined, currencyCode: 'USD' },
    chargebackFees: { amount: undefined, currencyCode: 'USD' },
    chargebackNotice: undefined,
    comments: '',
  }

export type ClaimReimbursementFormSchemaType = z.infer<
  typeof ClaimReimbursementFormValidateSchema
>
