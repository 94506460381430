export default {
  title: 'Signifyd intelligence',
  address: 'Address',
  device: 'Device',
  email: 'Email',
  time: 'Time',
  score: 'SCORE: {{score}}',
  showMore: 'Show {{length}} more',
  noAnalysis: 'No analysis available',
  panelActions: {
    billingAddress: 'Billing address',
    deliveryAddress: 'Delivery address',
    ipAddress: 'IP address',
    deviceId: 'Device ID',
    confirmationPhone: 'Confirmation phone',
    confirmationEmail: 'Confirmation email',
    disclaimer_one:
      '{{type}} has been seen {{count}} time in the last two years',
    disclaimer_other:
      '{{type}} has been seen {{count}} times in the last two years',
  },
  warning: {
    processing:
      'Apologies — fraud analysis is still loading. Refresh the page.',
    policyOnly:
      'Nothing to see here! This case was decisioned by merchant policies only thus fraud analysis is not available.',
    error:
      'Apologies — our system failed momentarily. We recommend you refresh the page.',
  },
}
