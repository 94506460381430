export const shippingCarrierOptions: Array<{ key: string; value: string }> = [
  { key: 'australia-post', value: 'Australia Post' },
  { key: 'brt-it', value: 'BRT' },
  { key: 'canada-post', value: 'Canada Post' },
  { key: 'dhl', value: 'DHL' },
  { key: 'dhl-global-mail', value: 'DHL Global Mail' },
  { key: 'dpd', value: 'DPD' },
  { key: 'dpd-uk', value: 'DPD UK' },
  { key: 'fedex', value: 'Fedex' },
  { key: 'interlink-express', value: 'Interlink Express' },
  { key: 'naldelivers', value: 'Naldelivers' },
  { key: 'purolator', value: 'Purolator' },
  { key: 'royal-mail', value: 'Royal Mail' },
  { key: 'rrdonnelley', value: 'RR Donnelley' },
  { key: 'star-track', value: 'Star Track' },
  { key: 'temando', value: 'Temando' },
  { key: 'usps', value: 'USPS' },
  { key: 'ups', value: 'UPS' },
  { key: 'other', value: 'Other' },
]

export const chargebackReasonOptions: Array<{ key: string; value: string }> = [
  { key: 'Credit not processed', value: 'Credit not processed' },
  { key: 'Fraud/Unauthorized', value: 'Fraud/Unauthorized' },
  { key: 'Item Not Received', value: 'Item Not Received' },
  { key: 'Not as described/defective', value: 'Not as described/defective' },
  { key: 'Not recognized', value: 'Not recognized' },
  { key: 'Other', value: 'Other' },
]
