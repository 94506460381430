import { UseQueryResult, useQuery } from '@tanstack/react-query'
import {
  VerificationResponse,
  getAVSOptions,
  getCVVOptions,
} from '@signifyd/http'

export const useGetAVSOptions = (): UseQueryResult<
  Array<VerificationResponse>
> =>
  useQuery(['avsOptions'], async () => {
    const { data } = await getAVSOptions()

    return data
  })

export const useGetCVVOptions = (): UseQueryResult<
  Array<VerificationResponse>
> =>
  useQuery(['cvvOptions'], async () => {
    const { data } = await getCVVOptions()

    return data
  })
