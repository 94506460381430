import moment from 'moment-timezone'
import { RangeFilter } from 'store/search/types'
import { getDefaultDateRange, getEndOfDay, getStartOfDay } from '../date.utils'

export const getSelectedQuickDateFilter = (
  dates: RangeFilter<string>,
  dayCount: number
): boolean => {
  const defaultDateRange = getDefaultDateRange()

  const today = getEndOfDay()
  const filterSelectedMinDate = getStartOfDay(
    moment().subtract(dayCount, 'days')
  )

  const min = dates?.min ?? defaultDateRange.min
  const max = dates?.max ?? defaultDateRange.max

  return today === max && filterSelectedMinDate === min
}
