import { FC } from 'react'
import { Tooltip } from 'antd'
import { colorYonder } from '@signifyd/colors'
import { camelCase } from 'lodash'
import icons from './icons'
import styles from './SummaryIcon.less'

export interface SummaryIconProps {
  type: string
  tooltip: string
}

const SummaryIcon: FC<SummaryIconProps> = ({ type, tooltip }) => {
  const IconComponent = icons[camelCase(type)]

  if (!IconComponent) {
    return null
  }

  return (
    <Tooltip title={tooltip}>
      <div
        className={styles.iconWrapper}
        data-test-id={`icon-${camelCase(type)}`}
      >
        <IconComponent fill={colorYonder} />
      </div>
    </Tooltip>
  )
}

export default SummaryIcon
