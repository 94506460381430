import { FC } from 'react'
import { Controller } from 'react-hook-form'
import { Input } from 'antd'
import type { FieldProps } from './Fields.types'
import FormItemWrapper from './FormItemWrapper'
import { useGetLabelText, useGetErrorText } from './utils'

export const TextField: FC<FieldProps> = ({
  fieldName,
  control,
  errors,
  span,
  required,
  disabled,
}) => {
  const labelText = useGetLabelText(fieldName)
  const errorText = useGetErrorText(fieldName, errors)

  return (
    <FormItemWrapper
      span={span}
      labelText={labelText}
      errorText={errorText}
      required={required}
    >
      <Controller
        name={fieldName}
        control={control}
        render={({ field }) => (
          <Input
            onChange={(e) => {
              field.onChange(e.target.value || undefined)
            }}
            value={field.value as string}
            type="text"
            disabled={disabled}
            data-analytics-id={`input-field-${fieldName}`}
            data-test-id={`inputField-${fieldName}`}
          />
        )}
      />
    </FormItemWrapper>
  )
}

export default TextField
