import { Text } from '@signifyd/components'
import { FC, Fragment } from 'react'
import { DiscountCode } from '@signifyd/http'
import { Tooltip } from 'antd'
import styles from './DiscountCodes.less'

interface Props {
  discountCodes: Array<DiscountCode>
  currency: string
}

const DiscountCodes: FC<Props> = ({ discountCodes, currency }) => (
  <>
    {discountCodes.map(({ code, amount, percentage }) => (
      <Fragment key={code}>
        <div data-test-id="discountCodeSegment" className={styles.discountCode}>
          <Tooltip title={code}>
            <Text className={styles.code}>{code}</Text>
          </Tooltip>
          <Text className={styles.value}>
            {amount
              ? `${currency} ${amount}`
              : percentage && `${percentage * 100}%`}
          </Text>
        </div>
        <br />
      </Fragment>
    ))}
  </>
)

export default DiscountCodes
