import { FC } from 'react'
import {
  CardIcon,
  CreditCardIcon,
  DebitCardIcon,
  PrepaidCardIcon,
  AmexIcon,
  UnionpayIcon,
  MaestroIcon,
  DinersClubIcon,
  JCBIcon,
  SoloIcon,
  VisaIcon,
  DiscoverIcon,
  CirrusIcon,
  MasterCardIcon,
  LaserIcon,
  OuroCardIcon,
  RBSIcon,
  PayPalIcon,
  AmazonPayIcon,
  SaksFifthAvIcon,
  ApplePayIcon,
  SamsungPayIcon,
  AliPayIcon,
  FedexIcon,
  USPSIcon,
  UPSIcon,
  DHLIcon,
  ShipwireIcon,
  PickupIcon,
  DeliveryIcon,
  CounterPickupIcon,
  CurbsidePickupIcon,
  LockerPickupIcon,
} from '@signifyd/components'

export const paymentIcons: Record<string, FC> = {
  noBrand: CardIcon,
  credit: CreditCardIcon,
  creditCard: CreditCardIcon,
  charge: CreditCardIcon,
  chargeCard: CreditCardIcon,
  debit: DebitCardIcon,
  debitCard: DebitCardIcon,
  prepaid: PrepaidCardIcon,
  prepaidCard: PrepaidCardIcon,
  prepaidVisaTravelmoney: PrepaidCardIcon,
  amex: AmexIcon,
  americanExpres: AmexIcon,
  chinaUnionPay: UnionpayIcon,
  chinaUnionpay: UnionpayIcon,
  maestro: MaestroIcon,
  maestroSwitch: MaestroIcon,
  switchMaestro: MaestroIcon,
  dinersClub: DinersClubIcon,
  jcb: JCBIcon,
  solo: SoloIcon,
  visa: VisaIcon,
  discover: DiscoverIcon,
  cirrus: CirrusIcon,
  mastercard: MasterCardIcon,
  laser: LaserIcon,
  ourocard: OuroCardIcon,
  rbsGiftCard: RBSIcon,
  paypal: PayPalIcon,
  amazonPayments: AmazonPayIcon,
  saksfirsStoreCard: SaksFifthAvIcon,
  applePay: ApplePayIcon,
  // check: 'check',
  // ach: 'ach',
  // carrierLoan: 'carrieLoan',
  // loan: 'loan',
  samsungPay: SamsungPayIcon,
  alipay: AliPayIcon,
}

export const shippingIcons: Record<
  string,
  FC<{ fill?: string; style?: any }>
> = {
  fedex: FedexIcon,
  usps: USPSIcon,
  ups: UPSIcon,
  dhl: DHLIcon,
  shipwire: ShipwireIcon,
  pickup: PickupIcon,
  delivery: DeliveryIcon,
  counterPickup: CounterPickupIcon,
  curbsidePickup: CurbsidePickupIcon,
  lockerPickup: LockerPickupIcon,
}

export default {
  ...paymentIcons,
  ...shippingIcons,
}
