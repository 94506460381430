import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { i18nInstance } from '@signifyd/components'
import { message } from 'antd'
import { createFulfillment, CreateFulfillment } from '@signifyd/http'
import { Actions, useStoreActions } from 'easy-peasy'
import { StoreModel } from 'store'

const translationPrefix = 'store.fulfillment'

interface FulfillmentPayload {
  investigationId: number
  fulfillmentPayload: CreateFulfillment
}

export const useCreateFulfillment = (
  onSuccess?: () => void
): UseMutationResult<CreateFulfillment, unknown, FulfillmentPayload> => {
  const { pollForChanges } = useStoreActions(
    (actions: Actions<StoreModel>) => actions.currentCase
  )

  return useMutation({
    mutationFn: async ({
      investigationId,
      fulfillmentPayload,
    }: FulfillmentPayload) => {
      const { data } = await createFulfillment(
        investigationId,
        fulfillmentPayload
      )

      return data
    },
    onSuccess: () => {
      onSuccess?.()
      message.success(i18nInstance.t(`${translationPrefix}.apiSuccess`))
      pollForChanges()
    },
    onError: () =>
      message.error(i18nInstance.t(`${translationPrefix}.apiFailure`)),
  })
}
