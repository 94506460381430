import React from 'react'
import ReactDOM, { render } from 'react-dom'
import { StoreProvider } from 'easy-peasy'
import { ErrorBoundary, AuthProvider } from '@signifyd/components'
import UserLoader from 'UserLoader'
import App from 'App'
import store from 'store'
import 'antd/dist/reset.css'
import '@signifyd/ant/dist/overrides/index.less'
import styles from './index.less'

if (process.env.NODE_ENV === 'development') {
  import('@axe-core/react').then((axe) => {
    axe.default(React, ReactDOM, 1000, {}, '#root')
  })
}

render(
  <AuthProvider>
    <ErrorBoundary className={styles.errorBoundary}>
      <StoreProvider store={store}>
        <UserLoader>
          <App />
        </UserLoader>
      </StoreProvider>
    </ErrorBoundary>
  </AuthProvider>,
  document.getElementById('root')
)
