import { action, thunk } from 'easy-peasy'
import { message } from 'antd'
import { isNil } from 'lodash'
import moment from 'moment-timezone'
import {
  createReroute,
  INV_CASE_TYPE,
  INV_GUARANTEE_DISPOSITION,
} from '@signifyd/http'
import { i18nInstance } from '@signifyd/components'
import { CASE_CAPABILITIES, hasCapability } from 'core/utils/capabilities'
import { isTransportAirline } from 'core/utils/isTransportAirline/isTransportAirline'
import { getPastStartDate } from 'core/utils/date.utils'
import {
  disableAction,
  enableAction,
  hideAction,
  toRerouteRequest,
} from './utils'
import { UpdateAddressActionModel } from './types'
import { parseAPIErrorMsg } from '../../core/utils/parseAPIErrorMsg'

const translationPrefix = 'store.caseActions.updateAddress'

const updateAddressModel: UpdateAddressActionModel = {
  isLoading: false,
  setLoading: action((state, payload) => {
    state.isLoading = payload
  }),

  // HTTP Stuff
  performAction: thunk((actions, { caseDetails, payload }) => {
    // Double check we are allowed to update the address
    const { disabled, hidden, disabledReason } =
      actions.canPerformAction(caseDetails)

    if (disabled || hidden) {
      message.error(
        disabledReason || i18nInstance.t(`${translationPrefix}.notAllowed`)
      )

      return Promise.resolve()
    }

    // Otherwise go ahead and update
    actions.setLoading(true)

    return createReroute(
      toRerouteRequest(payload, caseDetails),
      caseDetails.teamId
    )
      .then(() => {
        message.success(i18nInstance.t(`${translationPrefix}.apiSuccess`))
      })
      .catch((error) => {
        message.error(
          parseAPIErrorMsg(error) ||
            i18nInstance.t(`${translationPrefix}.apiFailure`)
        )
      })
      .finally(() => actions.setLoading(false))
  }),

  // CaseListener
  canPerformAction: thunk((_a, caseDetails, { getStoreState }) => {
    const { user } = getStoreState()

    // Bail here if Admin
    // Admin can update address regardless of other conditions except airline orders
    if (
      user.currentUser?.isAdmin &&
      !isTransportAirline(caseDetails.products)
    ) {
      return enableAction()
    }

    // Max number of days allowed to update the address
    const maxDays = hasCapability(
      caseDetails.teamId,
      caseDetails.customerId,
      CASE_CAPABILITIES.ALLOW_180_DAYS_FOR_ADDRESS_UPDATES
    )
      ? 180
      : 14

    // Max Allow updates
    const hasMultipleUpdates = hasCapability(
      caseDetails.teamId,
      caseDetails.customerId,
      CASE_CAPABILITIES.ALLOW_10_ADDRESS_UPDATES
    )

    const maxUpdates = hasMultipleUpdates ? 10 : 1

    // Allow customers with multiple addresses to update in_review and pending cases
    const allowedDispositions = hasMultipleUpdates
      ? [
          INV_GUARANTEE_DISPOSITION.PENDING,
          INV_GUARANTEE_DISPOSITION.APPROVED,
          INV_GUARANTEE_DISPOSITION.DECLINED,
          INV_GUARANTEE_DISPOSITION.IN_REVIEW,
        ]
      : [INV_GUARANTEE_DISPOSITION.APPROVED, INV_GUARANTEE_DISPOSITION.DECLINED]

    if (
      // Hide if guarantee disposition is not one of the following
      caseDetails.guaranteeDisposition &&
      !allowedDispositions.includes(
        caseDetails.guaranteeDisposition as INV_GUARANTEE_DISPOSITION
      )
    ) {
      return hideAction()
    }

    // 1. Disable if test order
    if (caseDetails.isTestInvestigation) {
      return disableAction(i18nInstance.t(`${translationPrefix}.testCase`))
    }

    // 2. Disable is Trial order
    if (caseDetails.caseType === INV_CASE_TYPE.TRIAL) {
      return disableAction(i18nInstance.t(`${translationPrefix}.trialCase`))
    }

    // 3. Disable if has an active claim against it
    if (caseDetails.claims.length > 0) {
      return disableAction(i18nInstance.t(`${translationPrefix}.hasClaim`))
    }

    // 4. Disable if order is older than 14/180 days
    if (
      moment(caseDetails.normalizedPurchaseCreatedAt).isBefore(
        getPastStartDate({ subtractInterval: 'days', subtractValue: maxDays })
      )
    ) {
      return disableAction(
        i18nInstance.t(`${translationPrefix}.tooOld`, { days: maxDays })
      )
    }

    // 5. Disable if not yet submitted for guarantee
    if (isNil(caseDetails.guaranteeDisposition)) {
      return enableAction()
    }

    // 6. Disable if decision count > 1, orders can only be re-decisioned once.
    if (
      !!caseDetails.guaranteeDecisionCount &&
      caseDetails.guaranteeDecisionCount > maxUpdates
    ) {
      return disableAction(
        i18nInstance.t(`${translationPrefix}.alreadyUpdated`)
      )
    }

    // 7. Disable if the case doesn't have a team id (necessary for reroute call)
    if (!caseDetails.teamId) {
      return disableAction(i18nInstance.t('caseActions.updateAddress.noTeamId'))
    }

    if (isTransportAirline(caseDetails.products)) {
      return hideAction()
    }

    // If we're here, action can be seen and used
    return enableAction()
  }),
}

export default updateAddressModel
