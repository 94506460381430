import { FC, forwardRef } from 'react'
import { InboxOutlined } from '@ant-design/icons'
import { Upload } from 'antd'
import { useTranslation } from 'react-i18next'
import { UploadProps } from 'antd/lib/upload'
import { useAuthToken } from '@signifyd/components'
import { getPciUploadUrl } from 'AppConstants'
import { ALLOWED_UPLOAD_FILE_TYPES } from 'core/constants'

interface Props extends UploadProps {
  investigationId: number
}
interface PropsWithRef extends Props {
  forwardedRef: any
}

const FileUploadDragger: FC<PropsWithRef> = ({
  investigationId,
  forwardedRef,
  ...props
}) => {
  const { t } = useTranslation()

  const token = useAuthToken()

  return (
    <Upload.Dragger
      id="claimFormFileUploader"
      ref={forwardedRef}
      accept={ALLOWED_UPLOAD_FILE_TYPES}
      multiple
      withCredentials
      action={getPciUploadUrl()}
      headers={{
        Authorization: `Bearer ${token}`,
      }}
      data={(file) => {
        return {
          filename: file.name,
          sensitive: 1,
          caseId: `${investigationId}`,
          withCredentials: true,
        }
      }}
      {...props}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">
        {t('caseActions.reimbursement.chargebackNotice.helpTitle')}
      </p>
      <p className="ant-upload-hint">
        {t('caseActions.reimbursement.chargebackNotice.helpText')}
      </p>
    </Upload.Dragger>
  )
}

export default forwardRef((props: Props, ref) => {
  return <FileUploadDragger {...props} forwardedRef={ref} />
})
