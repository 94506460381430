export default {
  reimbursementPolicy:
    "<helpArticleLink>Learn about Signifyd's reimbursement policy</helpArticleLink>",
  canceledReversal: {
    title: 'Chargeback reimbursement claim canceled (ID {{id}})',
    subtitle: 'Chargeback reversed.',
    content:
      'Claim canceled because the chargeback was reversed by the payment processor.',
  },
  canceledRecalled: {
    title: 'Recalled chargeback reimbursement claim (Ref {{id}})',
    content:
      'Chargeback recalled by the payment processor and is no longer eligible for financial reimbursement by Signifyd. Go to Activity for more information.',
  },
  canceled: {
    title: 'Chargeback reimbursement claim canceled (ID {{id}})',
    content:
      "Claim canceled per merchant's request. Go to Activity for details.",
  },
  pending: {
    title: 'Chargeback reimbursement claim pending (ID {{id}})',
    subtitle: 'Pending.',
    content: 'Claim in review.',
  },
  postRepresentment: {
    title: 'Chargeback claim liability approved (ID {{id}})',
    content:
      "Reimbursement payout processed upon receipt of debit notice from issuer. Learn more in <helpArticleLink>Signifyd's reimbursement policy</helpArticleLink>",
  },
  approved: {
    title: 'Chargeback reimbursement claim approved (ID {{id}})',
    isNetBilling:
      'Claim processed in line with the net billing reimbursement preference.',
    default: 'Claim is processed under the terms of the agreement.',
  },
  unableToVerifyPOS: {
    title: 'Chargeback reimbursement claim needs more information (ID {{id}})',
    content:
      'Unable to verify fulfillment. Submit additional information to process this claim.',
    listTile: 'The following is missing:',
    trackingNumber: 'Tracking number',
    shipperOrCarrier: 'Shipper or carrier',
    fulfillmentStatus: 'Fulfillment status',
    dateShipped: 'Date shipped',
  },
  needMoreInfo: {
    title: 'Chargeback reimbursement claim needs more information (ID {{id}})',
    content:
      'Claim requires additional information to complete our review. To be eligible for reimbursement, provide additional information within 20 days of request. Go to Activity for details.',
  },
  ineligibleChargeback: {
    title: 'Chargeback reimbursement claim declined (ID {{id}})',
    subtitle: 'Ineligible chargeback.',
    content:
      'Chargeback reason is {{reason}}. Only chargebacks due to fraud or unauthorized are eligible for reimbursement.',
  },
  noFinancialLoss: {
    title: 'Chargeback reimbursement claim declined (ID {{id}})',
    subtitle: 'No financial loss.',
    content: 'Chargeback reversed and does not require reimbursement.',
  },
  notAChargeback: {
    title: 'Chargeback reimbursement claim declined (ID {{id}})',
    subtitle: 'Not a chargeback.',
    content:
      'Information submitted shows a retrieval request or inquiry by the payment processor, meaning no chargeback was issued. Please respond to them soon — not responding can make a future chargeback ineligible for reimbursement.',
  },
  productRerouted: {
    title: 'Chargeback reimbursement claim declined (ID {{id}})',
    subtitle: 'Product rerouted.',
    content:
      'Chargeback ineligible for reimbursement because the product was rerouted to a different delivery address than provided in the original guaranteed order details.',
  },
  productReturned: {
    title: 'Chargeback reimbursement claim declined (ID {{id}})',
    subtitle: 'Product returned.',
    content:
      'Claim ineligible for reimbursement because products in this order have been or are in the process of being returned.',
  },
  orderNotGuaranteed: {
    title: 'Chargeback reimbursement claim declined (ID {{id}})',
    subtitle: 'Order not guaranteed.',
    content:
      "Order was not guaranteed by Signifyd therefore it's not eligible for reimbursement.",
  },
  ineligiblePreAuthGatewayStatusCode: {
    title: 'Chargeback reimbursement claim declined (ID {{id}})',
    subtitle: 'Chargeback reimbursement claim declined.',
    content:
      'The pre-authorization gateway status indicates this order was unsuccessful and is not eligible for reimbursement by Signifyd.',
  },
  missingInformation: {
    title: 'Chargeback reimbursement claim declined (ID {{id}})',
    subtitle: 'Past eligible submission date.',
    content:
      'Chargeback ineligible for reimbursement because 20 days have passed since additional information was requested.',
  },
  pastEligibleSubmissionDate: {
    title: 'Chargeback reimbursement claim declined (ID {{id}})',
    subtitle: 'Missing information.',
    content:
      'Claim declined because chargebacks must be submitted for reimbursement within 7 days of receipt.',
  },
  claimDeclined: {
    title: 'Chargeback reimbursement claim declined (ID {{id}})',
    subtitle: 'Claim declined.',
    content: 'Go to Activity for more details.',
  },
  pendingRepresentment: {
    title: 'Chargeback reimbursement claim on hold (ID {{id}})',
    subtitle: 'Representment pending.',
    content:
      'Chargeback disputed with the payment processor. If the dispute is lost, claim will be released for review.',
  },
  invoicePasteDue: {
    title: 'Chargeback reimbursement claim on hold (ID {{id}})',
    subtitle: 'Invoice past due.',
    content:
      'Account past due. All claims will be released for processing once the account is current.',
  },
  onHold: {
    title: 'Chargeback reimbursement claim on hold (ID {{id}})',
    subtitle: 'Claim on hold for processing.',
    content:
      'Get in touch via claims@signifyd.com for more information. Please include the claim ID.',
  },
  paypalDispute: {
    title: 'Chargeback reimbursement claim declined (ID {{id}})',
    subtitle: 'Not a chargeback.',
    content:
      'Information submitted shows this is a PayPal Dispute by the payment processor, meaning no chargeback has been issued.',
  },
  paypalClaim: {
    title: 'Chargeback reimbursement claim declined (ID {{id}})',
    subtitle: 'Not a chargeback.',
    content:
      'Information submitted shows this is a PayPal Claim by the payment processor, meaning no chargeback has been issued.',
  },
  fileUpload: {
    modal: {
      title: 'Upload additional chargeback information',
      okButton: 'Submit',
      tooLarge: 'File must be smaller than 10MB',
      unsupportedFileType: 'Only upload image, audio, video, PDF or HTML files',
      success: 'Success! File submitted.',
      helpText:
        'To help us tie this chargeback to the applicable order, attach additional supporting documentation.',
      subtitle: 'Chargeback documents',
      dragOrClick: 'Click or drag file to this area to upload',
      provideDocuments:
        'To submit your chargeback claim you must provide proof of shipment documentation and your chargeback notification document.',
    },
    attachButton: 'Attach file(s)',
  },
  fulfillment: {
    submitInfo: 'Submit information',
    modal: {
      title: 'To proceed with claim, provide shipping details.',
      okButton: 'Submit',
      success: 'Success! Files have been successfully submitted.',
    },
    form: {
      trackingNumbers: {
        label: 'Tracking number',
        placeholder: 'Enter number',
        error: 'Please enter a tracking number',
      },
      shippingCarrier: {
        label: 'Shipper or carrier',
        placeholder: 'Select',
        error: 'Please select shipper or carrier',
      },
      fulfillmentStatus: {
        label: 'Fulfillment status',
        placeholder: 'Select',
        error: 'Please select fulfillment status',
      },
      createdAt: {
        label: 'Date shipped',
        placeholder: 'Select date',
        error: 'Please enter shipped date',
      },
    },
  },
  representmentDetails: {
    underBankReview:
      'Chargeback challenged and under review with the issuing bank.',
    notChallenged:
      'Chargeback not challenged with the issuing bank. <helpArticleLink>Learn about our chargeback review process</helpArticleLink>',
    notChallengedValidFraud:
      'Chargeback not challenged with the issuing bank. The transaction appears to be fraudulent.',
    notChallengedUnfulfilled:
      'Chargeback not challenged with the issuing bank. The order has not been fulfilled.',
    notChallengedReturned:
      'Chargeback not challenged with the issuing bank. The merchandise has been successfully returned.',
    secondChargeback:
      'No further recourse. The cardholder submitted a second chargeback. <helpArticleLink>Learn about second chargebacks</helpArticleLink>',
    challengedNoOutcome:
      "Chargeback challenged. However, the outcome is not available, can't be obtained or wasn't received. <helpArticleLink>Learn about the availability of outcomes</helpArticleLink>",
    chargebackWon: 'Chargeback resolved in favor of the merchant.',
    chargebackLost: 'Chargeback resolved in favor of the cardholder.',
  },
}
