import { listTeamPayoutMethods, PayoutMethodSummary } from '@signifyd/http'
import { useQuery, UseQueryResult } from '@tanstack/react-query'

export const usePayoutMethod = (
  teamId: number
): UseQueryResult<PayoutMethodSummary> => {
  return useQuery({
    queryKey: ['teamId', teamId],
    queryFn: async () => {
      const { data } = await listTeamPayoutMethods(teamId)

      return data
    },
  })
}
