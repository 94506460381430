import { ExternalLink } from '@signifyd/components'
import { InvestigationNoteFile } from '@signifyd/http'
import { PaperClipOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './NoteFileLink.less'

const FileLabel: FC<{ fileName: string }> = ({ fileName }) => (
  <span className={styles.label}>
    <PaperClipOutlined />
    {fileName}
  </span>
)

interface Props {
  file: InvestigationNoteFile
}

const NoteFileLink: FC<Props> = ({ file }) => {
  const { t } = useTranslation()

  if (file.expired) {
    return (
      <div className={styles.expired} data-test-id="expiredFileLabel">
        <Tooltip title={t('caseActivity.expiredNoteTooltip')}>
          <FileLabel fileName={file.fileName} />
        </Tooltip>
      </div>
    )
  }

  return (
    <div>
      <ExternalLink
        target="_blank"
        url={file.presignedUrl}
        data-test-id="fileLink"
      >
        <FileLabel fileName={file.fileName} />
      </ExternalLink>
    </div>
  )
}

export default NoteFileLink
