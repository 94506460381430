import { FC, Fragment } from 'react'
import { CaretRightOutlined } from '@ant-design/icons'
import { Collapse } from 'antd'
import { InvestigationInfo } from '@signifyd/http'
import { Space } from '@signifyd/components'
import { useChargebackSettings } from 'core/queries/useChargebackSettings'
import getClaimDisplayData from './CaseClaimBanner.utils'
import RepresentmentDetails from './RepresentmentDetails'

interface Props {
  caseDetails: InvestigationInfo | null
}

export const CaseClaim: FC<Props> = ({ caseDetails }) => {
  const { data: settings, isLoading: settingsLoading } = useChargebackSettings(
    caseDetails?.teamId
  )

  if (!caseDetails || settingsLoading) {
    return null
  }

  return (
    <>
      {caseDetails.chargebackCases.map(
        ({ claim, representment, chargeback }) => {
          if (!claim) {
            return null
          }

          const claimDetails = getClaimDisplayData({
            claim,
            investigation: caseDetails,
            chargeback,
            settings,
          })

          if (!claimDetails) {
            return null
          }

          return (
            <Fragment key={claim.claimId}>
              <Collapse
                bordered={false}
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined
                    data-test-id="expandIcon"
                    rotate={isActive ? 90 : 0}
                  />
                )}
              >
                <Collapse.Panel
                  key={1}
                  header={claimDetails.title}
                  style={{
                    background: claimDetails.color,
                    border: 0,
                  }}
                >
                  {claimDetails.content}
                  <RepresentmentDetails
                    status={representment?.status}
                    statusReason={representment?.statusReason}
                    outcome={representment?.outcome}
                    type={representment?.type}
                  />
                </Collapse.Panel>
              </Collapse>
              <Space size="lg" />
            </Fragment>
          )
        }
      )}
    </>
  )
}

export default CaseClaim
