import { i18nInstance } from '@signifyd/components'
import { DeepLinkFactory, MENU_ICON } from '../DeepLinkDropdown.types'
import { encodedAgentConsoleSearchUrl } from '../DeepLinkConstants'

const getDeepLinksAgentConsoleSearch: DeepLinkFactory = ({ seller, field }) => {
  const value = seller[field]

  return [
    {
      icon: MENU_ICON.SigLogoSmall,
      text: i18nInstance.t(
        'pages.caseReview.sellerDetails.deepLinks.sigSearch'
      ),
      url: encodedAgentConsoleSearchUrl(`${value}`),
    },
  ]
}

export default getDeepLinksAgentConsoleSearch
