export default {
  onAddSuccess: 'Success! Item added to <bold>{{listName}}</bold>.',
  onAddFailure: 'Apologies — our system failed to add item to list. Try again.',
  onRemoveSuccess: 'Success! Item removed from <bold>{{listName}}</bold>.',
  onRemoveFailure:
    'Apologies — our system failed to remove item from list. Try again.',
  onFetchFailure: "Something's not right — refresh to try again",
  availableListsTitle: 'Available lists',
  confirmDeleteTitle: 'Remove from list?',
  confirmDeleteContent:
    'This action will remove the item immediately from list.',
  addToListButtonTooltip: 'Select a list to add item',
  confirmDeleteOkButton: 'Remove',
  removeFromListTooltip: 'Remove item from list',
  onAddItemNote: 'Item added to list {{listName}}',
  onRemoveItemNote: 'Item removed from list {{listName}}',
}
