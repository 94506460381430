export default {
  APPROVED: 'Approved',
  PENDING: 'Pending',
  DECLINED: 'Declined',
  CANCELED: 'Canceled',
  CANCELLED: 'Canceled',
  IN_REVIEW: 'In review',
  ESCALATED: 'In review',
  PROCESSING_REGUARANTEE: 'Processing',
  UNDEFINED_DISPOSITION: 'Processing',
  PROCESSING: 'Processing',
}
