import { ActionState } from './types/base'

export const enableAction = (): ActionState => ({
  hidden: false,
  disabled: false,
})

export const hideAction = (): ActionState => ({
  hidden: true,
  disabled: true,
})

export const disableAction = (disabledReason: string): ActionState => ({
  hidden: false,
  disabled: true,
  disabledReason,
})
