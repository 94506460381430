import { FC } from 'react'
import { ExternalLink, Text, getCaseReviewUrl } from '@signifyd/components'
import { useTranslation } from 'react-i18next'
import { InvestigationInfo } from '@signifyd/http'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import { Popover, Row, Col, Tooltip } from 'antd'
import { useStoreState } from 'store'
import FormattedDate from 'core/components/FormattedDate'
import styles from '../CaseHeader.less'

interface Props {
  caseDetails: InvestigationInfo
}

const MAX_CHECKOUT_LIMIT = 16

export const CaseHeaderDetails: FC<Props> = ({ caseDetails }) => {
  const { t } = useTranslation()
  const user = useStoreState((state) => state.user.currentUser)
  const caseReviewUrl = getCaseReviewUrl()

  const { checkoutToken, investigationId } = caseDetails
  const checkoutTokenTooLong =
    checkoutToken && checkoutToken?.length > MAX_CHECKOUT_LIMIT

  const caseStatusIcon =
    caseDetails?.caseStatus === 'OPEN' ? 'folder-open' : 'close-square'

  return (
    <>
      <div className={styles.caseDetails}>
        <Row gutter={[16, 8]}>
          <Col span={12}>
            <Text>
              {t('pages.caseReview.header.caseId')}
              {user?.isAdmin ? (
                <ExternalLink url={`${caseReviewUrl}/${investigationId}`}>
                  {investigationId}
                </ExternalLink>
              ) : (
                investigationId
              )}
            </Text>
            <Tooltip
              className={styles.caseStatusIcon}
              overlayClassName={styles.tooltipText}
              title={
                <>
                  <Text size="md" strong className={styles.tooltipText}>
                    {t('pages.caseReview.header.caseStatus', {
                      status: t(
                        `pages.caseReview.header.${caseDetails.caseStatus}`
                      ),
                    })}
                  </Text>
                </>
              }
              mouseEnterDelay={0.5}
            >
              <LegacyIcon
                type={caseStatusIcon}
                data-test-id="case-status-icon"
                title={t(`pages.caseReview.header.${caseDetails.caseStatus}`)}
              />
            </Tooltip>
          </Col>
          <Col span={12}>
            {caseDetails.teamName && (
              <Text>
                {t('pages.caseReview.header.team', {
                  team: caseDetails.teamName,
                })}
              </Text>
            )}
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col span={12}>
            <Text>
              {t('pages.caseReview.header.orderCreatedAt')}
              <FormattedDate date={caseDetails.normalizedPurchaseCreatedAt} />
            </Text>
          </Col>
          <Col span={12}>
            {checkoutToken && (
              <Text>
                {t('pages.caseReview.header.checkoutToken')}
                {checkoutTokenTooLong ? (
                  <>
                    {checkoutToken.substr(0, MAX_CHECKOUT_LIMIT)}...
                    <Popover
                      content={checkoutToken}
                      title={t('pages.caseReview.header.checkoutToken')}
                      trigger="click"
                    >
                      <Text className={styles.popoverAvailableText}>
                        {' '}
                        {t('pages.caseReview.header.view')}
                      </Text>
                    </Popover>
                  </>
                ) : (
                  checkoutToken
                )}
              </Text>
            )}
          </Col>
        </Row>
      </div>
    </>
  )
}

export default CaseHeaderDetails
