import { computed } from 'easy-peasy'
import { CaseEkataDataModel } from './types/case.ekata.types'

const caseEkataDataModel: CaseEkataDataModel = {
  ekata: {
    idCheckLink: computed(
      [(_state, storeState) => storeState.currentCase.entries],
      (entries) => {
        const {
          cardHolder,
          accountHolderPhone,
          cardHolderAddress,
          confirmationPhone,
          deliveryRecipient,
          deliveryRecipientAddress,
          confirmationEmail,
          purchaseIP,
        } = entries

        // If we have no values to work with -> return undefined
        if (
          !cardHolder &&
          !accountHolderPhone &&
          !cardHolderAddress &&
          !confirmationPhone &&
          !deliveryRecipient &&
          !deliveryRecipientAddress &&
          !confirmationEmail &&
          !purchaseIP
        ) {
          return undefined
        }

        // else let's construct a url
        const baseUrl = 'https://app.ekata.com/identity_checks'
        const addressArr = []

        if (cardHolder?.entityName) {
          addressArr.push(`billing_name=${cardHolder.entityName}`)
        }

        if (accountHolderPhone?.entityName) {
          addressArr.push(`billing_phone=${accountHolderPhone.entityName}`)
        }

        if (cardHolderAddress?.details) {
          const { street, cityName, regionAlpha, countryIsoCode } =
            cardHolderAddress.details
          if (street) {
            addressArr.push(`billing_address_street_line_1=${street}`)
          }
          if (cityName) {
            addressArr.push(`billing_address_city=${cityName}`)
          }
          if (regionAlpha) {
            addressArr.push(`billing_address_state_code=${regionAlpha}`)
          }
          if (countryIsoCode) {
            addressArr.push(`billing_address_country_code=${countryIsoCode}`)
          }
        }

        if (confirmationPhone?.entityName) {
          addressArr.push(`shipping_phone=${confirmationPhone.entityName}`)
        }

        if (deliveryRecipient?.entityName) {
          addressArr.push(`shipping_name=${deliveryRecipient.entityName}`)
        }

        if (deliveryRecipientAddress?.details) {
          const { street, cityName, regionAlpha, countryIsoCode } =
            deliveryRecipientAddress.details
          if (street) {
            addressArr.push(`shipping_address_street_line_1=${street}`)
          }
          if (cityName) {
            addressArr.push(`shipping_address_city=${cityName}`)
          }
          if (regionAlpha) {
            addressArr.push(`shipping_address_state_code=${regionAlpha}`)
          }
          if (countryIsoCode) {
            addressArr.push(`shipping_address_country_code=${countryIsoCode}`)
          }
        }

        if (confirmationEmail?.details?.emailAddress) {
          addressArr.push(
            `email_address=${confirmationEmail.details.emailAddress}`
          )
        }

        if (purchaseIP?.details?.ipAddress) {
          addressArr.push(`ip_address=${purchaseIP.details.ipAddress}`)
        }

        return encodeURI(`${baseUrl}?${addressArr.join('&')}`)
      }
    ),
  },
}

export default caseEkataDataModel
