import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Flex } from 'antd'
import { Unauthorized, Text, PageSpinner, Space } from '@signifyd/components'
import { useNavigate, useParams } from 'react-router'
import { USER_ROLE, Event } from '@signifyd/http'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { colorSnow } from '@signifyd/colors'
import { useStoreState } from 'store'
import PageWrapper from 'core/components/PageWrapper'
import useGetInvestigationData from './useGetInvestigationData'
import EventTimeline from './EventTimeline/EventTimeline'
import DataDisplay from './DataDisplay/DataDisplay'
import SelectedEventContent from './SelectedEvent/SelectedEventContent'
import styles from './EventViewer.less'
import CompareEvents from './CompareEvents/CompareEvents'

export const EventViewer: FC = () => {
  const navigate = useNavigate()
  const { caseId } = useParams()
  const { t } = useTranslation(undefined, { keyPrefix: 'eventViewer' })
  const currentUser = useStoreState((state) => state.user.currentUser)
  const isAdmin = currentUser?.roles.includes(USER_ROLE.ADMIN)

  const [isComparingEvents, setIsComparingEvents] = useState(false)
  const [compareEvents, setCompareEvents] = useState<Array<Event>>([])
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null)
  const { data, isLoading } = useGetInvestigationData(caseId, isAdmin)

  if (!caseId || !isAdmin) {
    return (
      <PageWrapper>
        <Unauthorized />
      </PageWrapper>
    )
  }

  if (isLoading) {
    return <PageSpinner />
  }

  if (!data?.externalInvestigation) {
    return (
      <PageWrapper>
        <Text>{t('noEvents')}</Text>
      </PageWrapper>
    )
  }

  const handleBack = (): void => {
    navigate(`/orders/${caseId}`)
  }

  const eventsFound = data?.externalInvestigation && data?.events.length

  const activeComparison = isComparingEvents && compareEvents.length === 2

  return (
    <>
      <Flex align="center">
        <Button
          type="link"
          onClick={handleBack}
          data-test-id="backToOrders"
          data-analytics-id="back-to-orders"
          className={styles.backButton}
        >
          <Text size="md" className={styles.backText} block>
            <ArrowLeftOutlined className={styles.backIcon} />
            {t(`backToOrder`)}
          </Text>
        </Button>
      </Flex>

      <PageWrapper
        className={styles.caseReviewPage}
        backgroundColor={colorSnow}
      >
        <Text size="lg" weight="semibold">
          {t(`investigation`, {
            investigationId: data?.externalInvestigation.investigationId,
          })}
        </Text>
        <Space size="sm" />
        {!eventsFound && <Text size="lg">{t(`noEvents`)}</Text>}
        {eventsFound && (
          <Flex gap={20}>
            <EventTimeline
              events={data?.events}
              selectedEvent={selectedEvent}
              setSelectedEvent={setSelectedEvent}
              isComparingEvents={isComparingEvents}
              setIsComparingEvents={setIsComparingEvents}
              compareEvents={compareEvents}
              setCompareEvents={setCompareEvents}
            />

            {!activeComparison && (
              <>
                <DataDisplay
                  title={t('currentInvestigation', {
                    investigationId:
                      data?.externalInvestigation.investigationId,
                  })}
                  data={data?.externalInvestigation}
                  isLoading={isLoading}
                />

                {selectedEvent && (
                  <SelectedEventContent
                    investigationId={+caseId}
                    selectedEvent={selectedEvent}
                  />
                )}
              </>
            )}

            {activeComparison && (
              <CompareEvents
                investigationId={+caseId}
                compareEvents={compareEvents}
              />
            )}
          </Flex>
        )}
      </PageWrapper>
    </>
  )
}

export default EventViewer
