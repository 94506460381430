import { FC, memo, useState } from 'react'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import { Button, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { joinClassNames } from '@signifyd/utils'
import styles from './SavedFilters.less'

interface Props {
  onClick: (key: string) => void
  onDelete: (key: string) => Promise<void>
  title: string
  itemKey: string
  flex: boolean
  isCurrentFilter: boolean
}

const SavedFilterItem: FC<Props> = ({
  onClick,
  onDelete,
  title,
  itemKey,
  flex,
  isCurrentFilter,
}) => {
  const { t } = useTranslation()
  const [deleting, setDeleting] = useState(false)

  const deleteHandler = (): void => {
    setDeleting(true)
    onDelete(itemKey).catch(() => setDeleting(false))
  }

  const clickHandler = (): void => onClick(itemKey)

  return (
    <div
      className={joinClassNames([
        styles.savedFilterItem,
        flex && styles.flexFlow,
      ])}
    >
      <Button
        data-analytics-id="saved-filter"
        data-test-id="saved-filter"
        type="link"
        onClick={clickHandler}
        className={joinClassNames([styles.button, styles.text])}
      >
        {title}
      </Button>
      {isCurrentFilter && (
        <span
          data-test-id={`selectedFilter-${title}`}
          className={styles.selected}
        >
          {t('filters.selected')}
        </span>
      )}
      <Tooltip title={t('filters.delete')}>
        <Button
          type="link"
          className={joinClassNames([
            styles.button,
            styles.delete,
            deleting && styles.visible,
          ])}
          onClick={deleteHandler}
        >
          <LegacyIcon
            data-test-id={
              deleting ? 'deleteFilterLoadingIcon' : 'deleteFilterIcon'
            }
            type={deleting ? 'loading' : 'delete'}
          />
        </Button>
      </Tooltip>
    </div>
  )
}

export default memo(SavedFilterItem)
