import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import EmptyWarning from '../EmptyWarning'

const NoInformation: FC = () => {
  const { t } = useTranslation()

  return <EmptyWarning text={t('pages.caseReview.details.noInformation')} />
}

export default NoInformation
