import { FC } from 'react'
import { Trans } from 'react-i18next'
import { ExternalLink } from '@signifyd/components'

export const SecondChargeback: FC = () => (
  <Trans
    i18nKey="claimsBanner.representmentDetails.secondChargeback"
    components={{
      helpArticleLink: (
        <ExternalLink
          target="_blank"
          url="https://www.signifyd.com/resources/fraud-101/chargeback-process-in-depth/#secondChargeback"
        >
          here
        </ExternalLink>
      ),
    }}
  />
)

export default SecondChargeback
