export enum MARKER_KEYS {
  billingAddress = 'BA',
  deliveryAddress = 'DA',
  ipGeo = 'IP',
  flightOrigin = 'FO',
}

export enum COMBINED_MARKER_KEYS {
  BA = 'BA',
  DA = 'DA',
  IP = 'IP',
  FO = 'FO',
  BA_DA = 'BA_DA',
  BA_IP = 'BA_IP',
  DA_IP = 'DA_IP',
  BA_DA_IP = 'BA_DA_IP',
}

export enum ROLES {
  billingAddress = 'billingAddress',
  deliveryAddress = 'deliveryAddress',
  ipGeo = 'ipGeo',
  flightOrigin = 'flightOrigin',
}

export interface Location {
  details: {
    latitude: number
    longitude: number
  }
  role: ROLES
}

export type Locations = Array<Location>

export type LocationToMarker = {
  lat: number
  lng: number
  type: COMBINED_MARKER_KEYS
}

export type GMapMap = {
  fitBounds: any
  panTo: ({ lat, lng }: { lat: number; lng: number }) => void
  center: {
    lat: () => number
    lng: () => number
  }
  zoom: number
}

export type GMapMaps = {
  LatLngBounds: any
  LatLng: any
  event: any
}
