type ShowHideItems<Items extends Array<unknown>> = {
  items: Items
  showAll: boolean
  maxDefaultExpandedItems?: number
}

interface ShowHideResponse<T> {
  maxCountItems: boolean
  itemsToShow: Array<T>
}

export const getShowHideItems = <Items>({
  items,
  showAll,
  maxDefaultExpandedItems = 3,
}: ShowHideItems<Array<Items>>): ShowHideResponse<Items> => {
  const maxCountItems = items.length > maxDefaultExpandedItems
  const itemsToShow = showAll ? items : items.slice(0, maxDefaultExpandedItems)

  return { maxCountItems, itemsToShow }
}
