import { getCaseSearchUrl, i18nInstance } from '@signifyd/components'
import { googleSearchLink, piplSearchLink } from '../DeepLinkConstants'
import {
  DeepLinkFactory,
  MENU_SECTION_TYPE,
  MENU_ICON,
  MenuItem,
} from '../DeepLinkDropdown.types'

const getDeepLinksAccounts: DeepLinkFactory = ({
  caseEntries,
  type,
  hasPowersearch,
}) => {
  const { data, filter } =
    type === MENU_SECTION_TYPE.confirmationEmail
      ? {
          data: caseEntries.confirmationEmail,
          filter: 'recipientConfirmationEmail',
        }
      : { data: caseEntries.accountEmail, filter: 'userAccountEmail' }

  const encodedSigSearchURL = `${getCaseSearchUrl()}?${filter}=${
    data?.entityName
  }`

  return [
    hasPowersearch && {
      icon: MENU_ICON.SigLogoSmall,
      text: i18nInstance.t('pages.caseReview.details.deepLinks.sigSearch'),
      url: encodedSigSearchURL,
    },
    {
      icon: MENU_ICON.GoogleSearchIcon,
      text: i18nInstance.t('pages.caseReview.details.deepLinks.emailSearch'),
      url: googleSearchLink + String(data?.entityName),
    },
    {
      icon: MENU_ICON.PiplIcon,
      text: i18nInstance.t('pages.caseReview.details.deepLinks.piplSearch'),
      url: `${piplSearchLink}${data?.entityName}&l=&sloc=&in=6`,
    },
    {
      icon: MENU_ICON.EkataIcon,
      text: i18nInstance.t('pages.caseReview.details.deepLinks.ekataSearch'),
      url: `https://app.ekata.com/emails?email=${data?.entityName}`,
    },
  ].filter((deepLink): deepLink is MenuItem => !!deepLink)
}

export default getDeepLinksAccounts
