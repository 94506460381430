import { FC, ReactNode, memo, useState } from 'react'
import { Card, Col, Button } from 'antd'
import { Text } from '@signifyd/components'
import { joinClassNames } from '@signifyd/utils'
import { CaseAnalysisItem } from '@signifyd/http'
import { useTranslation } from 'react-i18next'
import styles from './SummaryPanel.less'

interface AnalysisDetails {
  itemName: string
  meaning: string
  shortDescription: string
}

function renderSummaryItems(items: Array<AnalysisDetails>): ReactNode {
  return (
    <ul className={styles.summaryList} data-test-id="summaryList">
      {items.map(({ shortDescription, itemName, meaning }) => (
        <li
          key={itemName}
          className={joinClassNames([styles.summaryItem, styles[meaning]])}
          data-test-id="summaryItem"
        >
          <div className={styles.text}>
            <Text size="md">{shortDescription}</Text>
          </div>
        </li>
      ))}
    </ul>
  )
}

interface Props {
  title: string
  analysis?: CaseAnalysisItem
  isLarge: boolean
  actions?: Array<ReactNode>
}

export const SummaryPanel: FC<Props> = ({
  isLarge,
  title,
  analysis,
  actions,
}) => {
  const [showMore, setShowMore] = useState(false)
  const { t } = useTranslation()

  const items = analysis
    ? analysis.details.filter(({ shortDescription }) => !!shortDescription)
    : []

  const first3Items = items.splice(0, 3)

  return (
    <Col
      className={joinClassNames([styles.summaryPanel, isLarge && styles.large])}
      data-test-id={`summaryPanel${title}`}
    >
      <Card
        styles={{ body: { height: '100%' } }}
        className={styles.content}
        loading={!analysis}
        actions={actions}
        size="small"
        title={title}
      >
        {!first3Items.length && (
          <span data-test-id="noAnalysis">
            {t('caseIntelligence.noAnalysis')}
          </span>
        )}
        {analysis && renderSummaryItems(first3Items as Array<AnalysisDetails>)}

        {!!items.length &&
          (showMore ? (
            renderSummaryItems(items as Array<AnalysisDetails>)
          ) : (
            <Button
              data-analytics-id={`intelligence-${title}-show-more`}
              data-test-id="showMore"
              type="link"
              style={{ padding: 0 }}
              onClick={() => setShowMore(true)}
            >
              {t('caseIntelligence.showMore', {
                length: items.length,
              })}
            </Button>
          ))}
      </Card>
    </Col>
  )
}

export default memo(SummaryPanel)
