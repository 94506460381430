import { FC } from 'react'
import Icon from '@ant-design/icons'
import { Text, SeederIcon } from '@signifyd/components'
import AdditionalValues from 'core/components/AdditionalValues'
import styles from './EnhancedSeederLabel.less'

export interface EnhancedSeederLabelProps {
  label: string
  values?: Array<string>
  dataTestId?: string
}

const EnhancedSeederLabel: FC<EnhancedSeederLabelProps> = ({
  label,
  values = [],
  dataTestId,
}) => {
  const sortedValues = values.sort()

  return (
    <>
      <span data-test-id={dataTestId}>
        {label}
        {sortedValues.length > 0 && (
          <Text data-test-id="enhancedSeederValues">
            {' '}
            ({sortedValues.slice(0, 2).join(', ')}
            <AdditionalValues values={sortedValues} />)
          </Text>
        )}
      </span>
      <Icon
        data-test-id="seederLabelIcon"
        component={SeederIcon}
        className={styles.icon}
      />
    </>
  )
}

export default EnhancedSeederLabel
