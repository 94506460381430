import { FC } from 'react'
import { Trans } from 'react-i18next'
import { ExternalLink } from '@signifyd/components'

export const NotChallenged: FC = () => (
  <Trans
    i18nKey="claimsBanner.representmentDetails.notChallenged"
    components={{
      helpArticleLink: (
        <ExternalLink
          target="_blank"
          url="https://community.signifyd.com/support/s/article/chargeback-representment#friendlyfraudsignifyd"
        >
          here
        </ExternalLink>
      ),
    }}
  />
)

export default NotChallenged
