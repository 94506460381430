import { FC, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { GuaranteeIcon } from '@signifyd/components'
import moment from 'moment-timezone'
import { colorBondi } from '@signifyd/colors'
import { useStoreActions, useStoreState } from 'store'
import ResubmitGuaranteeModal from 'pages/CaseReviewPage/containers/ResubmitGuaranteeModal'
import { STATIC_NOTES } from 'store/constants'
import { ResubmitGuaranteeRequiredFields } from 'store/caseActions'
import styles from './ResubmitGuaranteeButton.less'
import ActionButton from '../ActionButton'

interface Props {
  caseDetails: ResubmitGuaranteeRequiredFields
}

export const ResubmitGuaranteeButton: FC<Props> = ({ caseDetails }) => {
  const { t } = useTranslation()
  const [modalVisible, setModalVisible] = useState(false)

  const { isLoading, resubmitStatus } = useStoreState(
    (state) => state.caseActions.resubmitGuarantee
  )
  const { getResubmitStatus } = useStoreActions(
    (state) => state.caseActions.resubmitGuarantee
  )

  useEffect(() => {
    getResubmitStatus(caseDetails)
  }, [caseDetails, getResubmitStatus])

  const [reReviewNote] = useStoreState(
    (state) => state.currentCase.notes
  ).filter(({ text }) => text === STATIC_NOTES.CASE_RE_REVIEW)

  const reSubmittedOn = reReviewNote
    ? `${t('caseActions.submittedOn')} ${moment(reReviewNote.createdAt).format(
        t('caseActions.dateTimeFormat')
      )}`
    : undefined

  if (!resubmitStatus) {
    return null
  }

  return (
    <>
      <ActionButton
        onClick={() => setModalVisible(true)}
        actionState={resubmitStatus}
        isLoading={isLoading}
        className={styles.submitGuaranteeIcon}
        icon={
          <GuaranteeIcon
            fill={resubmitStatus.disabled ? undefined : colorBondi}
          />
        }
        text={t('caseActions.reSubmitGuarantee.text')}
        tooltip={t('caseActions.reSubmitGuarantee.tooltip')}
        subText={reSubmittedOn}
        testId="resubmitGuaranteeButton"
      />

      <ResubmitGuaranteeModal
        caseDetails={caseDetails}
        visible={modalVisible}
        closeModal={() => setModalVisible(false)}
      />
    </>
  )
}

export default ResubmitGuaranteeButton
