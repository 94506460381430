export const BA = (
  <svg width="33" height="45" viewBox="0 0 66 90" data-test-id="markerBA">
    <defs>
      <path
        id="a"
        d="M32.896 0C14.73 0 0 14.726 0 32.895 0 51.062 32.896 90 32.896 90s32.896-38.937 32.896-57.105S51.065 0 32.896 0zm0 58.827c-14.606 0-26.446-11.84-26.446-26.446 0-14.606 11.84-26.447 26.446-26.447 14.606 0 26.447 11.841 26.447 26.447 0 14.605-11.841 26.446-26.447 26.446z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <circle cx="33" cy="32" r="32" fill="#FFF" fillOpacity=".66" />
      <g fillRule="nonzero">
        <use fill="#299236" fillRule="evenodd" xlinkHref="#a" />
        <path
          stroke="#44C354"
          strokeWidth="2"
          d="M32.896 88.437a165.708 165.708 0 0 1-.764.918h1.528a83.415 83.415 0 0 1-.764-.918zm0-28.61C17.738 59.827 5.45 47.54 5.45 32.381c0-15.158 12.288-27.447 27.446-27.447 15.158 0 27.447 12.289 27.447 27.447S48.054 59.827 32.896 59.827z"
        />
      </g>
      <g transform="translate(16 21)">
        <rect width="34" height="22" fill="#7EB23D" rx="3" />
        <path fill="#000" d="M0 6h34v5H0z" />
      </g>
    </g>
  </svg>
)

export const BA_DA = (
  <svg width="33" height="45" viewBox="0 0 66 90" data-test-id="markerBA_DA">
    <defs>
      <path
        id="a"
        d="M32.896 0C14.73 0 0 14.726 0 32.895 0 51.062 32.896 90 32.896 90s32.896-38.937 32.896-57.105S51.065 0 32.896 0zm0 58.827c-14.606 0-26.446-11.84-26.446-26.446 0-14.606 11.84-26.447 26.446-26.447 14.606 0 26.447 11.841 26.447 26.447 0 14.605-11.841 26.446-26.447 26.446z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <circle cx="33" cy="34" r="32" fill="#FFF" fillOpacity=".66" />
      <g fillRule="nonzero">
        <use fill="#F95648" fillRule="evenodd" xlinkHref="#a" />
        <path
          stroke="#412C2A"
          strokeWidth="2"
          d="M32.896 88.437a165.708 165.708 0 0 1-.764.918h1.528a83.415 83.415 0 0 1-.764-.918zm0-28.61C17.738 59.827 5.45 47.54 5.45 32.381c0-15.158 12.288-27.447 27.446-27.447 15.158 0 27.447 12.289 27.447 27.447S48.054 59.827 32.896 59.827z"
        />
      </g>
      <g fill="#123E7B" transform="translate(37 27)">
        <path
          fillRule="nonzero"
          d="M15.692 3h-5.23v4.667H0V11h1.962c0-1.104.878-2 1.961-2 1.083 0 1.962.896 1.962 2h5.884c0-1.104.879-2 1.962-2s1.961.896 1.961 2H17V5.667L15.692 3zm.464 3.364h-4.935V3.636h3.795l1.14 2.207v.521z"
        />
        <circle cx="3.75" cy="10.75" r="1.25" fillRule="nonzero" />
        <circle cx="13.75" cy="10.75" r="1.25" fillRule="nonzero" />
        <path d="M0 0h9.5v7H0z" />
      </g>
      <g transform="translate(12 27)">
        <rect width="18.545" height="12" fill="#7EB23D" rx="2" />
        <path fill="#000" d="M0 3.273h18.545V6H0z" />
      </g>
      <path
        fill="#80B539"
        d="M31.33 50.095c-.676-.402-1.792-1.164-2.53-1.643-.728-.435-1.017.126-1.59 1.065-.65 1.001.268 1.26 1.69 2.184l2.582 1.677c.93.529 1.36-.653 2.19-1.874l3.345-5.207c1.07-1.59 1.374-1.654.066-2.466-1.168-.796-1.125-.806-2.313 1.082-.557.8-3.25 5.005-3.44 5.182z"
      />
    </g>
  </svg>
)

export const BA_IP = (
  <svg width="33" height="45" viewBox="0 0 66 90" data-test-id="markerBA_IP">
    <defs>
      <path
        id="a"
        d="M32.896 0C14.73 0 0 14.726 0 32.895 0 51.062 32.896 90 32.896 90s32.896-38.937 32.896-57.105S51.065 0 32.896 0zm0 58.827c-14.606 0-26.446-11.84-26.446-26.446 0-14.606 11.84-26.447 26.446-26.447 14.606 0 26.447 11.841 26.447 26.447 0 14.605-11.841 26.446-26.447 26.446z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <circle cx="33" cy="34" r="32" fill="#FFF" fillOpacity=".66" />
      <g fillRule="nonzero">
        <use fill="#F95648" fillRule="evenodd" xlinkHref="#a" />
        <path
          stroke="#412C2A"
          strokeWidth="2"
          d="M32.896 88.437a165.708 165.708 0 0 1-.764.918h1.528a83.415 83.415 0 0 1-.764-.918zm0-28.61C17.738 59.827 5.45 47.54 5.45 32.381c0-15.158 12.288-27.447 27.446-27.447 15.158 0 27.447 12.289 27.447 27.447S48.054 59.827 32.896 59.827z"
        />
      </g>
      <g transform="translate(12 27)">
        <rect width="18.545" height="12" fill="#7EB23D" rx="2" />
        <path fill="#000" d="M0 3.273h18.545V6H0z" />
      </g>
      <path
        fill="#E97E07"
        fillRule="nonzero"
        d="M46.34 26.437c-2.367-1.004-5.299-1.078-7.74-.462l.32 2.177c2.034-.495 4.54-.433 6.52.407 1.978.84 3.763 2.6 4.82 4.407l1.788-1.282c-1.252-2.185-3.342-4.242-5.708-5.247zm-1.876 4.42c-1.711-.726-3.554-.823-5.323-.4l.322 2.17c1.362-.3 2.778-.21 4.1.352 1.322.561 2.371 1.516 3.1 2.705l1.786-1.277c-.925-1.565-2.275-2.824-3.985-3.55zm-4.933 4.01l.325 2.178c.688-.112 1.165.071 1.83.354.666.282 1.13.498 1.527 1.071l1.793-1.28c-.597-.947-1.364-1.465-2.419-1.913-1.054-.448-1.96-.64-3.056-.41z"
      />
      <path
        fill="#80B539"
        d="M31.33 50.095c-.676-.402-1.792-1.164-2.53-1.643-.728-.435-1.017.126-1.59 1.065-.65 1.001.268 1.26 1.69 2.184l2.582 1.677c.93.529 1.36-.653 2.19-1.874l3.345-5.207c1.07-1.59 1.374-1.654.066-2.466-1.168-.796-1.125-.806-2.313 1.082-.557.8-3.25 5.005-3.44 5.182z"
      />
    </g>
  </svg>
)

export const BA_DA_IP = (
  <svg width="33" height="45" viewBox="0 0 66 90" data-test-id="markerBA_DA_IP">
    <defs>
      <path
        id="a"
        d="M32.896 0C14.73 0 0 14.726 0 32.895 0 51.062 32.896 90 32.896 90s32.896-38.937 32.896-57.105S51.065 0 32.896 0zm0 58.827c-14.606 0-26.446-11.84-26.446-26.446 0-14.606 11.84-26.447 26.446-26.447 14.606 0 26.447 11.841 26.447 26.447 0 14.605-11.841 26.446-26.447 26.446z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <circle cx="33" cy="34" r="32" fill="#FFF" fillOpacity=".66" />
      <g fillRule="nonzero">
        <use fill="#F95648" fillRule="evenodd" xlinkHref="#a" />
        <path
          stroke="#412C2A"
          strokeWidth="2"
          d="M32.896 88.437a165.708 165.708 0 0 1-.764.918h1.528a83.415 83.415 0 0 1-.764-.918zm0-28.61C17.738 59.827 5.45 47.54 5.45 32.381c0-15.158 12.288-27.447 27.446-27.447 15.158 0 27.447 12.289 27.447 27.447S48.054 59.827 32.896 59.827z"
        />
      </g>
      <g fill="#123E7B" transform="translate(37 27)">
        <path
          fillRule="nonzero"
          d="M15.692 3h-5.23v4.667H0V11h1.962c0-1.104.878-2 1.961-2 1.083 0 1.962.896 1.962 2h5.884c0-1.104.879-2 1.962-2s1.961.896 1.961 2H17V5.667L15.692 3zm.464 3.364h-4.935V3.636h3.795l1.14 2.207v.521z"
        />
        <circle cx="3.75" cy="10.75" r="1.25" fillRule="nonzero" />
        <circle cx="13.75" cy="10.75" r="1.25" fillRule="nonzero" />
        <path d="M0 0h9.5v7H0z" />
      </g>
      <g transform="translate(12 27)">
        <rect width="18.545" height="12" fill="#7EB23D" rx="2" />
        <path fill="#000" d="M0 3.273h18.545V6H0z" />
      </g>
      <path
        fill="#E97E07"
        fillRule="nonzero"
        d="M36.34 11.437c-2.367-1.004-5.299-1.078-7.74-.462l.32 2.177c2.034-.495 4.54-.433 6.52.407 1.978.84 3.763 2.6 4.82 4.407l1.788-1.282c-1.252-2.185-3.342-4.242-5.708-5.247zm-1.876 4.42c-1.711-.726-3.554-.823-5.323-.4l.322 2.17c1.362-.3 2.778-.21 4.1.352 1.322.561 2.371 1.516 3.1 2.705l1.786-1.277c-.925-1.565-2.275-2.824-3.985-3.55zm-4.933 4.01l.325 2.178c.688-.112 1.165.071 1.83.354.666.282 1.13.498 1.527 1.071l1.793-1.28c-.597-.947-1.364-1.465-2.419-1.913-1.054-.448-1.96-.64-3.056-.41z"
      />
      <path
        fill="#80B539"
        d="M31.33 50.095c-.676-.402-1.792-1.164-2.53-1.643-.728-.435-1.017.126-1.59 1.065-.65 1.001.268 1.26 1.69 2.184l2.582 1.677c.93.529 1.36-.653 2.19-1.874l3.345-5.207c1.07-1.59 1.374-1.654.066-2.466-1.168-.796-1.125-.806-2.313 1.082-.557.8-3.25 5.005-3.44 5.182z"
      />
    </g>
  </svg>
)

export const DA = (
  <svg width="33" height="45" viewBox="0 0 66 90" data-test-id="markerDA">
    <defs>
      <path
        id="a"
        d="M32.896 0C14.73 0 0 14.726 0 32.895 0 51.062 32.896 90 32.896 90s32.896-38.937 32.896-57.105S51.065 0 32.896 0zm0 58.827c-14.606 0-26.446-11.84-26.446-26.446 0-14.606 11.84-26.447 26.446-26.447 14.606 0 26.447 11.841 26.447 26.447 0 14.605-11.841 26.446-26.447 26.446z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <circle cx="33" cy="34" r="32" fill="#FFF" fillOpacity=".66" />
      <g fillRule="nonzero">
        <use fill="#587195" fillRule="evenodd" xlinkHref="#a" />
        <path
          stroke="#2E558C"
          strokeWidth="2"
          d="M32.896 88.437a165.708 165.708 0 0 1-.764.918h1.528a83.415 83.415 0 0 1-.764-.918zm0-28.61C17.738 59.827 5.45 47.54 5.45 32.381c0-15.158 12.288-27.447 27.446-27.447 15.158 0 27.447 12.289 27.447 27.447S48.054 59.827 32.896 59.827z"
        />
      </g>
      <g fill="#123E7B" transform="translate(17 20)">
        <path
          fillRule="nonzero"
          d="M31.385 6H20.923v9.333H0V22h3.923c0-2.208 1.758-4 3.923-4 2.167 0 3.923 1.792 3.923 4h11.769c0-2.208 1.758-4 3.924-4s3.923 1.792 3.923 4H34V11.333L31.385 6zm.928 6.728h-9.871V7.272h7.59l2.28 4.414v1.042z"
        />
        <circle cx="7.5" cy="21.5" r="2.5" fillRule="nonzero" />
        <circle cx="27.5" cy="21.5" r="2.5" fillRule="nonzero" />
        <path d="M0 0h19v14H0z" />
      </g>
    </g>
  </svg>
)

export const DA_IP = (
  <svg width="33" height="45" viewBox="0 0 66 90" data-test-id="markerDA_IP">
    <defs>
      <path
        id="a"
        d="M32.896 0C14.73 0 0 14.726 0 32.895 0 51.062 32.896 90 32.896 90s32.896-38.937 32.896-57.105S51.065 0 32.896 0zm0 58.827c-14.606 0-26.446-11.84-26.446-26.446 0-14.606 11.84-26.447 26.446-26.447 14.606 0 26.447 11.841 26.447 26.447 0 14.605-11.841 26.446-26.447 26.446z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <circle cx="33" cy="34" r="32" fill="#FFF" fillOpacity=".66" />
      <g fillRule="nonzero">
        <use fill="#F95648" fillRule="evenodd" xlinkHref="#a" />
        <path
          stroke="#412C2A"
          strokeWidth="2"
          d="M32.896 88.437a165.708 165.708 0 0 1-.764.918h1.528a83.415 83.415 0 0 1-.764-.918zm0-28.61C17.738 59.827 5.45 47.54 5.45 32.381c0-15.158 12.288-27.447 27.446-27.447 15.158 0 27.447 12.289 27.447 27.447S48.054 59.827 32.896 59.827z"
        />
      </g>
      <g fill="#123E7B" transform="translate(37 27)">
        <path
          fillRule="nonzero"
          d="M15.692 3h-5.23v4.667H0V11h1.962c0-1.104.878-2 1.961-2 1.083 0 1.962.896 1.962 2h5.884c0-1.104.879-2 1.962-2s1.961.896 1.961 2H17V5.667L15.692 3zm.464 3.364h-4.935V3.636h3.795l1.14 2.207v.521z"
        />
        <circle cx="3.75" cy="10.75" r="1.25" fillRule="nonzero" />
        <circle cx="13.75" cy="10.75" r="1.25" fillRule="nonzero" />
        <path d="M0 0h9.5v7H0z" />
      </g>
      <path
        fill="#E97E07"
        fillRule="nonzero"
        d="M24.34 27.437c-2.367-1.004-5.299-1.078-7.74-.462l.32 2.177c2.034-.495 4.54-.433 6.52.407 1.978.84 3.763 2.6 4.82 4.407l1.788-1.282c-1.252-2.185-3.342-4.242-5.708-5.247zm-1.876 4.42c-1.711-.726-3.554-.823-5.323-.4l.322 2.17c1.362-.3 2.778-.21 4.1.352 1.322.561 2.371 1.516 3.1 2.705l1.786-1.277c-.925-1.565-2.275-2.824-3.985-3.55zm-4.933 4.01l.325 2.178c.688-.112 1.165.071 1.83.354.666.282 1.13.498 1.527 1.071l1.793-1.28c-.597-.947-1.364-1.465-2.419-1.913-1.054-.448-1.96-.64-3.056-.41z"
      />
      <path
        fill="#80B539"
        d="M31.33 50.095c-.676-.402-1.792-1.164-2.53-1.643-.728-.435-1.017.126-1.59 1.065-.65 1.001.268 1.26 1.69 2.184l2.582 1.677c.93.529 1.36-.653 2.19-1.874l3.345-5.207c1.07-1.59 1.374-1.654.066-2.466-1.168-.796-1.125-.806-2.313 1.082-.557.8-3.25 5.005-3.44 5.182z"
      />
    </g>
  </svg>
)

export const IP = (
  <svg width="33" height="45" viewBox="0 0 66 90" data-test-id="markerIP">
    <defs>
      <path
        id="a"
        d="M32.896 0C14.73 0 0 14.726 0 32.895 0 51.062 32.896 90 32.896 90s32.896-38.937 32.896-57.105S51.065 0 32.896 0zm0 58.827c-14.606 0-26.446-11.84-26.446-26.446 0-14.606 11.84-26.447 26.446-26.447 14.606 0 26.447 11.841 26.447 26.447 0 14.605-11.841 26.446-26.447 26.446z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <circle cx="34" cy="32" r="32" fill="#FFF" fillOpacity=".66" />
      <g fillRule="nonzero">
        <use fill="#E78A2B" fillRule="evenodd" xlinkHref="#a" />
        <path
          stroke="#BA6A1D"
          strokeWidth="2"
          d="M32.896 88.437a165.708 165.708 0 0 1-.764.918h1.528a83.415 83.415 0 0 1-.764-.918zm0-28.61C17.738 59.827 5.45 47.54 5.45 32.381c0-15.158 12.288-27.447 27.446-27.447 15.158 0 27.447 12.289 27.447 27.447S48.054 59.827 32.896 59.827z"
        />
      </g>
      <path
        fill="#E97E07"
        fillRule="nonzero"
        d="M38.211 19.203c-5.489-2.33-12.29-2.502-17.95-1.071l.741 5.048c4.719-1.148 10.53-1.005 15.12.943 4.59 1.949 8.73 6.03 11.182 10.222l4.147-2.974c-2.903-5.066-7.75-9.838-13.24-12.168zM33.86 29.454c-3.968-1.684-8.242-1.908-12.346-.93l.748 5.036c3.159-.698 6.442-.487 9.509.815s5.5 3.517 7.192 6.274l4.142-2.96c-2.147-3.631-5.278-6.55-9.245-8.235zm-11.44 9.3l.752 5.053c1.597-.26 2.704.165 4.247.82 1.544.655 2.618 1.156 3.54 2.485l4.158-2.968c-1.384-2.199-3.163-3.4-5.609-4.438-2.446-1.038-4.546-1.483-7.088-.952z"
      />
    </g>
  </svg>
)

export const FO = (
  <svg
    data-test-id="markerFO"
    width="34"
    height="45"
    viewBox="0 0 34 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="1" width="32" height="32" rx="16" fill="white" />
    <g clipPath="url(#clip0_2937_4954)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1656 21.7695C11.1669 21.2448 11.465 20.7317 11.9507 20.1575L9.14212 18.3815C8.96504 18.3046 8.96894 18.1966 9.07181 18.069L9.66686 17.5612C9.77493 17.4948 9.88952 17.4661 10.0132 17.5L13.4794 18.0859L16.3674 14.9583L9.62519 10.3971C9.45462 10.2968 9.4403 10.1836 9.61608 10.052L10.5887 9.276L19.3778 11.7461L21.9742 8.97001C22.8452 8.2161 23.6916 7.87886 24.3413 8.03902C24.6994 8.12756 24.8257 8.23433 24.9364 8.57027C25.1512 9.22912 24.8179 10.1145 24.0301 11.026L21.2541 13.6224L23.7242 22.4114L22.9481 23.3841C22.8166 23.5586 22.7033 23.5442 22.6031 23.375L18.0406 16.6341L14.913 19.5208L15.4989 22.9869C15.5327 23.1093 15.5054 23.2239 15.4377 23.3333L14.9299 23.9283C14.8036 24.0312 14.6942 24.0351 14.6174 23.858L12.8413 21.0494C12.2645 21.5364 11.7515 21.8346 11.2242 21.8346C11.176 21.8333 11.1656 21.8164 11.1656 21.7695Z"
        fill="#582C83"
      />
    </g>
    <path
      d="M17 0C7.61217 0 0 7.363 0 16.4475C0 25.531 17 45 17 45C17 45 34 25.5315 34 16.4475C34 7.3635 26.3894 0 17 0ZM17 29.4135C9.45191 29.4135 3.33323 23.4935 3.33323 16.1905C3.33323 8.8875 9.45191 2.967 17 2.967C24.5481 2.967 30.6673 8.8875 30.6673 16.1905C30.6673 23.493 24.5481 29.4135 17 29.4135Z"
      fill="#582C83"
    />
    <defs>
      <clipPath id="clip0_2937_4954">
        <rect width="16" height="16" fill="white" transform="translate(9 8)" />
      </clipPath>
    </defs>
  </svg>
)
