import { Shipment } from '@signifyd/http'
import { i18nInstance } from '@signifyd/components'
import { toTitleCase } from '@signifyd/utils'

const displayShippingMethod = (shipment?: Shipment): string => {
  return toTitleCase(
    shipment?.shippingMethod || i18nInstance.t('filters.shippingMethod.OTHER')
  )
}

export default displayShippingMethod
