import { message } from 'antd'
import { i18nInstance } from '@signifyd/components'
import { CreateClaimError, INV_GUARANTEE_DISPOSITION } from '@signifyd/http'
import { useCreateClaim } from '../../../queries/useCreateClaim'
import { CASE_CAPABILITIES, hasCapability } from '../../../utils/capabilities'
import {
  ACTION_CONTEXT,
  ClaimReimbursementRequiredFields,
  CUSTOMER_APPROVELIST_DECISION_REASONS,
  ReimbursementUpdate,
} from './types'
import {
  ActionState,
  getDisableActionState,
  getEnableActionState,
  getHideActionState,
} from '../utils'

const translationPrefix = 'store.caseActions.claimReimbursement'

export const canPerformAction = (
  caseDetails: ClaimReimbursementRequiredFields
): ActionState => {
  // 1. Hide if the customer has the - DISALLOW_CLAIM_SUBMISSION - capability
  if (
    hasCapability(
      caseDetails.teamId,
      caseDetails.customerId,
      CASE_CAPABILITIES.DISALLOW_CLAIM_SUBMISSION
    )
  ) {
    return getHideActionState()
  }
  // 2. Disable if order is a Test investigation
  if (caseDetails.isTestInvestigation) {
    return getDisableActionState(
      i18nInstance.t(`${translationPrefix}.testOrder`)
    )
  }
  // 3. Disable if order is not approved
  if (caseDetails.guaranteeDisposition !== INV_GUARANTEE_DISPOSITION.APPROVED) {
    return getDisableActionState(
      i18nInstance.t(`${translationPrefix}.notApproved`)
    )
  }
  // 4. Disable if order is approved by merchant policy rule
  if (
    caseDetails.guaranteeAutoDecisionReason ===
      CUSTOMER_APPROVELIST_DECISION_REASONS.APPROVE_APPROVELIST_CONFIRM_EMAIL ||
    caseDetails.guaranteeAutoDecisionReason ===
      CUSTOMER_APPROVELIST_DECISION_REASONS.APPROVE_APPROVELIST_DEL_ADDRESS
  ) {
    return getDisableActionState(
      i18nInstance.t(`${translationPrefix}.merchantPolicyRule`)
    )
  }

  return getEnableActionState()
}

export const useClaimReimbursement = (
  context: ACTION_CONTEXT
): {
  apiErrors: CreateClaimError | null
  isLoading: boolean
  performAction: (
    caseDetails: ClaimReimbursementRequiredFields,
    payload: ReimbursementUpdate
  ) => void
  reset: () => void
} => {
  const createClaim = useCreateClaim(context)

  const performAction = async (
    caseDetails: ClaimReimbursementRequiredFields,
    payload: ReimbursementUpdate
  ): Promise<void> => {
    const { disabled, hidden, disabledReason } = canPerformAction(caseDetails)

    if (disabled || hidden) {
      message.error(
        disabledReason || i18nInstance.t(`${translationPrefix}.notAllowed`)
      )

      return
    }

    createClaim.mutate({
      caseId: caseDetails.investigationId,
      reimbursementDetails: payload,
    })
  }

  return {
    apiErrors: createClaim.error,
    isLoading: createClaim.isLoading,
    performAction,
    reset: createClaim.reset,
  }
}
