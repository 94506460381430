import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { PageSpinner, Text } from '@signifyd/components'
import { Event } from '@signifyd/http'
import { useStoreState } from 'store'
import useGetInvestigationAtEvent from './useGetComparisonEvents'
import DataDisplay from '../DataDisplay/DataDisplay'
import { convertToTimestamp } from '../event.utils'

interface Props {
  investigationId: number
  compareEvents: Array<Event>
}

const CompareEvents: FC<Props> = ({ investigationId, compareEvents }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'eventViewer' })
  const timeZone = useStoreState((state) => state.user.timeZone.name)

  const { data, isLoading } = useGetInvestigationAtEvent(
    investigationId,
    compareEvents
  )

  if (!data) {
    return <PageSpinner />
  }

  const [firstEvent, secondEvent] = compareEvents
  const [investigation1, investigation2] = data

  if (!investigation1 || !investigation2) {
    return <Text size="lg">{t('unableToCompare')}</Text>
  }

  return (
    <>
      <DataDisplay
        title={t('investigationEvent', {
          eventName: firstEvent.name,
        })}
        subTitle={convertToTimestamp(firstEvent.processedAt, timeZone)}
        data={investigation1}
        isLoading={isLoading}
      />
      <DataDisplay
        title={t('investigationEvent', {
          eventName: secondEvent.name,
        })}
        subTitle={convertToTimestamp(secondEvent.processedAt, timeZone)}
        data={investigation2}
        isLoading={isLoading}
      />
    </>
  )
}

export default CompareEvents
