import { PhysicalAddress } from '@signifyd/http'

const formatFullAddress = (address: PhysicalAddress): string => {
  const { streetAddress, unit, city, provinceCode, postalCode, countryCode } =
    address

  return [streetAddress, unit, city, provinceCode, postalCode, countryCode]
    .filter((str) => str)
    .join(', ')
}

export default formatFullAddress
