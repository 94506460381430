import { TreeDataNode } from 'rc-tree-select/lib/interface'
import { i18nInstance } from '@signifyd/components'
import { FilterState } from 'store/search'
import {
  AdvancedFilterKey,
  AdvancedFilterConfig,
} from './AdvancedFiltersTree.types'

export const buildTreeData = (
  filterConfig: AdvancedFilterConfig
): Array<TreeDataNode> =>
  Object.entries(filterConfig).map(([key, options]) => {
    const typedKey = key as AdvancedFilterKey

    const { t } = i18nInstance

    const rootTitle = t(`filters.advanced.${typedKey}.label`)

    return {
      title: rootTitle,
      'data-analytics-id': `advanced-filter-option-${key}`,
      'data-test-id': `advancedFilterOption${key}`,
      tagLabel: rootTitle,
      value: key,
      key,
      selectable: false,
      children: (options as Array<string>).map((value) => ({
        // TODO FET-1807 https://signifyd.atlassian.net/browse/FET-1807
        // Replace anys with ValueOf<> once we have that exported - ValueOf<AdvancedFilterConfig[typedKey]>
        title: t(`filters.advanced.${typedKey}.${value}` as any),
        'data-test-id': `advancedFilterOption${typedKey}${value}`,
        tagLabel: `${rootTitle}: ${t(
          `filters.advanced.${typedKey}.${value}` as any
        )}`,
        key: `${key}__${value}`,
        value: `${key}__${value}`,
      })),
    }
  })

// if values in FilterState then map to `["key__value"]` for antd treeState
export const encodeFilterState = (
  treeData: Array<TreeDataNode>,
  filters: FilterState
): Array<string> => {
  return treeData.reduce((acc, { key }) => {
    const arrayForKey = (
      filters[key as AdvancedFilterKey] as Array<string>
    ).map((value) => `${key}__${value}`)

    return [...acc, ...arrayForKey]
  }, [] as Array<string>)
}

// map `[key__value]` back to `[key]: [valueA, valueB, ...] for FilterState
export const decodeFilterState = (
  value: Array<string>
): AdvancedFilterConfig => {
  return value.reduce((acc, item) => {
    const [key, value] = item.split('__') as [AdvancedFilterKey, never]
    if (acc[key]) {
      acc[key]?.push(value)
    } else {
      acc[key] = [value]
    }

    return acc
  }, {} as AdvancedFilterConfig)
}
