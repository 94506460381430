import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Spinner } from '@signifyd/components'
import { Event } from '@signifyd/http'
import { useStoreState } from 'store'
import DataDisplay from '../DataDisplay/DataDisplay'
import EventDataContainer from './EventDataContainer'
import useGetInvestigationAtEvent from './useGetInvestigationAtEvent'
import { convertToTimestamp } from '../event.utils'

interface Props {
  investigationId?: number
  selectedEvent: Event
}

const SelectedEventContent: FC<Props> = ({
  investigationId,
  selectedEvent,
}) => {
  const { t } = useTranslation()
  const timeZone = useStoreState((state) => state.user.timeZone.name)
  const { data, isLoading } = useGetInvestigationAtEvent(
    selectedEvent,
    investigationId
  )

  if (isLoading) {
    return <Spinner />
  }

  return (
    <>
      <DataDisplay
        title={t('eventViewer.investigationEvent', {
          eventName: selectedEvent.name,
        })}
        subTitle={convertToTimestamp(selectedEvent.processedAt, timeZone)}
        data={data?.investigation}
        isLoading={isLoading}
      />

      <EventDataContainer
        eventContent={data?.eventContent}
        isLoading={isLoading}
      />
    </>
  )
}

export default SelectedEventContent
