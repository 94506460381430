import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Descriptions } from 'antd'
import EmptyWarning from 'core/components/EmptyWarning'
import SeederLabel from 'pages/CaseReviewPage/components/SeederLabel'
import SellerField from './components/SellerField'
import { RuntimeSeller, SellerInformationMap } from '../SellerDetails.types'

const { Item: DescriptionItem } = Descriptions

interface Props {
  seller: RuntimeSeller
}

const SellerInformation: FC<Props> = ({ seller }) => {
  const { t } = useTranslation()
  const { sellerId, parentEntity, name, address, email, phone } = seller

  const noInfo =
    !sellerId &&
    !parentEntity &&
    !name &&
    !address?.fullAddress &&
    !email &&
    !phone

  const sellerMap: Array<SellerInformationMap> = [
    {
      key: 'sellerId',
      label: t('pages.caseReview.sellerDetails.sellerId'),
    },
    {
      key: 'parentEntity',
      label: t('pages.caseReview.sellerDetails.parentEntity'),
    },
    {
      key: 'name',
      label: t('pages.caseReview.sellerDetails.sellerName'),
    },
    {
      key: 'address',
      label: t('pages.caseReview.sellerDetails.sellerAddress'),
    },
    {
      key: 'email',
      label: t('pages.caseReview.sellerDetails.sellerEmail'),
    },
    {
      key: 'phone',
      label: t('pages.caseReview.sellerDetails.sellerPhoneNumber'),
    },
  ]

  return (
    <>
      <Descriptions
        title={
          <span id="sellerInformation">
            {t('pages.caseReview.sellerDetails.sellerInformation')}
          </span>
        }
        colon={false}
        size="small"
      >
        {sellerMap.map(({ key, label }) => {
          if (
            !seller[key] ||
            (key === 'address' && !seller.address.fullAddress)
          ) {
            return null
          }

          return (
            <DescriptionItem label={<SeederLabel label={label} />} key={key}>
              <SellerField seller={seller} field={key} />
            </DescriptionItem>
          )
        })}
      </Descriptions>
      {noInfo && (
        <EmptyWarning
          text={t('pages.caseReview.sellerDetails.noSellerInformation')}
        />
      )}
    </>
  )
}

export default SellerInformation
