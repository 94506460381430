export default {
  header: {
    orderCreatedAt: 'Created at: ',
    team: 'Team (store): {{- team}}',
    order: 'Order',
    caseId: 'Case ID: ',
    checkoutToken: 'Checkout token: ',
    count: '{{current}} of {{total}} results',
    orgId: 'Org ID',
    parentEntity: 'Parent entity',
    searchResults: 'Search results',
    search: 'Search',
    sellerId: 'Seller ID',
    trialOrder: 'Trial order',
    trialOrderTooltip:
      'Orders submitted during a trial not eligible for chargeback coverage, re-review, or order review services',
    testOrder: 'Test order',
    testOrderTip:
      'Test orders not eligible for chargeback coverage, re-review, or order review services',
    CLOSED: 'closed',
    OPEN: 'open',
    caseStatus: 'The status of this case is {{status}}',
    view: 'View',
    recordLocator: 'Record locator',
  },
  teamFilterModal: {
    title: 'View all orders from the past 14 days?',
    okText: 'View cases',
  },
  caseTabs: {
    order: 'Order',
    return: 'Return',
  },
  common: {
    showMore: 'Show {{count}} more',
    showLess: 'Show less',
    seederInfo: 'Seeder element (provided by buyer)',
  },
  details: {
    caseDetails: 'Case details',
    usedInList: 'Used in list:',
    addToListButton: 'Add to list',
    itemsCount: '{{count}} item',
    itemsCount_one: '{{count}} item',
    itemsCount_other: '{{count}} items',
    accounts: 'Accounts',
    emailAccounts: 'Email accounts',
    confirmationEmail: 'Confirmation email',
    accountEmail: 'Account email',
    locations: 'Locations',
    billingAddress: 'Billing address',
    deliveryAddress: 'Delivery address',
    creditCardIssuanceLocation: 'Credit card issuance location',
    ipGeo: 'IP geolocation',
    airportLocations: 'Airport locations',
    network: 'Network',
    purchaseIP: 'Purchase IP',
    emailDomain: 'Email domain',
    ipDomain: 'IP domain',
    organization: 'Organization',
    device: 'Device',
    deviceId: 'Device ID',
    ASNCarrier: 'ASN carrier',
    ipRegistree: 'IP registree',
    creditCardIssuer: 'Credit card issuer',
    people: 'People',
    cardHolder: 'Card holder',
    deliveryRecipient: 'Delivery recipient',
    accountHolderTaxId: 'Card holder tax ID',
    accountHolderTaxIdCountry: 'Card holder tax ID country',
    phone: 'Phone',
    confirmationPhone: 'Confirmation phone',
    accountHolderPhone: 'Account phone',
    normalizedAddress: {
      delivery: 'Normalized delivery address',
      billing: 'Normalized billing address',
      seller: 'Normalized seller address',
    },
    addressInsight: {
      deliveryPoint: 'Delivery point {{type}}',
      receivingMail: 'Receiving mail {{yesNo}}',
      true: 'Yes',
      false: 'Keep editing',
    },
    addressOccupants: {
      label: 'Delivery address occupant',
      age: 'Age {{ageRange}}',
      gender: 'Sex {{gender}}',
      phone: 'Phone {{phoneNumber}}',
      showAll: 'Show all',
      showLess: 'Show less',
    },
    deepLinks: {
      sigSearch: 'Search for other cases',
      emailSearch: 'Email search',
      piplSearch: 'Pipl search',
      ekataSearch: 'Ekata search',
      addressSearch: 'Address search',
      addressMapView: 'Address map view',
      googleFullNameAddressSearch: 'Full name, billing city and state search',
      googleLastNameAddressSearch: 'Last name, billing city and state search',
      ekataIdentityCheck: 'Ekata identity check',
      linkedinFullNameSearch: 'Full name search',
      phoneNumberSearch: 'Phone number search',
    },
    passenger: 'Passenger',
    leadPassenger: 'Lead passenger',
    noInformation: 'Information unavailable',
    confirmDeleteTitle: 'Remove from list?',
    confirmDeleteText:
      'This action will remove item immediately from the list.',
    confirmDeleteActionText: 'Remove',
    confirmCancelDeleteActionText: 'Cancel',
    showAll: 'Show all',
    showLess: 'Show less',
  },
  noOrderFound: {
    title: 'No order found',
    subTitle:
      "Oops — we either couldn't find the case by ID, or you don't have permission to access it",
  },
  errorLoadingOrder: {
    title: 'Apologies — our system is unable to load order. Refresh the page.',
    subTitle:
      'Apologies — our system failed momentarily. Refresh the page. If problem persists please contact support.',
  },
  returnDetails: {
    notFoundTitle: 'No return requested',
    returnDescription_one: 'Return requested on {{count}} item from this order',
    returnDescription_other:
      'Return requested on {{count}} items from this order',
    panelHeader: 'Return requested (ID {{id}})',
    descriptions: {
      requestDate: 'Request date',
      returnAction: 'Return action',
      refundMethod: 'Refund method',
      totalRefundAmount: 'Total refund amount',
      returnItem: 'Return item',
      itemId: 'Item ID',
      reason: 'Reason',
      itemPrice: 'Item price',
    },
  },
  sellerDetails: {
    sellerDetails: 'Seller details',
    sellerInformation: 'Seller information',
    noSellerInformation: 'Seller information unavailable',
    sellerId: 'Seller ID',
    parentEntity: 'Parent entity',
    sellerName: 'Seller name',
    sellerAddress: 'Seller address',
    sellerEmail: 'Seller email',
    sellerPhoneNumber: 'Seller phone number',
    sellerRecord: 'Seller record',
    noSellerRecord: 'Seller record unavailable',
    sellerTag: 'Seller tag',
    enrollmentDate: 'Enrollment date',
    aggregateOrderCount: 'Aggregate order count',
    aggregateOrderAmount: 'Aggregate order amount',
    deepLinks: {
      sigSearch: 'Search for other cases',
      emailSearch: 'Email search',
      piplSearch: 'Pipl search',
      ekataSearch: 'Ekata search',
      addressSearch: 'Address search',
      addressMapView: 'Address map view',
      googleSellerNameAddressSearch:
        'Seller name, address, city and state search',
      phoneNumberSearch: 'Phone number search',
    },
  },
  membershipDetails: {
    title: 'Membership details',
    membershipInformation: 'Membership information',
    membershipId: 'Membership ID',
    phoneNumber: 'Membership phone number',
    email: 'Membership email address',
    name: 'Membership name',
  },
}
