import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@signifyd/components'
import { get } from 'lodash'

interface Props {
  text: string
}

const FormattedOrderReviewChecklistItem: FC<Props> = ({ text }) => {
  const { t } = useTranslation()

  try {
    const data = JSON.parse(text)
    const checklist = get(data, 'OR_CL.ck') || []
    const comment = get(data, 'OR_CL.cm')

    return (
      <>
        <Text size="lg">{t('caseActivity.orderReviewChecklist')}</Text>
        {checklist.map(
          (
            item: { h: string; i?: Array<string>; t?: string },
            index: number
          ) => (
            <div key={index}>
              <Text weight="semibold">{`${item.h}: `}</Text>
              {item.i && <Text>{item.i.join(', ')}</Text>}
              {item.t && <div>{item.t}</div>}
            </div>
          )
        )}
        {comment && (
          <div>
            <Text weight="semibold">{t('caseActivity.comments')}</Text>
            <div>{comment}</div>
          </div>
        )}
      </>
    )
  } catch (e) {
    return <Text>{t('caseActivity.invalidJSON')}</Text>
  }
}

export default FormattedOrderReviewChecklistItem
