import { Recipient } from '@signifyd/http'

interface FormItem {
  updateKey: string
  required: boolean
  translationKey:
    | 'name'
    | 'unit'
    | 'street'
    | 'city'
    | 'state'
    | 'zip'
    | 'country'
  size: number
  initialValue: string | number | null
}

// Outer array is the Form
// Each inner array is a Row
// Each object within an inner Row is a Col/Input
const formItems = (recipient: Recipient): Array<Array<FormItem>> => [
  [
    {
      updateKey: 'fullName',
      required: true,
      translationKey: 'name',
      size: 15,
      initialValue: recipient.fullName,
    },
  ],
  [
    {
      updateKey: 'unit',
      required: false,
      translationKey: 'unit',
      size: 7,
      initialValue: recipient.address.unit,
    },
    {
      updateKey: 'streetAddress',
      required: true,
      translationKey: 'street',
      size: 14,
      initialValue: recipient.address.streetAddress,
    },
  ],
  [
    {
      updateKey: 'city',
      required: true,
      translationKey: 'city',
      size: 7,
      initialValue: recipient.address.city,
    },
    {
      updateKey: 'provinceCode',
      required: true,
      translationKey: 'state',
      size: 7,
      initialValue: recipient.address.provinceCode,
    },
  ],
  [
    {
      updateKey: 'postalCode',
      required: false,
      translationKey: 'zip',
      size: 7,
      initialValue: recipient.address.postalCode,
    },
    {
      updateKey: 'countryCode',
      required: true,
      translationKey: 'country',
      size: 14,
      initialValue: recipient.address.countryCode,
    },
  ],
]

export default formItems
