import { CASE_CAPABILITIES, ConfigItem } from './types'

export const getConfig = (name: CASE_CAPABILITIES): ConfigItem => {
  const config: Record<CASE_CAPABILITIES, ConfigItem> = {
    [CASE_CAPABILITIES.REQUIRE_REVIEW_DISPOSITION_FOR_GUARANTEE_SUBMIT]: {
      customerIds: {
        production: [24461, 29631 /* WalmartMX */],
        staging: [24461, 29631 /* WalmartMx */],
      },
    },
    [CASE_CAPABILITIES.DISALLOW_CLAIM_SUBMISSION]: {
      customerIds: {
        production: [2501 /* Todd Snyder */],
        staging: [2501 /* Todd Snyder */],
      },
    },
    [CASE_CAPABILITIES.ALLOW_10_ADDRESS_UPDATES]: {
      teamIds: {
        production: [2012201 /* ModaOperandi */],
        staging: [3476308 /* ModaOperandi */],
      },
    },
    [CASE_CAPABILITIES.ALLOW_180_DAYS_FOR_ADDRESS_UPDATES]: {
      teamIds: {
        production: [
          2012201 /* ModaOperandi */, 3874 /* Structube */,
          8501 /* Scuf Gaming */, 2011950 /* Red.com */,
        ],
        staging: [3476308 /* ModaOperandi */, 3479014 /* Structube */],
      },
    },
    [CASE_CAPABILITIES.REQUIRE_GOOD_FEEDBACK_REASON]: {
      customerIds: {
        production: [32296 /* Albertsons */, 34531 /* Arbonne */],
        staging: [3269841 /* Albertsons */],
      },
    },
  }

  return config[name]
}

export const hasCapability = (
  teamId: number,
  customerId: number,
  name: CASE_CAPABILITIES
): boolean => {
  const config = getConfig(name)
  if (!config) {
    return false
  }

  const backendEnv = process.env.BACKEND_ENV
  const requiredCustomerIds = config.customerIds?.[backendEnv] || []
  const requiredTeamIds = config.teamIds?.[backendEnv] || []

  return (
    requiredCustomerIds.includes(customerId) || requiredTeamIds.includes(teamId)
  )
}
