import { InvestigationInfo } from '@signifyd/http'

export enum CUSTOMER_APPROVELIST_DECISION_REASONS {
  APPROVE_APPROVELIST_DEL_ADDRESS = 'APPROVE_WHITELIST_DEL_ADDRESS',
  APPROVE_APPROVELIST_CONFIRM_EMAIL = 'APPROVE_WHITELIST_CONFIRM_EMAIL',
}

export type ClaimReimbursementRequiredFields = Pick<
  InvestigationInfo,
  | 'investigationId'
  | 'teamId'
  | 'customerId'
  | 'isTestInvestigation'
  | 'guaranteeDisposition'
  | 'guaranteeAutoDecisionReason'
>

export enum ACTION_CONTEXT {
  CaseReview,
  CaseSearch,
}

export interface AmountWithCurrency {
  amount?: number
  currencyCode: string
}

export interface ReimbursementUpdate {
  chargebackAmount: AmountWithCurrency
  chargebackFees?: AmountWithCurrency
  chargebackRefId?: string
  comments?: string
  issuerReportedDate?: string
  processorReasonDescription: string
  shipper: string
  trackingNumber: string
  payeeId?: string
  payoutMethod?: string
}
