import {
  INV_AUTH_GATEWAY_STATUS,
  INV_CASE_STATUS,
  INV_CASE_TYPE,
  INV_REVIEW_DISPOSITION,
  SEARCH_FIELD,
} from '@signifyd/http'
import { AdvancedFilterConfig } from './AdvancedFiltersTree.types'

export const filterConfig: AdvancedFilterConfig = {
  [SEARCH_FIELD.caseStatus]: [INV_CASE_STATUS.OPEN, INV_CASE_STATUS.CLOSED],
  [SEARCH_FIELD.caseType]: Object.values(INV_CASE_TYPE),
  [SEARCH_FIELD.isTestInvestigation]: [true, false],
  [SEARCH_FIELD.investigationReviewDisposition]: [
    INV_REVIEW_DISPOSITION.NONE,
    INV_REVIEW_DISPOSITION.GOOD,
    INV_REVIEW_DISPOSITION.BAD,
  ],
  [SEARCH_FIELD.authorizationGatewayStatus]: Object.values(
    INV_AUTH_GATEWAY_STATUS
  ),
}
