export default {
  title: 'Payment',
  cvvResponse: 'CVV response',
  avsResponse: 'AVS response',
  cardNumber: 'Number',
  expiry: 'Expiration date',
  bank: 'Bank',
  authStatus: 'Authorization status',
  authNumber: 'Authorization number',
  failureReason: 'Failure reason',
  terms: 'Payment terms',
  none: 'None',
  paymentCardTip: 'Payment card type {{text}}',
  paymentTypeTip: 'Payment type {{text}}',
  paymentType: 'Payment type',
  paymentGatewayTip: 'Payment gateway {{text}}',
  unknownPaymentType: 'Payment type unknown',
  discountCodes: 'Discount',
  authorizationTransactionId: 'Transaction ID',

  scaEvaluation: {
    label: 'SCA evaluation',
    detail: 'Not evaluated',
    detail_DELEGATE_TO_PSP: 'Delegate to payment service provider',
    detail_NOT_EVALUATED: 'Not evaluated',
    detail_REQUEST_EXCLUSION: 'Request exclusion',
    detail_REQUEST_EXCLUSION_ONE_LEG_OUT: 'Request exclusion — one leg out',
    detail_REQUEST_EXCLUSION_MIT:
      'Request exclusion — merchant initiated transaction',
    detail_REQUEST_EXCLUSION_MOTO:
      'Request exclusion — mail order/telephone order',
    detail_REQUEST_EXCLUSION_ANONYMOUS_PREPAID:
      'Request exclusion — anonymous prepaid',
    detail_REQUEST_EXEMPTION: 'Request exemption',
    detail_REQUEST_EXEMPTION_LOW_VALUE: 'Request exemption — low value',
    detail_REQUEST_EXEMPTION_TRA:
      'Request exemption — transaction risk analysis',
  },
}
