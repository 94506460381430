import { FC, memo, useMemo } from 'react'
import { Tooltip, message } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import { useTokenDownload } from '@signifyd/components'
import { useTranslation } from 'react-i18next'
import {
  DOWNLOAD_COLUMN,
  INV_SEARCH_VIEW,
  SEARCH_FIELD,
  getExportToken,
} from '@signifyd/http'
import ResponsiveIconButton from 'core/components/ResponsiveIconButton'
import { antSort2SigSort } from 'core/utils/antSort2SigSort'
import invSearchQueryBuilder from 'core/utils/invSearchQueryBuilder'
import useUserHasAirlineOrders from 'pages/SearchResultsPage/queries/useUserHasAirlineOrders'
import { useStoreState } from 'store'
import { getEndOfDay, getPastStartDate } from 'core/utils/date.utils'
import TableColumnSelectDrawer from '../TableColumnSelectDrawer'
import styles from './SearchResultsTable.less'

interface Props {
  resultsTotal: number
  hasAirlineOrders: boolean
}

const TableTitle: FC<Props> = ({ hasAirlineOrders, resultsTotal }) => {
  const { t } = useTranslation()
  const { currentPage, filters, pageSize, searchTerm, sort, totalResults } =
    useStoreState((store) => store.search)
  const { currentTeams, currentUser } = useStoreState((store) => store.user)

  const {
    data: userHasAirlineOrders,
    isLoading: areDownloadDependenciesLoading,
  } = useUserHasAirlineOrders()

  const searchQuery = useMemo(() => {
    const dateFilters = {
      ...filters[SEARCH_FIELD.normalizedPurchaseCreatedAt],
    }

    const defaultDateRange = {
      min: getPastStartDate({
        subtractInterval: 'weeks',
        subtractValue: 2,
      }),
      max: getEndOfDay(),
    }

    const dateFiltersWithDefaults = {
      ...dateFilters,
      min: dateFilters.min ?? defaultDateRange.min,
      max: dateFilters.max ?? defaultDateRange.max,
    }

    const downloadColumn = userHasAirlineOrders
      ? DOWNLOAD_COLUMN.ORDERS_AIRLINE
      : DOWNLOAD_COLUMN.ORDERS

    return invSearchQueryBuilder({
      searchTerm,
      filters: {
        ...filters,
        [SEARCH_FIELD.normalizedPurchaseCreatedAt]: dateFiltersWithDefaults,
        [SEARCH_FIELD.teamId]: currentTeams,
      },
      sort: antSort2SigSort(sort),
      pagination: { currentPage, size: pageSize },
      view: INV_SEARCH_VIEW.INVESTIGATION_SUMMARY,
      columns: [downloadColumn],
    })
  }, [
    currentPage,
    currentTeams,
    filters,
    pageSize,
    searchTerm,
    sort,
    userHasAirlineOrders,
  ])

  const {
    downloadSearchResults,
    isBackgroundDownloadActive,
    isDownloadPending,
  } = useTokenDownload({
    getTokenFunction: getExportToken,
    getTokenPayload: searchQuery,
    baseDownloadUrl: `${BASE_URL}/v2/investigations/searchExport/${currentUser?.userId}`,
    onError: () => message.error(t('pages.results.downloadButton.failure')),
  })

  const isDownloadDisabled =
    isBackgroundDownloadActive ||
    isDownloadPending ||
    areDownloadDependenciesLoading ||
    !totalResults

  const ButtonWrapper: FC = ({ children }) => {
    if (isDownloadDisabled) {
      return (
        <Tooltip
          placement="left"
          title={t('pages.results.downloadButton.unavailable')}
          className={styles.downloadButton}
        >
          <div data-test-id="downloadButtonTooltip">{children}</div>
        </Tooltip>
      )
    }

    return <>{children}</>
  }

  return (
    <div className={styles.title}>
      <span data-test-id="tableTitle">
        {t('pages.results.table.title', { count: resultsTotal })}
      </span>
      <div className={styles.additionalOptions}>
        <TableColumnSelectDrawer hasAirlineOrders={hasAirlineOrders} />
        <ButtonWrapper>
          <ResponsiveIconButton
            data-analytics-id="download-button"
            data-test-id="downloadButton"
            buttonClass={styles.downloadButton}
            text={t('pages.results.downloadButton.title')}
            Icon={<DownloadOutlined />}
            onClick={downloadSearchResults}
            disabled={isDownloadDisabled}
            loading={isDownloadPending}
          />
        </ButtonWrapper>
      </div>
    </div>
  )
}

export default memo(TableTitle)
