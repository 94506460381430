import { FC } from 'react'
import { Card } from 'antd'
import { Space } from '@signifyd/components'
import NotFound from 'core/components/NotFound'

interface Props {
  title: string
}

/**
 * A placeholder that takes up full height on the CaseReview page (matching the height of the actions card)
 */
const NotFoundPagePlaceholder: FC<Props> = ({ title }) => {
  return (
    <>
      <Card
        style={{ width: '100%', flex: 1 }}
        styles={{
          body: { height: '100%', display: 'flex' },
        }}
      >
        <NotFound title={title} />
      </Card>
      <Space size="md" />
    </>
  )
}

export default NotFoundPagePlaceholder
