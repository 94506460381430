import { FC } from 'react'
import { isNil } from 'lodash'
import { useTranslation } from 'react-i18next'
import { notNil } from '@signifyd/utils'
import { RangeFilter } from 'store/search'
import SummaryItem from '../SummaryItem'

interface Props {
  values: RangeFilter<number>
}

const ScoreItem: FC<Props> = ({ values }) => {
  const { t } = useTranslation()
  const { min, max } = values

  const rangeText = (): string => {
    if (notNil(min) && isNil(max)) {
      return t('filters.score.atLeast', { min })
    }
    if (notNil(max) && isNil(min)) {
      return t('filters.score.upTo', { max })
    }
    if (notNil(max) && notNil(min)) {
      return t('filters.score.range', { min, max })
    }

    return ''
  }

  return <SummaryItem label={t('filters.score.label')} value={rangeText()} />
}

export default ScoreItem
