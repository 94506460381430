import { FC, ReactNode } from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { Text } from '@signifyd/components'
import { Product } from '@signifyd/http'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import styles from './CaseHeader.less'

interface Props {
  products: Array<Product>
}

const TRUNCATE_LIMIT = 6

const AirlineNameRecord: FC<Props> = ({ products }) => {
  const { t } = useTranslation()

  const renderPassengerNameRecord = (products: Array<Product>): ReactNode => {
    const product = products.find((product) => !!product.itemId)

    if (!product?.itemId) {
      return ''
    }

    const { itemId } = product

    const passengerNameRecord =
      itemId.length > TRUNCATE_LIMIT
        ? `${itemId.substring(0, TRUNCATE_LIMIT)}...`
        : itemId

    return passengerNameRecord
  }

  const passengerNameRecord = renderPassengerNameRecord(products)

  if (!passengerNameRecord) {
    return null
  }

  return (
    <span className={cx([styles.orderItem, styles.passengerNameRecord])}>
      <Text className={styles.orderText} weight="semibold">
        {passengerNameRecord}
      </Text>
      <Tooltip
        overlayClassName={styles.tooltipText}
        title={
          <Text size="md" strong className={styles.tooltipText}>
            {t('pages.caseReview.header.recordLocator')}
          </Text>
        }
      >
        <InfoCircleOutlined
          className={styles.passengerNameRecordIcon}
          data-test-id="pnr-icon"
          title={t(`pages.caseReview.header.recordLocator`)}
        />
      </Tooltip>
    </span>
  )
}

export default AirlineNameRecord
