import { groupBy, map, isNil, filter, flow } from 'lodash/fp'

import {
  Location,
  Locations,
  LocationToMarker,
  MARKER_KEYS,
  ROLES,
} from './types'

// take in entries.physicalLocations and map to markers icons
export const mapPhysicalLocationsToMarkers = (
  locations: Locations
): Array<LocationToMarker> => {
  const mapLocationRoleToMarkerType = (groupedItems: {
    role: ROLES
  }): MARKER_KEYS => {
    return MARKER_KEYS[groupedItems.role]
  }

  const removeInvalidLocations = filter((location: Location) => {
    // This is temporary, to should be updated in FET-2263
    if (location?.role === ROLES.flightOrigin) {
      return (
        !isNil(location?.details?.latitude) &&
        !isNil(location?.details?.longitude) &&
        !!MARKER_KEYS[location?.role]
      )
    }

    return (
      !isNil(location?.details?.latitude) &&
      !isNil(location?.details?.longitude) &&
      !!MARKER_KEYS[location?.role]
    )
  })

  const groupLocations = groupBy(({ details }) => {
    return `${String(details.latitude)}_${String(details.longitude)}`
  })

  const convertLocationsToMarkers = map((groupedLocations: Locations) => {
    return {
      lat: groupedLocations[0].details.latitude,
      lng: groupedLocations[0].details.longitude,
      type: map(mapLocationRoleToMarkerType, groupedLocations).sort().join('_'),
    }
  })

  return flow([
    removeInvalidLocations,
    groupLocations,
    convertLocationsToMarkers,
  ])(locations)
}
