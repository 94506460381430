import { FC } from 'react'
import { Controller } from 'react-hook-form'
import { DatePicker } from '@signifyd/components'
import moment from 'moment-timezone'
import type { FieldProps } from './Fields.types'
import { DATE_FORMAT } from 'core/constants'
import FormItemWrapper from './FormItemWrapper'
import {
  NEW_CASE_DATE_FORMAT,
  useGetLabelText,
  useGetRequiredError,
} from './utils'

export const DateField: FC<FieldProps> = ({
  fieldName,
  control,
  errors,
  span,
  required,
  allowClear,
}) => {
  const labelText = useGetLabelText(fieldName)
  const errorText = useGetRequiredError(fieldName, errors)

  return (
    <FormItemWrapper
      span={span}
      labelText={labelText}
      errorText={errorText}
      required={required}
    >
      <Controller
        name={fieldName}
        control={control}
        render={({ field }) => {
          const value = field.value as string
          const momentValue = value
            ? moment(value, NEW_CASE_DATE_FORMAT)
            : undefined

          return (
            <DatePicker
              onChange={(value) => {
                field.onChange(value?.format(NEW_CASE_DATE_FORMAT))
              }}
              value={momentValue}
              format={DATE_FORMAT.date}
              disabledDate={(current) =>
                !!current && current > moment().endOf('day')
              }
              allowClear={allowClear}
              data-analytics-id={`date-field-${fieldName}`}
              data-test-id={`dateField-${fieldName}`}
            />
          )
        }}
      />
    </FormItemWrapper>
  )
}

export default DateField
