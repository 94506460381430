import { thunk, action, computed } from 'easy-peasy'
import { message } from 'antd'
import moment from 'moment-timezone'
import {
  getCurrentUser,
  listUserTeams,
  getUserUIPrefs,
  updateUserUIPrefs,
  replaceUserUIState,
  USER_FEATURE,
} from '@signifyd/http'
import { i18nInstance } from '@signifyd/components'
import { getUserConfigs } from '@signifyd/app-feature-configs'
import { UserModel } from './types'

const currentUserModel: UserModel = {
  // State
  currentUser: null,
  currentTeams: [],
  userTeams: [],
  uiPrefs: {},
  userConfigs: null,

  // Getters & Setters
  setCurrentUser: action((state, user) => {
    state.currentUser = user
  }),
  setCurrentTeams: action((state, teams) => {
    state.currentTeams = teams
  }),
  setUserTeams: action((state, teams) => {
    state.userTeams = teams
  }),
  setUserUiPrefs: action((state, uiPrefs) => {
    state.uiPrefs = uiPrefs
  }),
  setUserConfigs: action((state, configs) => {
    state.userConfigs = configs
  }),

  hiddenColumns: computed(
    [(store) => store.currentUser?.uiState],
    (uiState) => new Set(uiState?.searchHiddenColumns)
  ),
  timeZone: computed((state) => {
    const userTZPrefs = state.currentUser?.uiState?.timeZone

    return {
      name: userTZPrefs?.name || moment.tz.guess(),
      autoDetect: userTZPrefs?.autoDetect ?? null,
    }
  }),
  hasTeamRole: computed((state) => (id, role) => {
    const team = state.userTeams.find(({ teamId }) => teamId === id)

    return !!team && team.teamRole === role
  }),
  hasFeatureFlag: computed(({ currentUser }) => (featureFlag: USER_FEATURE) => {
    return !!currentUser?.features?.[featureFlag]
  }),

  // Thunks: Http Stuff
  getCurrentUser: thunk(async (actions) => {
    try {
      const {
        data: { user },
      } = await getCurrentUser()

      if (!user?.active) {
        throw new Error(!user ? 'User is not defined' : 'User is not active')
      }

      actions.setCurrentUser(user)

      await Promise.all([
        actions.getUserTeams(user.userId),
        actions.getUserUiPrefs(user.username),
      ])

      await actions.getUserFeatureConfigs({ user })
    } catch (e) {
      console.error(e)
    }
  }),

  getUserTeams: thunk(async (actions, userId) => {
    try {
      const {
        data: { teams },
      } = await listUserTeams(userId)

      actions.setUserTeams(teams)

      return teams
    } catch (e) {
      console.error(e)
      message.error(i18nInstance.t('store.user.userTeamsFailure'))

      return []
    }
  }),

  getUserUiPrefs: thunk((actions, username) => {
    return getUserUIPrefs(username)
      .then(({ data: prefs }) => {
        actions.setUserUiPrefs(prefs)
      })
      .catch(() => {
        message.error(i18nInstance.t('store.user.uiPrefsFailure'))
      })
  }),

  getUserFeatureConfigs: thunk(async (actions, { user }) => {
    try {
      const userConfigs = await getUserConfigs(user, 'V1')

      actions.setUserConfigs(userConfigs)
    } catch (e) {
      console.error(e)
    }
  }),

  updateUserUIPrefs: thunk((actions, { username, pref }, { getState }) => {
    const userUIPrefs = getState().uiPrefs || {}

    return updateUserUIPrefs(username, { ...userUIPrefs, ...pref })
      .then(() => {
        actions.getUserUiPrefs(username)
      })
      .catch(() => {
        message.error(i18nInstance.t('store.user.uiPrefsFailure'))
      })
  }),

  updateUserUIState: thunk((actions, newState, { getState }) => {
    const userUIState = getState().currentUser?.uiState || {}

    return replaceUserUIState({
      ...userUIState,
      ...newState,
    }).then(() => actions.getCurrentUser())
  }),
}

export default currentUserModel
