export default {
  currentInvestigation: 'Current investigation: {{investigationId}}',
  events: 'Events',
  timestamps: 'Timestamps',
  tags: 'Tags',
  viewEvents: 'View events',
  backToOrder: 'Back to order',
  eventContent: 'Event content',
  investigationEvent: 'Investigation at {{eventName}}',
  noEvents: 'No events found for investigation',
  compare: 'Compare',
  unableToCompare: 'Apologies. Unable to compare investigations',
  investigation: 'Investigation {{investigationId}}',
}
