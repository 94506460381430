import { i18nInstance } from '@signifyd/components'
import {
  DeepLinkFactory,
  MENU_SECTION_TYPE,
  MENU_ICON,
} from '../DeepLinkDropdown.types'
import { googleSearchLink } from '../DeepLinkConstants'

const getDeepLinksAddress: DeepLinkFactory = ({ type, caseDetails, value }) => {
  const emptyAddressObj = {
    fullAddress: '',
    countryCode: '',
    latitude: '',
    longitude: '',
    streetAddress: '',
    city: '',
    provinceCode: '',
    unit: '',
  }

  const recipientAddress = caseDetails?.recipients.find((recipient) => {
    if (recipient.address.streetAddress) {
      return value?.includes(recipient.address.streetAddress)
    }
    if (recipient.address.postalCode) {
      return value?.includes(recipient.address.postalCode)
    }

    return false
  })

  const typeOfAddress =
    type === MENU_SECTION_TYPE.billingAddress
      ? caseDetails?.billingAddress
      : recipientAddress?.address

  const address = typeOfAddress || emptyAddressObj
  const unit = address.streetAddress && address.unit ? ` ${address.unit}` : ''

  const ekataSearchQuery =
    `street=${address.streetAddress}` +
    `${unit}` +
    `&where=${address.city} ${address.provinceCode}`

  return [
    {
      icon: MENU_ICON.GoogleSearchIcon,
      text: i18nInstance.t('pages.caseReview.details.deepLinks.addressSearch'),
      url: googleSearchLink + address.fullAddress,
    },
    {
      icon: MENU_ICON.GoogleMapIcon,
      text: i18nInstance.t('pages.caseReview.details.deepLinks.addressMapView'),
      url: `https://maps.google.com/maps?z=20&t=k&q=${address.fullAddress},${address.countryCode}@${address.latitude}+${address.longitude}`,
    },
    {
      icon: MENU_ICON.WhitepagesIcon,
      text: i18nInstance.t('pages.caseReview.details.deepLinks.addressSearch'),
      url: `http://www.whitepages.com/search/FindNearby?utf8=true&street=${address.streetAddress}&where=${address.city} ${address.provinceCode}`,
    },
    {
      icon: MENU_ICON.EkataIcon,
      text: i18nInstance.t('pages.caseReview.details.deepLinks.addressSearch'),
      url: `https://app.ekata.com/addresses?${ekataSearchQuery}`,
    },
  ]
}

export default getDeepLinksAddress
