export default {
  teamSelect: 'Team select and date picker failed to load.',
  aggregatePanel: 'Aggregate panel failed to load',
  searchFilters: 'Search filters failed to load.',
  savedFilters: 'Saved filters failed to load.',
  orderDetails: 'Order details failed to load.',
  actions: 'Actions failed to load.',
  activity: 'Activity failed to load.',
  caseDetails: 'Case details failed to load.',
  sellerDetails: 'Seller details failed to load.',
  locationDetails: 'Location details failed to load.',
  organizationDetails: 'Organization details failed to load.',
  intelligence: 'Signifyd intelligence failed to load.',
  paymentSummary: 'Payment summary failed to load.',
  accountSummary: 'Account summary failed to load.',
  shippingSummary: 'Shipping summary failed to load.',
  orderSummary: 'Order summary failed to load.',
  searchResults: 'Search results failed to load.',
  travelDetails: 'Travel details failed to load.',
  airlineTripDetails: 'Airline trip details failed to load.',
}
