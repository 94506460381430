import { FC, useState } from 'react'
import { CloseOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { Recipient, Shipment } from '@signifyd/http'
import { Address, UpdateAddressRequiredFields } from 'store/caseActions/types'
import { useStoreActions } from 'store'
import { COMMON_MODAL_PROPS } from 'core/constants'
import UpdateAddressList from './UpdateAddressList'
import UpdateAddressConfirmation from './UpdateAddressConfirmation'
import UpdateAddressForm, { Props as FormProps } from './UpdateAddressForm'

interface Props {
  visible: boolean
  closeModal: () => void
  caseDetails: UpdateAddressRequiredFields
}

export enum UPDATE_STAGE {
  SELECT = 'select',
  UPDATE = 'update',
  CONFIRM = 'confirm',
}

const initializeAddresses = (
  recipients: Array<Recipient>,
  shipments: Array<Shipment>
): Array<Address> =>
  recipients.map((recipient) => {
    const matchingShipment = shipments.find(
      (shipment) => shipment.shipmentId === recipient.shipmentId
    )

    return {
      oldAddress: recipient,
      shipment: matchingShipment,
    }
  })

const UpdateAddressModal: FC<Props> = ({
  visible,
  closeModal,
  caseDetails,
}) => {
  const { recipients, shipments } = caseDetails
  const { t } = useTranslation()

  // Local State
  const [hasErrors, setHasErrors] = useState<boolean>(false)
  const [addresses, setAddresses] = useState<Array<Address>>(
    initializeAddresses(recipients, shipments)
  )
  const [setFormRef] = useState<{ props: FormProps }>()
  const [updateStage, setUpdateStage] = useState<UPDATE_STAGE>(
    UPDATE_STAGE.SELECT
  )

  const changeCount = addresses.filter((address) => address.newAddress).length

  // Store State / Actions
  const updateAddresses = useStoreActions(
    (actions) => actions.caseActions.updateAddress.performAction
  )

  // Handlers
  const resetModal = (clearModal: boolean): void => {
    setHasErrors(false)
    setAddresses(initializeAddresses(recipients, shipments))
    setUpdateStage(UPDATE_STAGE.SELECT)

    if (clearModal) {
      closeModal()
    }
  }

  const confirmUpdateHandler = (): void => {
    updateAddresses({
      caseDetails,
      payload: addresses,
    }).then(() => resetModal(true))
  }

  const okActionByStage = (): void => {
    if (updateStage === UPDATE_STAGE.SELECT) {
      return setUpdateStage(UPDATE_STAGE.UPDATE)
    }
    if (updateStage === UPDATE_STAGE.UPDATE) {
      return setUpdateStage(UPDATE_STAGE.CONFIRM)
    }

    return confirmUpdateHandler()
  }

  const cancelActionByStage = (
    eventType: React.MouseEvent<HTMLElement>
  ): void => {
    if (!eventType || updateStage === UPDATE_STAGE.SELECT) {
      return resetModal(true)
    }
    if (updateStage === UPDATE_STAGE.UPDATE) {
      resetModal(false)

      return setUpdateStage(UPDATE_STAGE.SELECT)
    }

    return setUpdateStage(UPDATE_STAGE.UPDATE)
  }

  return (
    <Modal
      {...COMMON_MODAL_PROPS}
      title={
        updateStage === UPDATE_STAGE.SELECT
          ? t('caseActions.updateAddress.modalTitle')
          : undefined
      }
      open={visible}
      data-test-id="update-address-modal"
      okType="link"
      okText={t('caseActions.updateAddress.okText', {
        context: updateStage,
        count: changeCount || undefined,
      })}
      cancelText={t('caseActions.updateAddress.cancelText', {
        context: updateStage,
      })}
      closable
      closeIcon={<CloseOutlined onClick={closeModal} />}
      okButtonProps={{
        disabled: hasErrors || !changeCount,
      }}
      onOk={okActionByStage}
      onCancel={(e) => cancelActionByStage(e)}
    >
      <>
        {updateStage === UPDATE_STAGE.SELECT && (
          <UpdateAddressList
            addresses={addresses}
            setAddresses={setAddresses}
          />
        )}
        {updateStage === UPDATE_STAGE.UPDATE && (
          <UpdateAddressForm
            addresses={addresses}
            setAddresses={setAddresses}
            setHasErrors={setHasErrors}
            wrappedComponentRef={setFormRef}
          />
        )}
        {updateStage === UPDATE_STAGE.CONFIRM && (
          <UpdateAddressConfirmation addresses={addresses} />
        )}
      </>
    </Modal>
  )
}

export default UpdateAddressModal
