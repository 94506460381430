import { i18nInstance } from '@signifyd/components'
import { googleSearchLink, piplSearchLink } from '../DeepLinkConstants'
import { DeepLinkFactory, MENU_ICON } from '../DeepLinkDropdown.types'

const getDeepLinksEmail: DeepLinkFactory = ({ seller, field }) => {
  const value = seller[field]

  return [
    {
      icon: MENU_ICON.GoogleSearchIcon,
      text: i18nInstance.t(
        'pages.caseReview.sellerDetails.deepLinks.emailSearch'
      ),
      url: googleSearchLink + String(value),
    },
    {
      icon: MENU_ICON.PiplIcon,
      text: i18nInstance.t(
        'pages.caseReview.sellerDetails.deepLinks.piplSearch'
      ),
      url: `${piplSearchLink}${value}&l=&sloc=&in=6`,
    },
    {
      icon: MENU_ICON.EkataIcon,
      text: i18nInstance.t(
        'pages.caseReview.sellerDetails.deepLinks.ekataSearch'
      ),
      url: `https://app.ekata.com/emails?email=${value}`,
    },
  ]
}

export default getDeepLinksEmail
