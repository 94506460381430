import { FC, memo } from 'react'
import { Button } from 'antd'
import { Text } from '@signifyd/components'
import { useTranslation } from 'react-i18next'
import { useStoreState } from 'store'
import { QUICKVIEW_KEYS } from 'store/search'
import styles from './QuickViews.less'

interface Props {
  onClick: (key: string) => void
}

const QuickViews: FC<Props> = ({ onClick }) => {
  const { t } = useTranslation()
  const quickviews = useStoreState((state) => state.search.quickviews)

  return (
    <>
      <Text size="md" transform="uppercase">
        {t('search.quickSearch.quickViews')}
      </Text>

      {Object.entries(quickviews).map(([key, { title }]) => (
        <Button
          data-analytics-id={`quick-view-${key}`}
          data-test-id={`quickView${key}`}
          key={key}
          type="link"
          onClick={() => onClick(key)}
          className={styles.quickViewItem}
        >
          {t(`search.quickSearch.${title as QUICKVIEW_KEYS}`)}
        </Button>
      ))}
    </>
  )
}
export default memo(QuickViews)
