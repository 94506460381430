import { Decision, Return } from '@signifyd/http'

interface ReturnWithDecision extends Return {
  decision: Decision
}

export const getReturnsWithDecisions = (
  returns: Array<Return>
): Array<ReturnWithDecision> => {
  return returns.filter((item) => item.decision) as Array<ReturnWithDecision>
}

export const showReturnsTag = (returns: Array<Return>): boolean => {
  const filteredDecisionsList = getReturnsWithDecisions(returns)

  return !!returns.length && !!filteredDecisionsList.length
}
