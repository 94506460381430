import { ForwardedRef, forwardRef, ForwardRefRenderFunction } from 'react'
import { Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { AttachFilesForUploadHook, NoteTextArea } from '@signifyd/components'
import { COMMON_MODAL_PROPS } from 'core/constants'

interface Props {
  visible: boolean
  maxLength: number
  isSubmitDisabled: boolean
  note: string
  attachedFilesData: AttachFilesForUploadHook
  onChange: (value: string) => void
  onCancel: () => void
  onSubmit: () => void
}

export const NoteModal: ForwardRefRenderFunction<HTMLDivElement, Props> = (
  {
    visible,
    note,
    onChange,
    onCancel,
    onSubmit,
    maxLength,
    isSubmitDisabled,
    attachedFilesData,
  },
  ref: ForwardedRef<HTMLDivElement>
) => {
  const { t } = useTranslation()

  return (
    <Modal
      {...COMMON_MODAL_PROPS}
      title={t('caseActivity.addNotes')}
      open={visible}
      okText={t('caseActivity.addNotes')}
      cancelText={t('caseActivity.cancelButton')}
      onCancel={onCancel}
      okType="link"
      onOk={onSubmit}
      okButtonProps={{ disabled: isSubmitDisabled }}
    >
      <NoteTextArea
        placeholder={t('caseActivity.addNotesPlaceholder')}
        note={note}
        onChange={onChange}
        maxLength={maxLength}
        fileAttacher={attachedFilesData}
        ref={ref}
        attachFileButtonAnalyticsId="case-not-modal-attach-file-button"
        textAreaAnalyticsId="case-not-modal-text-area"
      />
    </Modal>
  )
}

export default forwardRef(NoteModal)
