import { getCaseSearchUrl, i18nInstance } from '@signifyd/components'
import { DeepLinkFactory, MENU_ICON } from '../DeepLinkDropdown.types'

const getDeepLinksNetwork: DeepLinkFactory = ({
  caseEntries,
  hasPowersearch,
}) => {
  const { purchaseIP } = caseEntries

  const encodedSigSearchURL = `${getCaseSearchUrl()}?browserIp=${
    purchaseIP?.entityName
  }`

  if (!hasPowersearch) {
    return []
  }

  return [
    {
      icon: MENU_ICON.SigLogoSmall,
      text: i18nInstance.t('pages.caseReview.details.deepLinks.sigSearch'),
      url: encodedSigSearchURL,
    },
  ]
}

export default getDeepLinksNetwork
