import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { INV_GUARANTEE_DISPOSITION } from '@signifyd/http'
import SummaryItem from '../SummaryItem'

interface Props {
  values: Array<INV_GUARANTEE_DISPOSITION>
}

const GuaranteeStatusItem: FC<Props> = ({ values }) => {
  const { t } = useTranslation()

  return (
    <SummaryItem
      label={t('filters.guaranteeStatus.label')}
      value={values
        .map((item) => t(`filters.guaranteeStatus.${item}`))
        .join(', ')}
    />
  )
}

export default GuaranteeStatusItem
