import { Text, SigTag, i18nInstance } from '@signifyd/components'
import { colorGlitter } from '@signifyd/colors'
import { AirlineTripDetail } from '@signifyd/http'
import styles from './AirlineTripDetails.less'

const { t } = i18nInstance

const PanelHeader = ({
  departurePortCode,
  arrivalPortCode,
  bookingClass,
  currency,
  slicePrice,
}: AirlineTripDetail): JSX.Element => (
  <>
    <Text
      data-test-id={`airlineTripHeader-${departurePortCode}-${arrivalPortCode}`}
      size="md"
      weight="semibold"
      className={styles.legTitle}
    >
      {t('airline.airlineTrip', {
        departurePortCode: departurePortCode?.toUpperCase(),
        arrivalPortCode: arrivalPortCode?.toUpperCase(),
      })}
    </Text>
    {bookingClass && (
      <SigTag data-test-id="bookingClass" color={colorGlitter} type="primary">
        <Text size="sm" className={styles.bookingClass}>
          {t('airline.bookingClass', {
            context: bookingClass,
          })}
        </Text>
      </SigTag>
    )}
    <SigTag data-test-id="currencyPrice" color={colorGlitter} type="primary">
      <Text size="sm" className={styles.currencyPrice}>
        {currency} {slicePrice?.toLocaleString()}
      </Text>
    </SigTag>
  </>
)

export default PanelHeader
