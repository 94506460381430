import { CSSProperties } from 'react'
import { ButtonProps } from 'antd/lib/button'

export const appRoot = document.getElementById('root')

export const COMMON_MODAL_PROPS = {
  cancelButtonProps: { type: 'link' } as ButtonProps,
  closable: false,
  style: { top: 92 } as CSSProperties,
  width: 604,
  destroyOnClose: true,
  maskClosable: false,
}

export const ALLOWED_UPLOAD_FILE_TYPES = 'image/*,.pdf,audio/*,video/*,.html'

export const ALLOWED_UPLOAD_FILE_TYPES_NOTE_ATTACHMENT =
  'image/*,audio/*,video/*,application/pdf'

// OCC-304 and OCC-305
export const PLATFORM_URL_PREFIXES_BY_TEAM_ID: {
  [key: number]: string
} = {
  7863: 'https://colourpop-prd.myshopify.com/admin/orders',
  6831: 'https://kylielipkit.myshopify.com/admin/orders',
}

export const DATE_FORMAT = {
  year: 'YYYY',
  date: 'M/D/YYYY',
  dateTime: 'M/D/YYYY h:mm A z',
  dateTimeMill: 'MM/DD/YYYY HH:mm:ss.SSS z',
  fileName: 'YYYYMMDD',
  apiDate: 'YYYY-MM-DD',
}

export const maxNoteLength = 1000
