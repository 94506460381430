import { FC } from 'react'
import { Loading3QuartersOutlined } from '@ant-design/icons'
import styles from './PageSpinner.less'

const PageSpinner: FC = () => (
  <div className={styles.wrapper} data-test-id="pageSpinner">
    <Loading3QuartersOutlined spin className={styles.icon} />
  </div>
)

export default PageSpinner
