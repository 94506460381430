import { colorPalette } from '@signifyd/colors/dist/colors'
import moment from 'moment-timezone'
import { DATE_FORMAT } from 'core/constants'
import { formatDateTimezone } from 'core/utils/date.utils'

export const convertToTimestamp = (
  dateTime: string,
  timeZone: string
): string =>
  formatDateTimezone({
    date: moment(dateTime),
    timeZone,
    format: DATE_FORMAT.dateTimeMill,
  })

export const tagColors: Record<string, string> = {
  CREATION: colorPalette.colorAmethyst,
  BACKFILL: colorPalette.colorAtlantic,
  UPDATE: colorPalette.colorCerulean,
  SCORING: colorPalette.colorCreamsicle,
  DECISION: colorPalette.colorCarmine,
  GUARANTEE: colorPalette.colorEmerald,
  NORMALIZATION: colorPalette.colorEmerald,
  FILE_UPLOAD: colorPalette.colorGlitter,
  CLAIM: colorPalette.colorAtlantic,
  UNCLAIMED_CHARGEBACK: colorPalette.colorHaze,
  REVENUE: colorPalette.colorIris,
  REPROCESS: colorPalette.colorLapis,
  RETURN: colorPalette.colorLilac,
  DEMO: colorPalette.colorTurquoise,
  EARLY_ALERT: colorPalette.colorSlate,
  API_CALLS: colorPalette.colorGold,
  V3: colorPalette.colorYale,
}
