import { CHECKPOINT_ACTION } from '@signifyd/http'

const getReturnLocales = (): Record<CHECKPOINT_ACTION, string> => {
  return {
    ACCEPT: 'Return set to Accept due to policy',
    HOLD: 'Return set to Hold due to policy',
    REJECT: 'Return set to Reject due to policy',
    CREDIT: 'Return set to Credit due to policy',
    CHALLENGE: 'Return set to Challenge due to policy',
  }
}

export default {
  tooltip: {
    returnSetTo: getReturnLocales(),
  },
  noMatch: 'No match',
}
