import {
  getInvestigationAggregates,
  SearchAggregationQuery,
} from '@signifyd/http'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { RangeFilter } from 'store/search'
import { getDefaultDateRange } from 'core/utils/date.utils'
import {
  generateAggregateQuery,
  parseClaimData,
  parseOrderData,
} from '../components/Aggregates/aggregate.utils'

export interface OrderData {
  approvedCount: number
  declinedCount: number
  canceledCount: number
  pendingCount: number
  inReviewCount: number
}

export interface ClaimData {
  approvedCount: number
  declinedCount: number
  needsMoreInfoCount: number
}

export interface AggregateResponse {
  orderData: OrderData
  claimData: ClaimData
}

const useSearchAggregates = (
  dates: RangeFilter<string>,
  teamIds: Array<number>
): UseQueryResult<AggregateResponse> => {
  const defaultDateRange = getDefaultDateRange()

  const min = dates?.min ?? defaultDateRange.min
  const max = dates?.max ?? defaultDateRange.max

  const dataKey = `${min ?? '_'}-${max ?? '_'}_${teamIds}`

  return useQuery<AggregateResponse>(
    ['getAggregateSearch', dataKey],
    async () => {
      const aggregateQuery: SearchAggregationQuery = generateAggregateQuery(
        {
          min,
          max,
        },
        teamIds
      )

      const { data } = await getInvestigationAggregates(aggregateQuery)

      const orderData = parseOrderData(data)
      const claimData = parseClaimData(data)

      return {
        orderData,
        claimData,
      }
    },
    {
      enabled: !!min && !!max,
    }
  )
}

export default useSearchAggregates
