import SigTag from '@signifyd/components/src/components/SigTag'
import { colorSlate, colorHaze } from '@signifyd/colors'
import Icon from '@ant-design/icons'
import { Text } from '@signifyd/components/src/components/Typography'
import { i18nInstance } from '@signifyd/components'
import { FC } from 'react'

interface Props {
  icon: FC
}

const NoMatchTag: FC<Props> = ({ icon }) => {
  return (
    <SigTag data-test-id="noMatchTag" color={colorHaze} type="primary">
      <Icon component={icon} style={{ color: colorSlate }} />
      <Text size="sm" style={{ color: colorSlate }}>
        {i18nInstance.t('returnTag.noMatch')}
      </Text>
    </SigTag>
  )
}

export default NoMatchTag
