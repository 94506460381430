import { FC, RefObject } from 'react'
import { Form } from '@ant-design/compatible'
import { Select } from 'antd'
import { useTranslation } from 'react-i18next'
import {
  CHECKOUT_CHECKPOINT_ACTION,
  CHECKOUT_CHECKPOINT_ACTION_VALUES,
  SEARCH_FIELD,
} from '@signifyd/http'
import { FilterState } from 'store/search'

interface Props {
  value: Array<CHECKOUT_CHECKPOINT_ACTION>
  updateFilterFn: (update: Partial<FilterState>) => void
  scrollRef?: RefObject<HTMLDivElement>
}

const RecommendationStatusSelectFilter: FC<Props> = ({
  value,
  updateFilterFn,
  scrollRef,
}) => {
  const { t } = useTranslation()

  const handleChange = (value: Array<CHECKOUT_CHECKPOINT_ACTION>): void => {
    updateFilterFn({
      [SEARCH_FIELD.recommendedAction]: value,
    })
  }

  return (
    <Form.Item
      label={t('filters.recommendedAction.label')}
      data-test-id="recommendationStatusFilter"
    >
      <Select
        mode="multiple"
        allowClear
        placeholder={t('filters.placeholder.selectOneOrMore')}
        value={value}
        onChange={handleChange}
        data-test-id="filterRecommendationSelect"
        getPopupContainer={(triggerNode: HTMLElement) => {
          return scrollRef?.current ?? (triggerNode.parentNode as HTMLElement)
        }}
        options={CHECKOUT_CHECKPOINT_ACTION_VALUES.map((option) => ({
          key: option,
          label: t(`filters.recommendedAction.${option}`),
          value: option,
          'data-analytics-id': `filter-recommendation-selected-${option}`,
          'data-test-id': `filterRecommendationSelectOption-${option}`,
        }))}
      />
    </Form.Item>
  )
}

export default RecommendationStatusSelectFilter
