import { FC } from 'react'
import { Card, Skeleton } from 'antd'
import ReactJson from 'react-json-view'
import { Text, Space } from '@signifyd/components'
import { InvestigationContentData } from '../SelectedEvent/useGetInvestigationAtEvent'
import styles from './DataDisplay.less'

interface Props {
  title: string
  subTitle?: string
  data:
    | InvestigationContentData['eventContent']
    | InvestigationContentData['investigation']
  isLoading: boolean
}

const DataDisplay: FC<Props> = ({ title, data, subTitle, isLoading }) => {
  if (!data) {
    return null
  }

  return (
    <Card
      title={
        <>
          <Text size="lg" weight="semibold" block>
            {title}
          </Text>
          {subTitle ? <Text size="sm">{subTitle}</Text> : <Space size="md" />}
        </>
      }
      size="small"
      className={styles.card}
    >
      {isLoading ? (
        <Skeleton paragraph={{ rows: 20 }} />
      ) : (
        <div className={styles.container}>
          <ReactJson src={data} displayDataTypes={false} />
        </div>
      )}
    </Card>
  )
}

export default DataDisplay
