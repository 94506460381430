import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from 'antd'
import { useGetAVSOptions, useGetCVVOptions } from 'core/hooks/useMisc'
import { DateField, SelectField, TextField } from '../Fields'
import FormCard from './FormCard'
import { ReactHookFormTypes } from './Card.types'

const currencies = [
  'USD',
  'AUD',
  'BRL',
  'GBP',
  'CAD',
  'CHF',
  'CZK',
  'DKK',
  'EUR',
  'HKD',
  'HUF',
  'INR',
  'ILS',
  'JPY',
  'MXN',
  'NOK',
  'NZD',
  'PLN',
  'SGD',
  'SEK',
  'ZAR',
]

export const OrderCard: FC<ReactHookFormTypes> = ({ control, errors }) => {
  const { t } = useTranslation()

  const { data: avsOptions, isLoading: avsOptionsLoading } = useGetAVSOptions()
  const { data: cvvOptions, isLoading: cvvOptionsLoading } = useGetCVVOptions()

  return (
    <FormCard title={t('pages.newCase.cards.order')}>
      <Row>
        <SelectField
          fieldName="purchase.avsResponseCode"
          options={avsOptions?.map(({ code, shortDescription }) => ({
            key: code,
            label: `${code} (${shortDescription})`,
            value: code,
          }))}
          span={8}
          required
          loading={avsOptionsLoading}
          control={control}
          errors={errors}
        />

        <SelectField
          fieldName="purchase.cvvResponseCode"
          options={cvvOptions?.map(({ code, shortDescription }) => ({
            key: code,
            label: `${code} (${shortDescription})`,
            value: code,
          }))}
          span={8}
          loading={cvvOptionsLoading}
          control={control}
          errors={errors}
          allowClear
        />

        <TextField
          fieldName="card.bin"
          span={8}
          control={control}
          errors={errors}
        />
      </Row>

      <Row>
        <SelectField
          fieldName="purchase.currency"
          options={currencies.map((currency: string) => ({
            key: currency,
            label: currency,
            value: currency,
          }))}
          span={8}
          required
          control={control}
          errors={errors}
        />

        <TextField
          fieldName="purchase.totalPrice"
          span={8}
          required
          control={control}
          errors={errors}
        />

        <TextField
          fieldName="purchase.shippingPrice"
          span={8}
          control={control}
          errors={errors}
        />
      </Row>

      <Row>
        <TextField
          fieldName="purchase.orderId"
          span={16}
          required
          control={control}
          errors={errors}
        />

        <DateField
          fieldName="purchase.createdAt"
          span={8}
          required
          control={control}
          errors={errors}
          allowClear={false}
        />
      </Row>
    </FormCard>
  )
}

export default OrderCard
