import { i18nInstance } from '@signifyd/components'
import { DeepLinkFactory, MENU_ICON } from '../DeepLinkDropdown.types'
import { googleSearchLink, piplSearchLink } from '../DeepLinkConstants'

const getDeepLinksPhone: DeepLinkFactory = ({ seller, field }) => {
  const value = seller[field]

  return [
    {
      icon: MENU_ICON.GoogleSearchIcon,
      text: i18nInstance.t(
        'pages.caseReview.sellerDetails.deepLinks.phoneNumberSearch'
      ),
      url: googleSearchLink + value,
    },
    {
      icon: MENU_ICON.WhitepagesIcon,
      text: i18nInstance.t(
        'pages.caseReview.sellerDetails.deepLinks.phoneNumberSearch'
      ),
      url: `http://www.whitepages.com/phone/${value}`,
    },
    {
      icon: MENU_ICON.EkataIcon,
      text: i18nInstance.t(
        'pages.caseReview.sellerDetails.deepLinks.phoneNumberSearch'
      ),
      url: `https://app.ekata.com/phones?number=${value}`,
    },
    {
      icon: MENU_ICON.PiplIcon,
      text: i18nInstance.t(
        'pages.caseReview.sellerDetails.deepLinks.phoneNumberSearch'
      ),
      url: piplSearchLink + value,
    },
  ]
}

export default getDeepLinksPhone
