import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Col } from 'antd'
import { ShowMoreContainer } from 'pages/CaseReviewPage/components/ShowMoreContainer/ShowMoreContainer'
import { RuntimeSeller } from './SellerDetails.types'
import SellerInformation from './SellerInformation'
import SellerRecord from './SellerRecord'

interface Props {
  sellers: Array<RuntimeSeller>
  currency: string
}

export const SellerDetails: FC<Props> = ({ sellers, currency }) => {
  const { t } = useTranslation()

  if (!sellers.length) {
    return null
  }

  return (
    <ShowMoreContainer
      data={sellers}
      cardTitle={t('pages.caseReview.sellerDetails.sellerDetails')}
      idPrefix="sellerDetails"
      defaultToShow={5}
      renderItem={(seller) => (
        <React.Fragment key={seller.runtimeId}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <SellerInformation seller={seller} />
          </Col>

          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <SellerRecord seller={seller} currency={currency} />
          </Col>
        </React.Fragment>
      )}
    />
  )
}

export default SellerDetails
