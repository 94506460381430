import { computed } from 'easy-peasy'
import { ArkOwlObject } from '@signifyd/http'
import {
  ArkOwlStateObject,
  CaseArkOwlDataModel,
} from './types/case.arkOwl.types'
import {
  getArkOwlOldestBreach,
  getArkOwlEmailHost,
} from './utils/case.arkOwl.utils'

function buildArkOwlData(
  arkOwlData?: ArkOwlObject | null
): ArkOwlStateObject | undefined {
  if (!arkOwlData) {
    return undefined
  }

  return {
    socialAccounts: arkOwlData.socialMedia,
    oldestBreach: getArkOwlOldestBreach(arkOwlData.dataBreaches),
    emailHost: getArkOwlEmailHost(arkOwlData.emailHost),
    exists: arkOwlData.exists,
  }
}

const caseArkOwlDataModel: CaseArkOwlDataModel = {
  arkOwl: {
    // Computed State
    confirmationEmail: computed(
      [(_state, storeState) => storeState.currentCase.entries],
      (entries) => buildArkOwlData(entries.confirmationEmail?.details?.arkOwl)
    ),

    accountEmail: computed(
      [(_state, storeState) => storeState.currentCase.entries],
      (entries) => buildArkOwlData(entries.accountEmail?.details?.arkOwl)
    ),
  },
}

export default caseArkOwlDataModel
