import { GuaranteeIcon, i18nInstance, Text } from '@signifyd/components'
import { colorEmerald, colorRed, colorSlate } from '@signifyd/colors'
import {
  CLAIM_DISPOSITION,
  CLAIM_STATUS,
  INV_GUARANTEE_DISPOSITION,
  SEARCH_FIELD,
} from '@signifyd/http'
import { NavigateFunction } from 'react-router'
import { ThunkCreator } from 'easy-peasy'
import { FilterState } from 'store/search'
import { AggregateResponse } from '../../queries/useSearchAggregates'
import { SubTotal } from './AggregateContainer'
import styles from './AggregateContainer.less'
import { handleArrayChange } from './aggregate.utils'

const defaultCaseSearch = {
  quickSearchKey: undefined,
  saveAs: '',
  savedFilterKey: undefined,
}

export const getOrderSubTotals = (
  {
    orderData: {
      approvedCount,
      declinedCount,
      canceledCount,
      pendingCount,
      inReviewCount,
    },
  }: AggregateResponse,
  filters: FilterState,
  updateFilters: (filters: FilterState) => void,
  searchCases: ThunkCreator<
    {
      quickSearchKey?: string | undefined
      saveAs?: string | undefined
      savedFilterKey?: string | undefined
      navigate: NavigateFunction
    },
    string
  >,
  navigate: NavigateFunction
): Array<SubTotal> => {
  const applyFilterUpdates = (field: INV_GUARANTEE_DISPOSITION): void => {
    const guarantees = {
      [SEARCH_FIELD.guaranteeDisposition]: handleArrayChange(
        field,
        filters.guaranteeDisposition
      ),
    }

    updateFilters({
      ...filters,
      ...guarantees,
    })
    searchCases({ ...defaultCaseSearch, navigate })
  }

  return [
    {
      title: (
        <Text size="xs" data-test-id="aggregateApprovedTitle">
          {i18nInstance.t('aggregates.guarantee.approved')}
        </Text>
      ),
      icon: <GuaranteeIcon fill={colorEmerald} className={styles.icon} />,
      value: `${approvedCount}`,
      onClick: () => applyFilterUpdates(INV_GUARANTEE_DISPOSITION.APPROVED),
      isSelected: filters.guaranteeDisposition.includes(
        INV_GUARANTEE_DISPOSITION.APPROVED
      ),
      id: 'aggregateGuaranteeApproved',
    },
    {
      title: (
        <Text size="xs" data-test-id="aggregateDeclinedTitle">
          {i18nInstance.t('aggregates.guarantee.declined')}
        </Text>
      ),
      icon: <GuaranteeIcon fill={colorRed} className={styles.icon} />,
      value: `${declinedCount}`,
      onClick: () => applyFilterUpdates(INV_GUARANTEE_DISPOSITION.DECLINED),
      isSelected: filters.guaranteeDisposition.includes(
        INV_GUARANTEE_DISPOSITION.DECLINED
      ),
      id: 'aggregateGuaranteeDeclined',
    },
    {
      title: (
        <Text size="xs" data-test-id="aggregateCanceledTitle">
          {i18nInstance.t('aggregates.guarantee.canceled')}
        </Text>
      ),
      icon: <GuaranteeIcon fill={colorRed} className={styles.icon} />,
      value: `${canceledCount}`,
      onClick: () => applyFilterUpdates(INV_GUARANTEE_DISPOSITION.CANCELED),
      isSelected: filters.guaranteeDisposition.includes(
        INV_GUARANTEE_DISPOSITION.CANCELED
      ),
      id: 'aggregateGuaranteeCanceled',
    },
    {
      title: (
        <Text size="xs" data-test-id="aggregatePendingTitle">
          {i18nInstance.t('aggregates.guarantee.pending')}
        </Text>
      ),
      icon: <GuaranteeIcon fill={colorSlate} className={styles.icon} />,
      value: `${pendingCount}`,
      onClick: () => applyFilterUpdates(INV_GUARANTEE_DISPOSITION.PENDING),
      isSelected: filters.guaranteeDisposition.includes(
        INV_GUARANTEE_DISPOSITION.PENDING
      ),
      id: 'aggregateGuaranteePending',
    },
    {
      title: (
        <Text size="xs" data-test-id="aggregateInReviewTitle">
          {i18nInstance.t('aggregates.guarantee.inReview')}
        </Text>
      ),
      icon: <GuaranteeIcon fill={colorSlate} className={styles.icon} />,
      value: `${inReviewCount}`,
      onClick: () => applyFilterUpdates(INV_GUARANTEE_DISPOSITION.IN_REVIEW),
      isSelected: filters.guaranteeDisposition.includes(
        INV_GUARANTEE_DISPOSITION.IN_REVIEW
      ),
      id: 'aggregateGuaranteeInReview',
    },
  ]
}

export const getClaimsSubTotals = (
  {
    claimData: { approvedCount, declinedCount, needsMoreInfoCount },
  }: AggregateResponse,
  filters: FilterState,
  updateFilters: (filters: FilterState) => void,
  searchCases: ThunkCreator<
    {
      quickSearchKey?: string | undefined
      saveAs?: string | undefined
      savedFilterKey?: string | undefined
      navigate: NavigateFunction
    },
    string
  >,
  navigate: NavigateFunction
): Array<SubTotal> => {
  const applyFilterUpdates = (claims: Partial<FilterState>): void => {
    updateFilters({
      ...filters,
      ...claims,
    })
    searchCases({ ...defaultCaseSearch, navigate })
  }

  return [
    {
      title: (
        <Text data-test-id="aggregateClaimApprovedTitle">
          {i18nInstance.t('aggregates.claim.approved')}
        </Text>
      ),
      value: approvedCount,
      onClick: () => {
        const claims = {
          [SEARCH_FIELD.claimDisposition]: handleArrayChange(
            CLAIM_DISPOSITION.APPROVED,
            filters.claimDisposition
          ),
        }

        applyFilterUpdates(claims)
      },
      isSelected: filters.claimDisposition.includes(CLAIM_DISPOSITION.APPROVED),
      id: 'aggregateClaimApproved',
    },
    {
      title: (
        <Text data-test-id="aggregateClaimDeclinedTitle">
          {i18nInstance.t('aggregates.claim.declined')}
        </Text>
      ),
      value: declinedCount,
      onClick: () => {
        const claims = {
          [SEARCH_FIELD.claimDisposition]: handleArrayChange(
            CLAIM_DISPOSITION.DECLINED,
            filters.claimDisposition
          ),
        }

        applyFilterUpdates(claims)
      },
      isSelected: filters.claimDisposition.includes(CLAIM_DISPOSITION.DECLINED),
      id: 'aggregateClaimDeclined',
    },
    {
      title: (
        <Text data-test-id="aggregateClaimNeedsMoreInfoTitle">
          {i18nInstance.t('aggregates.claim.needsMoreInfo')}
        </Text>
      ),
      value: needsMoreInfoCount,
      onClick: () => {
        const claims = {
          [SEARCH_FIELD.claimStatus]: handleArrayChange(
            CLAIM_STATUS.NEED_MORE_INFO,
            filters.claimStatus
          ),
        }

        applyFilterUpdates(claims)
      },
      isSelected: filters.claimStatus.includes(CLAIM_STATUS.NEED_MORE_INFO),
      id: 'aggregateClaimNeedsMoreInfo',
    },
  ]
}
