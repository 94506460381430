import { FC, MouseEvent, useCallback, useEffect, useState } from 'react'
import { Flex } from 'antd'
import { ExternalLink, Text } from '@signifyd/components'
import { AppstoreAddOutlined, ExportOutlined } from '@ant-design/icons'
import { InvSummaryViewInfo } from '@signifyd/http'
import { useTranslation } from 'react-i18next'
import styles from './ContextMenuPopover.less'
import { getInvestigationUrl, useIsShiftKeyPressed } from '../utils'

export interface ContextMenuPopoverProps {
  content: InvSummaryViewInfo | null
  visible: boolean
  x: number
  y: number
}

const ContextMenuPopover: FC<ContextMenuPopoverProps> = ({
  content,
  visible,
  x,
  y,
}) => {
  const { t } = useTranslation()
  const { isShiftPressed } = useIsShiftKeyPressed()

  if (!visible || !content) {
    return null
  }

  const url = getInvestigationUrl(content.investigationId)

  return (
    <Flex
      data-test-id="contextMenuPopover"
      vertical
      gap={8}
      className={styles.popover}
      style={{ left: `${x}px`, top: `${y}px` }}
    >
      <Text>
        {t('pages.results.table.contextMenu.investigation', {
          investigationId: content.investigationId,
        })}
      </Text>

      <ExternalLink url={url} className={styles.popoverOption} target="_blank">
        <Flex gap={8} align="center">
          {isShiftPressed ? (
            <>
              <AppstoreAddOutlined />
              {t('pages.results.table.contextMenu.openNewWindow')}
            </>
          ) : (
            <>
              <ExportOutlined />
              {t('pages.results.table.contextMenu.openNewTab')}
            </>
          )}
        </Flex>
      </ExternalLink>
    </Flex>
  )
}

const contextMenuInitialValue: ContextMenuPopoverProps = {
  visible: false,
  x: 0,
  y: 0,
  content: null,
}

interface UseContextMenu {
  contextMenuState: ContextMenuPopoverProps
  handleContextMenu: (
    rowValues: InvSummaryViewInfo
  ) => (e: MouseEvent<HTMLTableRowElement>) => void
}

export const useContextMenu = (): UseContextMenu => {
  const [contextMenuState, setContextMenuState] =
    useState<ContextMenuPopoverProps>(contextMenuInitialValue)

  const closeContextMenu = useCallback(() => {
    setContextMenuState(contextMenuInitialValue)
  }, [])

  const handleContextMenu =
    (rowValues: InvSummaryViewInfo) => (e: MouseEvent<HTMLTableRowElement>) => {
      e.preventDefault()

      setContextMenuState({
        visible: true,
        x: e.clientX,
        y: e.clientY - 50,
        content: rowValues,
      })
    }

  useEffect(() => {
    const handleEvent = (): void => closeContextMenu()

    const handleKeyDown = (event: KeyboardEvent): void => {
      if (event.key === 'Escape') {
        closeContextMenu()
      }
    }

    if (contextMenuState.visible) {
      window.addEventListener('click', handleEvent)
      window.addEventListener('scroll', handleEvent, true)
      window.addEventListener('resize', handleEvent)
      window.addEventListener('keydown', handleKeyDown)
    }

    return () => {
      window.removeEventListener('click', handleEvent)
      window.removeEventListener('scroll', handleEvent, true)
      window.removeEventListener('resize', handleEvent)
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [contextMenuState.visible, closeContextMenu])

  return {
    contextMenuState,
    handleContextMenu,
  }
}

export default ContextMenuPopover
