import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Descriptions } from 'antd'

import { Membership, PREDICTION_LIST_TYPE } from '@signifyd/http'
import SeederLabel from 'pages/CaseReviewPage/components/SeederLabel'
import CopyWrapper from 'core/components/CopyWrapper'
import { ShowMoreContainer } from 'pages/CaseReviewPage/components/ShowMoreContainer/ShowMoreContainer'
import OrderAttributeLists from 'core/components/OrderAttributeLists/OrderAttributeLists'

interface Props {
  memberships: Array<Membership>
}

export const MembershipDetails: FC<Props> = ({ memberships }) => {
  const { t } = useTranslation()

  return (
    <ShowMoreContainer
      cardTitle={t('pages.caseReview.membershipDetails.title')}
      data={memberships}
      idPrefix="membershipDetails"
      renderItem={({
        phoneNumber,
        membershipId,
        emailAddress,
        membershipName,
      }) => (
        <Col xs={{ span: 24 }} lg={{ span: 12 }} key={membershipName}>
          <Descriptions
            title={t(
              'pages.caseReview.membershipDetails.membershipInformation'
            )}
            colon={false}
            size="small"
          />
          {membershipId && (
            <>
              <Descriptions colon={false} size="small">
                <Descriptions.Item
                  label={
                    <SeederLabel
                      label={t(
                        'pages.caseReview.membershipDetails.membershipId'
                      )}
                    />
                  }
                >
                  <CopyWrapper text={membershipId}>{membershipId}</CopyWrapper>
                </Descriptions.Item>
              </Descriptions>
              <OrderAttributeLists
                type={PREDICTION_LIST_TYPE.MEMBERSHIP_ID}
                value={membershipId}
                analyticsId="membership-ID"
              />
            </>
          )}
          {phoneNumber && (
            <Descriptions colon={false} size="small">
              <Descriptions.Item
                label={
                  <SeederLabel
                    label={t('pages.caseReview.membershipDetails.phoneNumber')}
                  />
                }
              >
                <CopyWrapper text={phoneNumber}>{phoneNumber}</CopyWrapper>
              </Descriptions.Item>
            </Descriptions>
          )}
          {emailAddress && (
            <Descriptions colon={false} size="small">
              <Descriptions.Item
                label={
                  <SeederLabel
                    label={t('pages.caseReview.membershipDetails.email')}
                  />
                }
              >
                <CopyWrapper text={emailAddress}>{emailAddress}</CopyWrapper>
              </Descriptions.Item>
            </Descriptions>
          )}
          <Descriptions colon={false} size="small">
            <Descriptions.Item
              label={
                <SeederLabel
                  label={t('pages.caseReview.membershipDetails.name')}
                />
              }
            >
              <CopyWrapper text={membershipName}>{membershipName}</CopyWrapper>
            </Descriptions.Item>
          </Descriptions>
        </Col>
      )}
    />
  )
}

export default MembershipDetails
