import payment from './payment'
import shipping from './shipping'
import account from './account'
import order from './order'

export default {
  title: 'Order summary',
  noInfo: 'No information available.',
  payment,
  shipping,
  account,
  order,
}
