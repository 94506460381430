import { FC } from 'react'
import { Descriptions, Tooltip } from 'antd'
import { formatCurrencyNumber } from '@signifyd/utils'
import { useTranslation } from 'react-i18next'
import {
  CaseOrder,
  InvestigationInfo,
  PREDICTION_LIST_TYPE,
} from '@signifyd/http'
import FormattedDate from 'core/components/FormattedDate'
import NoInformation from 'core/components/NoInformation'
import OrderAttributeLists from 'core/components/OrderAttributeLists'

const { Item: DescriptionItem } = Descriptions

export interface AccountSummaryProps {
  orderDetails: CaseOrder
  caseDetails: InvestigationInfo
}

export const AccountSummary: FC<AccountSummaryProps> = ({
  orderDetails,
  caseDetails,
}) => {
  const { t } = useTranslation()

  const noInfo =
    !orderDetails?.buyerAccount?.membershipNumbers?.length &&
    !caseDetails?.userAccountNumber &&
    !caseDetails?.userAccountAggregateOrderCount &&
    !caseDetails?.userAccountAggregateOrderDollars &&
    !caseDetails?.userAccountCreatedDate &&
    !caseDetails?.userAccountLastUpdateDate &&
    !caseDetails?.userAccountLastOrderExternalId
  const sectionName = t('caseSummary.account.title')

  if (noInfo) {
    return (
      <>
        <Descriptions title={sectionName} colon={false} size="small" />
        <NoInformation />
      </>
    )
  }

  const {
    userAccountNumber,
    currency,
    userAccountLastUpdateDate,
    userAccountAggregateOrderCount,
    userAccountAggregateOrderDollars,
    userAccountCreatedDate,
    userAccountLastOrderExternalId,
  } = caseDetails
  const {
    buyerAccount: { membershipNumbers },
  } = orderDetails

  return (
    <>
      <Descriptions title={sectionName} colon={false} size="small" />
      {/*  Number */}
      {userAccountNumber && (
        <>
          <Descriptions colon={false} size="small">
            <DescriptionItem label={t('caseSummary.account.number')}>
              {userAccountNumber}
            </DescriptionItem>
          </Descriptions>
          <OrderAttributeLists
            type={PREDICTION_LIST_TYPE.ACCOUNT_NUMBER}
            value={userAccountNumber}
            analyticsId="account-number"
          />
        </>
      )}

      <Descriptions colon={false} size="small">
        {/* Order Count */}
        {userAccountAggregateOrderCount && (
          <DescriptionItem label={t('caseSummary.account.orderCount')}>
            {userAccountAggregateOrderCount}
          </DescriptionItem>
        )}

        {/* Order Amount */}
        {userAccountAggregateOrderDollars && (
          <DescriptionItem label={t('caseSummary.account.orderAmount')}>
            {`${currency || 'USD'} ${formatCurrencyNumber(
              userAccountAggregateOrderDollars
            )}`}
          </DescriptionItem>
        )}

        {/* Creation Date */}
        {userAccountCreatedDate && (
          <DescriptionItem label={t('caseSummary.account.createdDate')}>
            <FormattedDate date={userAccountCreatedDate} />
          </DescriptionItem>
        )}

        {/* Last Update */}
        {userAccountLastUpdateDate && (
          <DescriptionItem label={t('caseSummary.account.lastUpdated')}>
            <FormattedDate date={userAccountLastUpdateDate} />
          </DescriptionItem>
        )}

        {/* Last Order ID */}
        {userAccountLastOrderExternalId && (
          <DescriptionItem label={t('caseSummary.account.lastOrder')}>
            {userAccountLastOrderExternalId}
          </DescriptionItem>
        )}
      </Descriptions>

      {/* Membership ID */}
      {!!membershipNumbers?.length && (
        <Descriptions colon={false} size="small">
          <DescriptionItem label={t('caseSummary.account.membership')}>
            <Tooltip title={membershipNumbers.join(', ')}>
              <div>{membershipNumbers.join(', ')}</div>
            </Tooltip>
          </DescriptionItem>
          <OrderAttributeLists
            type={PREDICTION_LIST_TYPE.MEMBERSHIP_ID}
            value={membershipNumbers.join(', ')}
            analyticsId="membership-number"
          />
        </Descriptions>
      )}
    </>
  )
}
export default AccountSummary
