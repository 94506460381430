import { FC } from 'react'
import { Descriptions } from 'antd'
import { toTitleCase } from '@signifyd/utils'
import { CaseOrder, InvestigationInfo } from '@signifyd/http'
import { useTranslation } from 'react-i18next'
import { compact } from 'lodash'
import NoInformation from 'core/components/NoInformation'
import DiscountCodes from 'pages/CaseReviewPage/components/DiscountCodes'
import SeederLabel from 'pages/CaseReviewPage/components/SeederLabel'
import { useCaseEntries } from 'core/hooks/useCaseEntries'
import styles from './PaymentSummary.less'
import PaymentSummaryIcons from './PaymentSummaryIcons'

const { Item: DescriptionItem } = Descriptions

interface Props {
  orderDetails: CaseOrder
  caseDetails: InvestigationInfo
}

export const PaymentSummary: FC<Props> = ({ orderDetails, caseDetails }) => {
  const { t } = useTranslation()
  const { data: caseEntries } = useCaseEntries(caseDetails.investigationId)

  const {
    authorizationTransactionId,
    avsResponseCode,
    avsResponseDescription,
    authorizationGatewayStatus,
    authorizationFailureReason,
    cardBin,
    cardLastFourDigits,
    currency,
    cvvResponseCode,
    cvvResponseDescription,
    discountCodes,
    scaEvaluation,
    normalizedCardNumber,
  } = caseDetails

  const {
    paymentMethod: {
      bankAuthNumber,
      cardExpiryYear,
      cardExpiryMonth,
      paymentTerms,
      type,
    },
  } = orderDetails

  const noInfo =
    !authorizationGatewayStatus &&
    !avsResponseCode &&
    !cardBin &&
    !(cardExpiryMonth && cardExpiryYear) &&
    !cardLastFourDigits &&
    !caseEntries?.creditCardIssuer?.details?.bankName &&
    !cvvResponseCode &&
    !discountCodes?.length &&
    !paymentTerms?.length &&
    !scaEvaluation &&
    !normalizedCardNumber

  const sectionName = t('caseSummary.payment.title')

  if (noInfo) {
    return (
      <div data-test-id="paymentSummary.noInfo">
        <Descriptions
          title={
            <>
              {sectionName}
              <PaymentSummaryIcons orderDetails={orderDetails} />
            </>
          }
          size="small"
        />
        <NoInformation />
      </div>
    )
  }

  const scaEvalOutcome = scaEvaluation?.outcome
  const scaEvalDetail =
    scaEvaluation?.exclusionDetails?.exclusion ||
    scaEvaluation?.exemptionDetails?.exemption

  const scaKey = compact([scaEvalOutcome, scaEvalDetail]).join('_')

  return (
    <>
      <Descriptions
        title={
          <>
            {sectionName}
            <PaymentSummaryIcons orderDetails={orderDetails} />
          </>
        }
        colon={false}
        size="small"
      >
        {/* CVV */}
        {cvvResponseCode && (
          <DescriptionItem label={t('caseSummary.payment.cvvResponse')}>
            <span data-test-id="paymentSummary.cvvResponse">
              {cvvResponseDescription} ({cvvResponseCode})
            </span>
          </DescriptionItem>
        )}

        {/* AVS */}
        {avsResponseCode && (
          <DescriptionItem label={t('caseSummary.payment.avsResponse')}>
            <span data-test-id="paymentSummary.avsResponse">
              {avsResponseDescription} ({avsResponseCode})
            </span>
          </DescriptionItem>
        )}

        {/* Card Number */}
        {normalizedCardNumber && (
          <DescriptionItem
            label={
              <SeederLabel
                dataTestId="seeder-credit-number-label"
                label={t('caseSummary.payment.cardNumber')}
              />
            }
          >
            <span data-test-id="paymentSummary.cardNumber">
              {normalizedCardNumber}
            </span>
          </DescriptionItem>
        )}

        {/* Expiry */}
        {cardExpiryMonth && cardExpiryYear && (
          <DescriptionItem label={t('caseSummary.payment.expiry')}>
            <span data-test-id="paymentSummary.cardExpiry">
              {cardExpiryMonth} / {cardExpiryYear}
            </span>
          </DescriptionItem>
        )}

        {/* Bank */}
        {caseEntries?.creditCardIssuer?.details?.bankName && (
          <DescriptionItem label={t('caseSummary.payment.bank')}>
            <span data-test-id="paymentSummary.bankName">
              {caseEntries.creditCardIssuer.details.bankName}
            </span>
          </DescriptionItem>
        )}

        {/* Auth Status */}
        {authorizationGatewayStatus && (
          <DescriptionItem label={t('caseSummary.payment.authStatus')}>
            <span data-test-id="paymentSummary.authStatus">
              {toTitleCase(authorizationGatewayStatus)}
            </span>
          </DescriptionItem>
        )}

        {/* SCA Evaluation */}
        {scaEvaluation && (
          <DescriptionItem label={t('caseSummary.payment.scaEvaluation.label')}>
            <span
              className={styles.scaEvaluation}
              data-test-id="paymentSummary.scaEvaluation"
              data-analytics-id={`sca-evaluation-${scaKey}`}
            >
              {t('caseSummary.payment.scaEvaluation.detail', {
                context: scaKey,
              })}
            </span>
          </DescriptionItem>
        )}

        {/* Auth Number */}
        {bankAuthNumber && (
          <DescriptionItem label={t('caseSummary.payment.authNumber')}>
            <span data-test-id="paymentSummary.authNumber">
              {bankAuthNumber}
            </span>
          </DescriptionItem>
        )}

        {/* Failure Reason */}
        {authorizationFailureReason && (
          <DescriptionItem label={t('caseSummary.payment.failureReason')}>
            <span data-test-id="paymentSummary.failureReason">
              {toTitleCase(authorizationFailureReason)}
            </span>
          </DescriptionItem>
        )}

        {/* Payment Terms */}
        {paymentTerms?.length && (
          <DescriptionItem label={t('caseSummary.payment.terms')}>
            <span data-test-id="paymentSummary.paymentTerms">
              {paymentTerms.join(', ')}
            </span>
          </DescriptionItem>
        )}
        {/* Payment Type */}
        {type && (
          <DescriptionItem label={t('caseSummary.payment.paymentType')}>
            <span data-test-id="paymentSummary.paymentType">
              {toTitleCase(type)}
            </span>
          </DescriptionItem>
        )}
        {/* Transaction ID */}
        {authorizationTransactionId && (
          <DescriptionItem
            label={t('caseSummary.payment.authorizationTransactionId')}
          >
            <span data-test-id="paymentSummary.authorizationTransactionId">
              {authorizationTransactionId}
            </span>
          </DescriptionItem>
        )}
        {/* Discount code */}
        {discountCodes?.length && (
          <DescriptionItem label={t('caseSummary.payment.discountCodes')}>
            <span data-test-id="paymentSummary.discountCodeContent">
              <DiscountCodes
                discountCodes={discountCodes}
                currency={currency}
              />
            </span>
          </DescriptionItem>
        )}
      </Descriptions>
    </>
  )
}
export default PaymentSummary
