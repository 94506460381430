import Collapse, { CollapsePanelProps } from 'antd/lib/collapse'
import { FC } from 'react'

const OrderDetailsCollapsePanel: FC<CollapsePanelProps> = (props) => {
  return (
    <Collapse.Panel
      {...props}
      style={{
        border: 0,
        padding: 0,
      }}
    />
  )
}

export default OrderDetailsCollapsePanel
