import { FC } from 'react'
import { Text } from '@signifyd/components'

interface Props {
  label: string
  value: StrOrNum
}

const SummaryItem: FC<Props> = ({ label, value }) => {
  return (
    <span>
      <Text transform="uppercase" size="xs">
        {label}:{' '}
      </Text>
      <Text size="lg">{value}</Text>
    </span>
  )
}

export default SummaryItem
