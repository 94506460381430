import { FC } from 'react'
import { Descriptions, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { Text } from '@signifyd/components'
import { toTitleCase } from '@signifyd/utils'
import { Address } from 'store/caseActions/types'
import styles from './UpdateAddressModal.less'
import AddressField from '../../components/AddressField'
import { MENU_SECTION_TYPE } from '../OrderDetails/components/CaseDetails/components/DeepLinkDropdown/DeepLinkDropdown.types'

export interface Props {
  addresses: Array<Address>
}

const { Title } = Typography

const UpdateAddressConfirmation: FC<Props> = ({ addresses }) => {
  const { t } = useTranslation()

  const formatAddressTitle = (recipient: Address): string => {
    if (recipient.shipment?.shippingMethod) {
      return t('caseActions.updateAddress.updateTitleWithShipment', {
        fullName: recipient.newAddress!.fullName,
        shipment: toTitleCase(recipient.shipment?.shippingMethod),
      })
    }

    return t('caseActions.updateAddress.updateTitle', {
      fullName: recipient.newAddress!.fullName,
    })
  }

  const addressesToUpdate = addresses.filter(
    (recipient) => recipient.newAddress
  )

  return (
    <div data-test-id="update-address-confirmation">
      <div className={styles.titleBlock}>
        <Title level={3} className={styles.titlePadding}>
          {t('caseActions.updateAddress.confirmationTitle')}
        </Title>
        <Text size="md" weight="semibold">
          {t('caseActions.updateAddress.confirmation', {
            count: addressesToUpdate.length,
          })}
        </Text>
      </div>

      {addressesToUpdate.map((recipient) => (
        <Descriptions
          key={recipient.newAddress!.address.fullAddress}
          title={formatAddressTitle(recipient)}
          colon={false}
          data-test-id={`update-address-confirmation-${
            recipient.newAddress!.address.fullAddress
          }`}
        >
          <Descriptions.Item
            label={
              <Text>{t('caseActions.updateAddress.originalAddress')}</Text>
            }
          >
            <AddressField
              type={MENU_SECTION_TYPE.deliveryAddress}
              address={recipient.oldAddress.address}
            />
          </Descriptions.Item>
          <Descriptions.Item
            label={<Text>{t('caseActions.updateAddress.updatedAddress')}</Text>}
          >
            <AddressField
              type={MENU_SECTION_TYPE.deliveryAddress}
              address={recipient.newAddress!.address}
            />
          </Descriptions.Item>
        </Descriptions>
      ))}
    </div>
  )
}

export default UpdateAddressConfirmation
