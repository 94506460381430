export default {
  title: 'New Case',
  intro: "Enter the information you have and we'll create a case for you.",
  requiredMessage: 'Required fields marked with ',
  createCase: 'Create Case',
  creatingCase: 'Creating Case',
  createSuccess: 'Success! Case created',
  createError: 'There was an error creating this case',
  cards: {
    customer: 'Customer',
    order: 'Order',
    orderHistory: 'Order History',
    billing: 'Billing',
    shipping: 'Shipping',
    shippingCheckbox: 'Same as Billing',
  },
  fields: {
    team: 'Team',
    recipient: {
      confirmationEmail: 'Email',
      fullName: 'Full Name',
      confirmationPhone: 'Billing Phone',
      deliveryAddress: {
        streetAddress: 'Address',
        unit: 'Unit/Suite',
        city: 'City',
        provinceCode: 'State',
        postalCode: 'Zip',
        countryCode: 'Country Code',
      },
    },
    purchase: {
      browserIpAddress: 'Browser IP Address',
      avsResponseCode: 'AVS Code',
      cvvResponseCode: 'CVV Code',
      currency: 'Order Currency',
      totalPrice: 'Total Price',
      shippingPrice: 'Shipping Price',
      orderId: 'Order ID',
      createdAt: 'Order Date',
    },
    userAccount: {
      accountNumber: 'Account Number',
      createdDate: 'Account Created Date',
      aggregateOrderCount: 'Order Count',
      aggregateOrderDollars: 'Orders Total ($)',
      phone: 'Billing Phone',
    },
    card: {
      bin: 'BIN',
      cardHolderName: 'Full Name',
      billingAddress: {
        streetAddress: 'Address',
        unit: 'Unit/Suite',
        city: 'City',
        provinceCode: 'State',
        postalCode: 'Zip',
        countryCode: 'Country Code',
      },
    },
    platformAndClient: {
      signifydClientApp: 'Client App',
      signifydClientAppVersion: 'Client App Version',
    },
  },
  errors: {
    requiredText: 'Please enter {{labelText}}',
    requiredSelect: 'Please select {{labelText}}',
    number: 'Please enter a number',
    decimal: 'Please enter a valid amount',
    ip: 'Please enter a valid IP Address',
  },
}
