export function encodeUrlHash<T>(state: T): string {
  return btoa(encodeURIComponent(JSON.stringify(state)))
}

export function decodeUrlHash<T>(hash: string): T {
  return JSON.parse(decodeURIComponent(atob(hash)))
}

export function cleanURLQueryParams(url: string): string {
  return encodeURI(url).replace(/null|undefined/g, '')
}
