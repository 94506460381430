import { Thunk, Computed } from 'easy-peasy'
import { StoreModel } from 'store'
import { SearchStoreModel } from '.'

export enum QUICKVIEW_KEYS {
  TODAYS_ORDERS = 'todays-orders',
  ALL_ORDERS_LAST_FOURTEEN_DAYS = 'all-orders-last-fourteen-days',
  DECLINED_LAST_7_DAYS = 'declined-last-7-days',
  CLAIMS_NEED_INFO = 'claims-need-more-info',
}

export interface SavedFilter {
  title: string
  searchHash: string
}

export interface SelectedSavedFilterState {
  selectedQuickView?: SavedFilter
  selectedSavedFilter?: SavedFilter
}

export interface SavedFilterModel extends SelectedSavedFilterState {
  quickviews: Computed<
    SearchStoreModel,
    {
      [key in QUICKVIEW_KEYS]: SavedFilter
    },
    StoreModel
  >
  savedFilters: Computed<
    SearchStoreModel,
    {
      [key: string]: SavedFilter
    },
    StoreModel
  >
  getSavedFilterMatch: Computed<
    SearchStoreModel,
    (searchString: any) => SelectedSavedFilterState,
    StoreModel
  >
  createSavedFilter: Thunk<
    SearchStoreModel,
    { name: string; searchHash: string },
    any,
    StoreModel
  >
  deleteSavedFilter: Thunk<SearchStoreModel, string, any, StoreModel>
}
