import { SEARCH_FIELD } from '@signifyd/http'

export const getDefaultColumnConfigs = (
  hasAirlineOrders?: boolean
): Array<ColumnConfig> => [
  {
    key: SEARCH_FIELD.guaranteeDisposition,
    isLocked: true,
    isHidden: false,
  },
  {
    key: SEARCH_FIELD.signifydScore,
    isLocked: true,
    isHidden: false,
  },
  {
    key: SEARCH_FIELD.investigationId,
    isLocked: true,
    isHidden: false,
  },
  {
    key: SEARCH_FIELD.orderId,
    isLocked: true,
    isHidden: false,
  },
  ...(hasAirlineOrders
    ? [
        {
          key: SEARCH_FIELD.recordLocator,
          isLocked: true,
          isHidden: false,
        },
      ]
    : []),
  {
    key: SEARCH_FIELD.recommendedDecisionDisposition,
    isLocked: false,
    isHidden: false,
  },
  {
    key: SEARCH_FIELD.guaranteeRecommendedAction,
    isLocked: false,
    isHidden: false,
  },
  {
    key: SEARCH_FIELD.returnDecisionCheckpointAction,
    isLocked: false,
    isHidden: false,
  },
  ...(!hasAirlineOrders
    ? [
        {
          key: SEARCH_FIELD.recipientFullName,
          isLocked: false,
          isHidden: false,
        },
      ]
    : []),
  {
    key: SEARCH_FIELD.normalizedPurchaseCreatedAt,
    isLocked: false,
    isHidden: false,
  },
  {
    key: SEARCH_FIELD.orderTotalAmount,
    isLocked: false,
    isHidden: false,
  },
  ...(hasAirlineOrders
    ? [
        {
          key: SEARCH_FIELD.leadPassenger,
          isLocked: false,
          isHidden: false,
        },
        {
          key: SEARCH_FIELD.departurePortCode,
          isLocked: false,
          isHidden: false,
        },
        {
          key: SEARCH_FIELD.arrivalPortCode,
          isLocked: false,
          isHidden: false,
        },
      ]
    : [
        {
          key: SEARCH_FIELD.shippingMethod,
          isLocked: false,
          isHidden: false,
        },
      ]),
  {
    key: SEARCH_FIELD.sellerId,
    isLocked: false,
    isHidden: false,
  },
  {
    key: SEARCH_FIELD.sellerParentEntity,
    isLocked: false,
    isHidden: false,
  },
  {
    key: SEARCH_FIELD.sellerName,
    isLocked: false,
    isHidden: false,
  },
  {
    key: SEARCH_FIELD.authorizationGatewayStatus,
    isLocked: false,
    isHidden: false,
  },
  {
    key: SEARCH_FIELD.investigationReviewDisposition,
    isLocked: false,
    isHidden: false,
  },
  {
    key: SEARCH_FIELD.caseStatus,
    isLocked: false,
    isHidden: false,
  },
  {
    key: SEARCH_FIELD.caseType,
    isLocked: false,
    isHidden: false,
  },
  {
    key: SEARCH_FIELD.checkoutToken,
    isLocked: false,
    isHidden: false,
  },
  {
    key: SEARCH_FIELD.isTestInvestigation,
    isLocked: false,
    isHidden: false,
  },
  {
    key: SEARCH_FIELD.claimStatus,
    isLocked: false,
    isHidden: false,
  },
]

export interface ColumnConfig {
  key: SEARCH_FIELD
  isLocked: boolean
  isHidden: boolean
}

export const getDrawerColumns = (
  hasAirlineOrders: boolean,
  columnsToHide: Set<SEARCH_FIELD>
): Array<ColumnConfig> => {
  const columns = getDefaultColumnConfigs(hasAirlineOrders)

  columnsToHide.forEach((columnKey) => {
    const columnMatch = columns.find((column) => column.key === columnKey)
    if (columnMatch && !columnMatch.isLocked) {
      columns[columns.indexOf(columnMatch)].isHidden = true
    }
  })

  return columns
}
