import { FC, useEffect, useState, useMemo, ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { Checkbox, Input, Tooltip } from 'antd'
import { Text } from '@signifyd/components'
import { useStoreState } from 'store'
import styles from './SavedFilterInput.less'

export interface SavedFilterInputProps {
  updateHandler: (arg: string) => void
  setIsInvalidSavedFilter: (invalid: boolean) => void
  isInvalidSavedFilter: boolean
  isDefaultFilters: boolean
}

const SavedFilterInput: FC<SavedFilterInputProps> = ({
  updateHandler,
  setIsInvalidSavedFilter,
  isInvalidSavedFilter,
  isDefaultFilters,
}) => {
  const { t } = useTranslation()

  // Store State
  const savedFilters = useStoreState((state) => state.search.savedFilters)
  const currentSavedFilter = useStoreState(
    (state) => state.search.selectedSavedFilter
  )
  const savedFilterNames = useMemo(() => {
    return new Set(
      Object.values(savedFilters).map(({ title }) => title.toLowerCase())
    )
  }, [savedFilters])

  // Local State
  const [searchName, setSearchName] = useState('')
  const [saveEnabled, setSaveEnabled] = useState(false)
  const searchNameTrimmed = searchName.trim()
  const searchNameExists = savedFilterNames.has(searchNameTrimmed.toLowerCase())
  const invalidCharacters = !/^[a-zA-ZÀ-ÿ0-9,.:;!?¡¿\- ]*$/g.test(searchName)
  const searchNameTooLong = searchNameTrimmed.length > 40

  // Handlers
  useEffect(() => {
    const invalidName =
      !searchNameTrimmed ||
      searchNameExists ||
      searchNameTooLong ||
      invalidCharacters

    if (!saveEnabled) {
      updateHandler('')
      setIsInvalidSavedFilter(false)
    } else if (invalidName) {
      setIsInvalidSavedFilter(true)
    } else {
      updateHandler(searchName)
      setIsInvalidSavedFilter(false)
    }
  }, [
    invalidCharacters,
    saveEnabled,
    searchName,
    searchNameExists,
    searchNameTooLong,
    searchNameTrimmed,
    setIsInvalidSavedFilter,
    updateHandler,
  ])

  useEffect(() => {
    if (isDefaultFilters) {
      setSearchName('')
      setSaveEnabled(false)
    }
  }, [isDefaultFilters])

  const updateSearchName = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>): void => {
    setSearchName(value)
    setSaveEnabled(!!value)
  }

  const formField = (
    <div className={styles.input}>
      <Input
        maxLength={40}
        placeholder={
          currentSavedFilter?.title || t('search.footer.saveFilter.placeholder')
        }
        onChange={updateSearchName}
        value={searchName}
        className={isInvalidSavedFilter ? styles.inputError : undefined}
        disabled={isDefaultFilters}
        data-test-id="savedFilterInputField"
      />
      {searchNameExists && (
        <Text size="xs" type="danger" className={styles.warningText}>
          {t('search.footer.saveFilter.nameExists')}
        </Text>
      )}
      {invalidCharacters && (
        <Text size="xs" type="danger" className={styles.warningText}>
          {t('search.footer.saveFilter.noSpecialChar')}
        </Text>
      )}
      {!searchNameTrimmed && saveEnabled && (
        <Text size="xs" type="danger" className={styles.warningText}>
          {t('search.footer.saveFilter.noName')}
        </Text>
      )}
    </div>
  )

  return (
    <div className={styles.wrapper} data-test-id="savedFilterInput">
      <Checkbox
        data-analytics-id="save-filter-checkbox"
        data-test-id="saveFilterCheckbox"
        defaultChecked={false}
        className={styles.checkbox}
        onChange={(e) => setSaveEnabled(e.target.checked)}
        checked={saveEnabled && !isDefaultFilters}
        disabled={isDefaultFilters}
      />

      {t('search.footer.saveFilter.label')}

      {isDefaultFilters ? (
        <Tooltip
          title={t('search.footer.saveFilter.tooltip')}
          placement="topLeft"
        >
          {formField}
        </Tooltip>
      ) : (
        formField
      )}
    </div>
  )
}

export default SavedFilterInput
