import { throttle } from 'lodash'
import { FC, useState, useEffect } from 'react'
import PageSizeContext, {
  getPageSize,
  initialPageSize,
} from './PageSizeContext'

const PageSizeProvider: FC = ({ children }) => {
  const [pageSize, updatePageSize] = useState(initialPageSize)

  useEffect(() => {
    const throttledUpdate = throttle(() => {
      const size = getPageSize()

      updatePageSize(size)
    }, 250)

    window.addEventListener('resize', throttledUpdate)

    return () => window.removeEventListener('resize', throttledUpdate)
  }, [updatePageSize])

  return (
    <PageSizeContext.Provider value={pageSize}>
      {children}
    </PageSizeContext.Provider>
  )
}

export default PageSizeProvider
