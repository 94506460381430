import { FC } from 'react'
import { MenuProps, Flex } from 'antd'
import Icon from '@ant-design/icons'
import { ExternalLink, IconProps } from '@signifyd/components'
import { cleanURLQueryParams } from 'core/utils/urlEncoder'
import styles from './DeepLinkMenuItems.less'

type MenuItem<T> = {
  icon: T
  url: string
  text: string
}

const MenuItems = <T extends string>(
  items: Array<MenuItem<T>>,
  menuIcons: Map<T, FC<IconProps>>
): MenuProps['items'] => {
  return items.map(({ icon, url, text }) => ({
    key: icon,
    className: styles.dropdownMenu,
    label: (
      <ExternalLink
        data-analytics-id={`deeplink-link-${String(icon)}`}
        data-test-id={`deeplinkLink${String(icon)}`}
        url={cleanURLQueryParams(url)}
        target="_blank"
      >
        <Flex className={styles.externalLinkContent} align="center">
          <Icon className={styles.icon} component={menuIcons.get(icon)} />
          {text}
        </Flex>
      </ExternalLink>
    ),
  }))
}

export default MenuItems
