import { FC } from 'react'
import { MENU_SECTION_TYPE } from 'pages/CaseReviewPage/containers/OrderDetails/components/CaseDetails/components/DeepLinkDropdown/DeepLinkDropdown.types'
import CopyWrapper from 'core/components/CopyWrapper'
import AddressField from 'pages/CaseReviewPage/components/AddressField'
import { RuntimeSeller } from 'pages/CaseReviewPage/containers/OrderDetails/components/SellerDetails/SellerDetails.types'
import DeepLinkDropdown from '../DeepLinkDropdown'

interface Props {
  seller: RuntimeSeller
  field: keyof RuntimeSeller
}

const SellerField: FC<Props> = ({ seller, field }) => {
  const value = seller[field]

  if (field === 'address') {
    return (
      <CopyWrapper text={seller.address.fullAddress}>
        <DeepLinkDropdown seller={seller} field={field}>
          <AddressField
            type={MENU_SECTION_TYPE.seller}
            address={seller.address}
            normalizedAddress={seller.address.fullAddress}
          />
        </DeepLinkDropdown>
      </CopyWrapper>
    )
  }

  return (
    <CopyWrapper text={`${value}`}>
      <DeepLinkDropdown seller={seller} field={field}>
        {value}
      </DeepLinkDropdown>
    </CopyWrapper>
  )
}

export default SellerField
