import { InvestigationInfo } from '@signifyd/http'

export enum CASE_CAPABILITIES {
  REQUIRE_REVIEW_DISPOSITION_FOR_GUARANTEE_SUBMIT = 'REQUIRE_REVIEW_DISPOSITION_FOR_GUARANTEE_SUBMIT',
  DISALLOW_CLAIM_SUBMISSION = 'DISALLOW_CLAIM_SUBMISSION',
  ALLOW_10_ADDRESS_UPDATES = 'ALLOW_10_ADDRESS_UPDATES',
  ALLOW_180_DAYS_FOR_ADDRESS_UPDATES = 'ALLOW_180_DAYS_FOR_ADDRESS_UPDATES',
  REQUIRE_GOOD_FEEDBACK_REASON = 'REQUIRE_GOOD_FEEDBACK_REASON',
}

export type RequiredFields = Pick<InvestigationInfo, 'customerId' | 'teamId'>

export interface ConfigItem {
  customerIds?: {
    [key in BACKEND_ENV_VAL]: Array<number>
  }
  teamIds?: {
    [key in BACKEND_ENV_VAL]: Array<number>
  }
}
