import { FC } from 'react'
import { FileExclamationOutlined } from '@ant-design/icons'
import { Space, Text } from '@signifyd/components'
import styles from './EmptyWarning.less'

interface Props {
  text: string
}

const EmptyWarning: FC<Props> = ({ text }) => {
  return (
    <div className={styles.wrapper} data-test-id="emptyWarning">
      <FileExclamationOutlined className={styles.icon} />
      <Space size={6} />
      <Text type="secondary">{text}</Text>
    </div>
  )
}

export default EmptyWarning
