import { FC } from 'react'
import cx from 'classnames'
import { Text } from '@signifyd/components'
import styles from './CopyWrapper.less'

interface Props {
  text?: string | null
  className?: string
}

const CopyWrapper: FC<Props> = ({ text, children, className }) => (
  <div className={cx([styles.copyWrapper, className])}>
    <div className={styles.content}> {children}</div>
    {text && <Text className={styles.copyButton} copyable={{ text }} />}
  </div>
)

export default CopyWrapper
