export interface ActionState {
  disabled: boolean
  disabledReason?: string
  hidden?: boolean
}

export const getEnableActionState = (): ActionState => ({
  hidden: false,
  disabled: false,
})

export const getHideActionState = (): ActionState => ({
  hidden: true,
  disabled: true,
})

export const getDisableActionState = (disabledReason: string): ActionState => ({
  hidden: false,
  disabled: true,
  disabledReason,
})
