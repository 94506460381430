import { SEARCH_ORDER } from '@signifyd/http'
import { FilterState, SortState } from 'store/search/types'

export const antSort2SigSort = (
  sort: SortState
): { by: keyof FilterState; order: SEARCH_ORDER } => {
  return {
    ...sort,
    order:
      sort.order === 'ascend'
        ? SEARCH_ORDER.ASCENDING
        : SEARCH_ORDER.DESCENDING,
  }
}
