import { FC, RefObject } from 'react'
import { Form } from '@ant-design/compatible'
import { Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { CLAIM_STATUS, CLAIM_DISPOSITION, SEARCH_FIELD } from '@signifyd/http'
import { FilterState } from 'store/search'

interface Props {
  value: Array<CLAIM_DISPOSITION | CLAIM_STATUS>
  updateFilterFn: (update: Partial<FilterState>) => void
  scrollRef?: RefObject<HTMLDivElement>
}

const claimStatusSet = new Set(Object.keys(CLAIM_STATUS))
const claimDispositionSet = new Set(Object.keys(CLAIM_DISPOSITION))

const ClaimStatusSelectFilter: FC<Props> = ({
  value,
  updateFilterFn,
  scrollRef,
}) => {
  const { t } = useTranslation()

  const handleChange = (value: CLAIM_DISPOSITION | CLAIM_STATUS): void => {
    const isDispositionValue = claimDispositionSet.has(value)
    const isStatusValue = claimStatusSet.has(value)

    updateFilterFn({
      [SEARCH_FIELD.claimDisposition]: isDispositionValue
        ? [value as CLAIM_DISPOSITION]
        : [],
      [SEARCH_FIELD.claimStatus]: isStatusValue ? [value as CLAIM_STATUS] : [],
    })
  }

  return (
    <Form.Item label={t('filters.claimStatus.label')}>
      <Select
        // mode="multiple"
        // placeholder={t('filters.placeholder.selectOneOrMore')}
        placeholder={t('filters.placeholder.selectStatus')}
        value={value[0]} // TODO RTS-3088 Temporarily only allow one value to be selected at a time due to a limitation documented
        allowClear
        onChange={handleChange}
        data-analytics-id="filter-claim-status-select"
        data-test-id="filterClaimStatusSelect"
        getPopupContainer={(triggerNode: HTMLElement) => {
          return scrollRef?.current ?? (triggerNode.parentNode as HTMLElement)
        }}
        options={[
          {
            label: t(`filters.claimStatus.${CLAIM_DISPOSITION.APPROVED}`),
            value: CLAIM_DISPOSITION.APPROVED,
            'data-analytics-id': `filter-claim-status-selected-${CLAIM_DISPOSITION.APPROVED}`,
            'data-test-id': `filterClaimStatusSelected-${CLAIM_DISPOSITION.APPROVED}`,
          },
          {
            label: t(`filters.claimStatus.${CLAIM_STATUS.PENDING}`),
            value: CLAIM_STATUS.PENDING,
            'data-analytics-id': `filter-claim-status-selected-${CLAIM_STATUS.PENDING}`,
            'data-test-id': `filterClaimStatusSelected-${CLAIM_STATUS.PENDING}`,
          },
          {
            label: t(`filters.claimStatus.${CLAIM_DISPOSITION.DECLINED}`),
            value: CLAIM_DISPOSITION.DECLINED,
            'data-analytics-id': `filter-claim-status-selected-${CLAIM_DISPOSITION.DECLINED}`,
            'data-test-id': `filterClaimStatusSelected-${CLAIM_DISPOSITION.DECLINED}`,
          },
          {
            label: t(`filters.claimStatus.${CLAIM_STATUS.CANCELLED}`),
            value: CLAIM_STATUS.CANCELLED,
            'data-analytics-id': `filter-claim-status-selected-${CLAIM_STATUS.CANCELLED}`,
            'data-test-id': `filterClaimStatusSelected-${CLAIM_STATUS.CANCELLED}`,
          },
          {
            label: t(`filters.claimStatus.${CLAIM_STATUS.NEED_MORE_INFO}`),
            value: CLAIM_STATUS.NEED_MORE_INFO,
            'data-analytics-id': `filter-claim-status-selected-${CLAIM_STATUS.NEED_MORE_INFO}`,
            'data-test-id': `filterClaimStatusSelected-${CLAIM_STATUS.NEED_MORE_INFO}`,
          },
        ]}
      />
    </Form.Item>
  )
}

export default ClaimStatusSelectFilter
