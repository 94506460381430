import { CaseEntriesItem } from '@signifyd/http'

export const findRoleInSection = <T>(
  section: Array<CaseEntriesItem<T>>,
  roleName: string
): CaseEntriesItem<T> | undefined =>
  section.find(({ role }) => role === roleName)

export const findRolesInSection = <T>(
  section: Array<CaseEntriesItem<T>>,
  roleName: string
): Array<CaseEntriesItem<T>> | undefined =>
  section.filter(({ role }) => role === roleName)

export const findRolesMatchInSection = <T>(
  section: Array<CaseEntriesItem<T>>,
  roleName: string
): Array<CaseEntriesItem<T>> | undefined =>
  section.filter(({ role }) =>
    role.toLowerCase().includes(roleName.toLowerCase())
  )
