import { FC } from 'react'
import {
  CaseEntriesItem,
  CaseEntryLocationDetails,
  PhysicalAddress,
} from '@signifyd/http'
import AddressInsight from './AddressInsight'

interface Props {
  recipientAddress: PhysicalAddress
  deliveryAddresses?: Array<CaseEntriesItem<CaseEntryLocationDetails>>
}

const MatchedAddressInsight: FC<Props> = ({
  recipientAddress,
  deliveryAddresses,
}) => {
  const matchedRecipient = deliveryAddresses?.find((address) => {
    if (recipientAddress.streetAddress) {
      return address.details?.fullAddress?.includes(
        recipientAddress.streetAddress
      )
    }
    if (recipientAddress.postalCode) {
      return address.details?.fullAddress?.includes(recipientAddress.postalCode)
    }

    return false
  })

  return (
    <AddressInsight
      isReceivingMail={matchedRecipient?.details?.isReceivingMail}
      deliveryPoint={matchedRecipient?.details?.deliveryPoint}
    />
  )
}

export default MatchedAddressInsight
