import { useQuery, UseQueryResult } from '@tanstack/react-query'
import {
  getInvestigationAtEvent,
  InvestigationInfo,
  Event,
} from '@signifyd/http'

const useGetInvestigationAtEvent = (
  investigationId: number,
  events: Array<Event>
): UseQueryResult<Array<InvestigationInfo>> => {
  const [investigation1, investigation2] = events

  return useQuery<Array<InvestigationInfo>>(
    [
      'getComparisonEvents',
      `${investigationId}-${investigation1.investigationEventId.uuid}-${investigation2.investigationEventId.uuid}`,
    ],
    async () => {
      const investigationCall1 = await getInvestigationAtEvent({
        investigationId,
        groupId: investigation1.investigationEventId.groupId,
        typeId: investigation1.investigationEventId.typeId,
        uuid: investigation1.investigationEventId.uuid,
      })

      const investigationCall2 = await getInvestigationAtEvent({
        investigationId,
        groupId: investigation2.investigationEventId.groupId,
        typeId: investigation2.investigationEventId.typeId,
        uuid: investigation2.investigationEventId.uuid,
      })

      const [
        {
          data: { investigation: investigation1Response },
        },
        {
          data: { investigation: investigation2Response },
        },
      ] = await Promise.all([investigationCall1, investigationCall2])

      return [investigation1Response, investigation2Response]
    },
    {
      enabled: !!investigationId,
    }
  )
}

export default useGetInvestigationAtEvent
