import { useCallback, useState } from 'react'

const useToggle = (
  initialValue = false
): {
  value: boolean
  toggle: () => void
  enable: () => void
  disable: () => void
} => {
  const [value, setValue] = useState(initialValue)

  const toggle = useCallback(() => {
    setValue((v) => !v)
  }, [])

  const enable = useCallback(() => {
    setValue(true)
  }, [])

  const disable = useCallback(() => {
    setValue(false)
  }, [])

  return { value, toggle, enable, disable }
}

export default useToggle
