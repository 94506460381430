import { FC } from 'react'
import { Row, Col, Descriptions } from 'antd'
import { useTranslation } from 'react-i18next'
import { ExternalLink } from '@signifyd/components'
import { ReturnProduct } from '@signifyd/http'
import { formatPrice } from './ReturnDetails.utils'

const { Item: DescriptionItem } = Descriptions

interface Props {
  returnedItem: ReturnProduct
  refundCurrency?: string | null
}

const ReturnedItem: FC<Props> = ({ returnedItem, refundCurrency }) => {
  const { t } = useTranslation()

  return (
    <Row gutter={32}>
      <Col md={12}>
        <Descriptions colon={false} size="small">
          <DescriptionItem
            label={t('pages.caseReview.returnDetails.descriptions.returnItem')}
          >
            {returnedItem.itemUrl ? (
              <ExternalLink target="_blank" url={returnedItem.itemUrl}>
                {returnedItem.itemName}
              </ExternalLink>
            ) : (
              returnedItem.itemName
            )}
          </DescriptionItem>
          <DescriptionItem
            label={t('pages.caseReview.returnDetails.descriptions.reason')}
          >
            {t(`returnAttemptReason.${returnedItem.reason}`)}
          </DescriptionItem>
        </Descriptions>
      </Col>
      <Col md={12}>
        <Descriptions colon={false} size="small">
          <DescriptionItem
            label={t('pages.caseReview.returnDetails.descriptions.itemId')}
          >
            {returnedItem.itemId}
          </DescriptionItem>
          <DescriptionItem
            label={t('pages.caseReview.returnDetails.descriptions.itemPrice')}
          >
            {formatPrice(returnedItem.itemPrice, refundCurrency)}
          </DescriptionItem>
        </Descriptions>
      </Col>
    </Row>
  )
}

export default ReturnedItem
