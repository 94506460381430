import {
  createPredictionListEntriesWithJson,
  deletePredictionListEntries,
  DUPLICATE_PREDICTION_LIST_ENTRY_ACTION,
  listPredictionLists,
  PREDICTION_LIST_TYPE,
  PredictionList,
} from '@signifyd/http'
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query'
import { message } from 'antd'
import { AxiosError } from 'axios'
import { ThunkCreator } from 'easy-peasy'
import { Trans, useTranslation } from 'react-i18next'
import { NewNoteUpdate } from 'store/currentCase/types/case.notes.types'

export type GetOrderAttributeListsData = {
  teamId: number
  value: string | undefined
  type: PREDICTION_LIST_TYPE
}

export type AddOrRemoveItemListMutation = GetOrderAttributeListsData & {
  addNote: ThunkCreator<NewNoteUpdate, any>
  investigationId: number
}

export type RemoveItemFromList = {
  list: PredictionList
  value: string
}
export type AddItemToList = RemoveItemFromList & {
  type: PREDICTION_LIST_TYPE
}

export type ListData = {
  contains: Array<PredictionList>
  excludes: Array<PredictionList>
}

/* GET all list data for the given type & value */
export const useGetListData = ({
  teamId,
  type,
  value,
}: GetOrderAttributeListsData): UseQueryResult<ListData> => {
  const { t } = useTranslation()

  return useQuery(
    ['OrderAttributeListsData', { teamId, type, value }],
    async () => {
      const [containsResponse, doesNotContainResponse] = await Promise.all([
        listPredictionLists({
          teamIds: teamId.toString(),
          containsEntry: value,
          types: [type],
          isShownInOrdersApp: true,
        }),
        listPredictionLists({
          teamIds: teamId.toString(),
          doesNotContainEntry: value,
          types: [type],
          isShownInOrdersApp: true,
        }),
      ])

      return {
        contains: containsResponse.data.data,
        excludes: doesNotContainResponse.data.data,
      }
    },
    {
      onError: () => {
        message.error({
          content: t('orderAttributeLists.onFetchFailure'),
          key: teamId,
        })

        return { contains: [], excludes: [] }
      },
      enabled: !!value,
      initialData: { contains: [], excludes: [] },
    }
  )
}

/* ADD ITEM TO A LIST */
export const useAddToListMutation = ({
  teamId,
  type,
  value,
  addNote,
  investigationId,
}: AddOrRemoveItemListMutation): UseMutationResult<
  string,
  AxiosError,
  AddItemToList
> => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ list, value, type }: AddItemToList) => {
      const { id, name } = list

      await createPredictionListEntriesWithJson(id, {
        values: [value],
        type,
        duplicateEntryAction: DUPLICATE_PREDICTION_LIST_ENTRY_ACTION.DISCARD,
      })

      return name
    },
    onSuccess: (listName) => {
      if (addNote) {
        addNote({
          caseId: investigationId,
          text: t('orderAttributeLists.onAddItemNote', {
            listName,
            interpolation: { escapeValue: false },
          }),
        })
      }

      message.success(
        <Trans
          i18nKey="orderAttributeLists.onAddSuccess"
          values={{ listName }}
          components={{ bold: <strong /> }}
          shouldUnescape
        />
      )

      queryClient.invalidateQueries([
        'OrderAttributeListsData',
        { teamId, type, value },
      ])
    },
    onError: () => {
      message.error(t('orderAttributeLists.onAddFailure'))
    },
  })
}

/* REMOVE ITEM TO A LIST */
export const useRemoveFromListMutation = ({
  teamId,
  type,
  value,
  addNote,
  investigationId,
}: AddOrRemoveItemListMutation): UseMutationResult<
  string,
  AxiosError,
  RemoveItemFromList
> => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ list, value }: RemoveItemFromList) => {
      const { id, name } = list

      await deletePredictionListEntries(id, {
        listEntryValues: [value],
      })

      return name
    },
    onSuccess: (listName) => {
      if (addNote) {
        addNote({
          caseId: investigationId,
          text: t('orderAttributeLists.onRemoveItemNote', {
            listName,
            interpolation: { escapeValue: false },
          }),
        })
      }

      message.success(
        <Trans
          i18nKey="orderAttributeLists.onRemoveSuccess"
          values={{ listName }}
          components={{ bold: <strong /> }}
          shouldUnescape
        />
      )

      queryClient.invalidateQueries([
        'OrderAttributeListsData',
        { teamId, type, value },
      ])
    },
    onError: () => {
      message.error(t('orderAttributeLists.onRemoveFailure'))
    },
  })
}
