/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { z } from 'zod'
import moment from 'moment-timezone'
import { COUNTRY_ISO } from '@signifyd/http'
import { NEW_CASE_DATE_FORMAT } from './components/Fields/utils'

const requiredString = z.string({ required_error: 'required' })
const nonRequiredString = z.string().nullish()
const zDecimal = z.coerce
  .number({
    invalid_type_error: 'number',
  })
  .multipleOf(0.01, { message: 'decimal' })
  .nonnegative({ message: 'decimal' })
const countryCode = z.nativeEnum(COUNTRY_ISO, { required_error: 'required' })

const sameShippingObj = z.object({
  confirmationEmail: requiredString,
  fullName: nonRequiredString,
  confirmationPhone: nonRequiredString,
  deliveryAddress: z.object({
    streetAddress: nonRequiredString,
    unit: nonRequiredString,
    city: nonRequiredString,
    provinceCode: nonRequiredString,
    postalCode: nonRequiredString,
    countryCode: nonRequiredString,
  }),
})

const diffShippingObj = z.object({
  confirmationEmail: requiredString,
  fullName: requiredString,
  confirmationPhone: nonRequiredString,
  deliveryAddress: z.object({
    streetAddress: requiredString,
    unit: nonRequiredString,
    city: requiredString,
    provinceCode: nonRequiredString,
    postalCode: nonRequiredString,
    countryCode,
  }),
})

const getNewCaseFormSchema = (sameShipping: boolean) =>
  z.object({
    team: requiredString,
    recipient: sameShipping ? sameShippingObj : diffShippingObj,
    purchase: z.object({
      browserIpAddress: z.string().ip({ message: 'ip' }).nullish(),
      avsResponseCode: requiredString,
      cvvResponseCode: nonRequiredString,
      currency: requiredString,
      totalPrice: requiredString.pipe(zDecimal),
      shippingPrice: zDecimal.nullish(),
      orderId: requiredString,
      createdAt: requiredString,
    }),
    userAccount: z.object({
      accountNumber: nonRequiredString,
      createdDate: nonRequiredString,
      aggregateOrderCount: z.coerce
        .number({
          invalid_type_error: 'number',
        })
        .nonnegative({ message: 'decimal' })
        .nullish(),
      aggregateOrderDollars: zDecimal.nullish(),
      phone: nonRequiredString,
    }),
    card: z.object({
      bin: nonRequiredString,
      cardHolderName: requiredString,
      billingAddress: z.object({
        streetAddress: requiredString,
        unit: nonRequiredString,
        city: requiredString,
        provinceCode: nonRequiredString,
        postalCode: nonRequiredString,
        countryCode,
      }),
    }),
    // Required by API
    platformAndClient: z.object({
      signifydClientApp: z.string(),
      signifydClientAppVersion: z.string(),
    }),
  })

export const NewCaseFormValidateSchema = (sameShipping: boolean) =>
  getNewCaseFormSchema(sameShipping)

export const NewCaseFormDefaultValues = {
  recipient: {
    deliveryAddress: {
      countryCode: COUNTRY_ISO.US,
    },
  },
  purchase: {
    createdAt: moment().format(NEW_CASE_DATE_FORMAT),
  },
  card: {
    billingAddress: {
      countryCode: COUNTRY_ISO.US,
    },
  },
  // Required by API
  platformAndClient: {
    signifydClientApp: 'Case Form',
    signifydClientAppVersion: '1.0',
  },
}
