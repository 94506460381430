import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Descriptions } from 'antd'
import { InvestigationInfo, PREDICTION_LIST_TYPE } from '@signifyd/http'
import CopyWrapper from 'core/components/CopyWrapper'
import OrderAttributeLists from 'core/components/OrderAttributeLists'
import NoInformation from 'core/components/NoInformation'

const { Item: DescriptionItem } = Descriptions

interface Props {
  caseDetails: InvestigationInfo | null
}

const Device: FC<Props> = ({ caseDetails }) => {
  const { t } = useTranslation()

  const deviceId = caseDetails?.threatMetrixDeviceId

  return (
    <>
      <Descriptions
        title={t('pages.caseReview.details.device')}
        colon={false}
        size="small"
      >
        {deviceId && (
          <DescriptionItem label={t('pages.caseReview.details.deviceId')}>
            <CopyWrapper text={deviceId}>{deviceId}</CopyWrapper>
          </DescriptionItem>
        )}
      </Descriptions>
      {deviceId && (
        <OrderAttributeLists
          type={PREDICTION_LIST_TYPE.DEVICE_ID}
          value={deviceId}
          analyticsId="device-ID"
        />
      )}
      {!deviceId && <NoInformation />}
    </>
  )
}

export default Device
