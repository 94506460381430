export default {
  summary: 'Summary ({{range}})',
  summary_one: 'Summary (Last day)',
  summary_other: 'Summary (Last {{count}} days)',
  lastDays_one: 'Last {{count}} day',
  lastDays_other: 'Last {{count}} days',
  title: {
    orders: 'Orders',
    claims: 'Claims',
  },
  guarantee: {
    approved: 'Approved orders',
    declined: 'Declined orders',
    canceled: 'Canceled orders',
    inReview: 'In review orders',
    pending: 'Pending orders',
  },
  claim: {
    approved: 'Approved claims',
    declined: 'Declined claims',
    needsMoreInfo: 'Needs more info',
  },
}
