import { FC, useContext } from 'react'
import { nanoid } from 'nanoid'
import { Space } from '@signifyd/components'
import PageSizeContext, { PAGE_SIZE } from 'core/components/PageSizeContext'
import { useStoreState } from 'store'
import { isTransportAirline } from 'core/utils/isTransportAirline/isTransportAirline'
import { useCaseEntries } from 'core/hooks/useCaseEntries'
import CaseIntelligence from '../CaseIntelligence'
import CaseClaimsBanner from '../CaseClaimsBanner'
import CaseSummary from '../CaseSummary'
import CaseDetails from './components/CaseDetails'
import SellerDetails from './components/SellerDetails'
import MembershipDetails from './components/MembershipDetails/MembershipDetails'
import TravelDetails from './components/TravelDetails'

const OrderDetails: FC = () => {
  const pageSize = useContext(PageSizeContext)
  const isLarge = pageSize === PAGE_SIZE.LG_AND_LARGER

  const { details, order } = useStoreState((state) => state.currentCase)
  const { data: entries } = useCaseEntries(details?.investigationId)

  if (!details || !entries) {
    return null
  }

  const sellers = details.sellers.map((seller) => ({
    ...seller,
    runtimeId: nanoid(),
  }))

  const isAirlineOrder = isTransportAirline(details.products)

  return (
    <div data-test-id="orderDetailsDisplay">
      <CaseClaimsBanner caseDetails={details} />
      <CaseIntelligence isLarge={isLarge} caseDetails={details} />
      <Space size="sm" />
      <CaseSummary
        orderDetails={order}
        caseEntries={entries}
        caseDetails={details}
      />
      {isAirlineOrder && (
        <>
          <Space size="sm" />
          <TravelDetails investigation={details} />
        </>
      )}
      <Space size="sm" />
      <CaseDetails caseEntries={entries} caseDetails={details} />
      <Space size="sm" />
      <SellerDetails sellers={sellers} currency={details.currency ?? 'USD'} />
      {details.memberships?.length > 0 && (
        <>
          <Space size="sm" />
          <MembershipDetails memberships={details.memberships} />
        </>
      )}
      <Space size="sm" />
    </div>
  )
}

export default OrderDetails
