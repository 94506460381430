import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import DataDisplay from '../DataDisplay/DataDisplay'

interface Props {
  eventContent: unknown | null
  isLoading: boolean
}

const EventDataContainer: FC<Props> = ({ eventContent, isLoading }) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'eventViewer',
  })

  if (!eventContent || typeof eventContent !== 'object') {
    return null
  }

  return (
    <DataDisplay
      title={t('eventContent')}
      data={eventContent}
      isLoading={isLoading}
    />
  )
}

export default EventDataContainer
