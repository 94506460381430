import { FC, ReactNode } from 'react'
import { Button, Tooltip } from 'antd'
import { Text } from '@signifyd/components'
import { joinClassNames } from '@signifyd/utils'
import { ActionState } from 'store/caseActions'
import styles from './ActionButton.less'

interface Props {
  icon?: ReactNode
  text: string
  subText?: string
  tooltip?: string
  className?: string
  onClick?: () => void
  actionState: ActionState
  isLoading: boolean
  testId?: string
}

export const ActionButton: FC<Props> = ({
  icon,
  text,
  subText,
  tooltip,
  className,
  onClick,
  actionState,
  isLoading,
  testId,
}) => {
  if (actionState.hidden) {
    return null
  }

  return (
    <>
      <Tooltip
        placement="topLeft"
        title={actionState.disabledReason || tooltip}
        mouseEnterDelay={0.5}
      >
        <Button
          data-analytics-id={`action-button-${text}`}
          data-test-id={testId || `actionButton-${text}`}
          className={joinClassNames([className, styles.actionBtn])}
          onClick={onClick}
          style={{ padding: ' 0 0' }}
          disabled={actionState.disabled || isLoading}
          type="link"
          icon={icon}
        >
          {text}
        </Button>
      </Tooltip>
      {subText && (
        <Text
          size="xs"
          className={styles.subText}
          data-test-id={`${testId}-subtext`}
        >
          {subText}
        </Text>
      )}
    </>
  )
}

export default ActionButton
