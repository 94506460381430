import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { InvestigationInfo, USER_TEAM_ROLE } from '@signifyd/http'

import { StopOutlined } from '@ant-design/icons'
import { useStoreState } from 'store'
import CancelGuaranteeModal from 'pages/CaseReviewPage/containers/CancelGuaranteeModal'

import {
  canCancelGuarantee,
  useCancelGuarantee,
} from 'core/queries/useCancelGuarantee'
import ActionButton from '../ActionButton'
import styles from './CancelGuaranteeButton.less'

interface Props {
  caseDetails: InvestigationInfo
}

export const CancelGuaranteeButton: FC<Props> = ({ caseDetails }) => {
  const { t } = useTranslation()
  const [modalVisible, setModalVisible] = useState(false)

  const { isLoading, mutate } = useCancelGuarantee()

  const { hasTeamRole } = useStoreState((state) => state.user)

  const hasReviewerRole = hasTeamRole(
    caseDetails.teamId,
    USER_TEAM_ROLE.REVIEWER
  )

  return (
    <>
      <ActionButton
        onClick={() => setModalVisible(true)}
        actionState={canCancelGuarantee(caseDetails, hasReviewerRole)}
        isLoading={isLoading}
        icon={<StopOutlined />}
        className={styles.redBtn}
        text={t('caseActions.cancelGuarantee.text')}
        tooltip={t('caseActions.cancelGuarantee.tooltip')}
      />

      <CancelGuaranteeModal
        visible={modalVisible}
        closeModal={() => setModalVisible(false)}
        onOk={() =>
          mutate({ caseDetails }, { onSuccess: () => setModalVisible(false) })
        }
        isLoading={isLoading}
      />
    </>
  )
}

export default CancelGuaranteeButton
