export default {
  map: 'Map',
  markerType: {
    billingAddress: 'Billing address',
    deliveryAddress: 'Delivery address',
    IPGeolocation: 'IP geolocation',
    billingAndIPGeo: 'Billing and IP geolocation',
    billingAndDelivery: 'Billing and delivery address',
    IPGeoAndDelivery: 'IP geolocation and delivery address',
    billingDeliveryAndIPGeo:
      'Billing address, delivery address and IP geolocation',
    flightOrigin: 'Departure airport',
  },
}
