import { FC, ReactNode } from 'react'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { Form } from '@ant-design/compatible'
import { Col, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { GetFieldDecoratorOptions } from '@ant-design/compatible/lib/form/Form'
import styles from './FormInputWrapper.less'

const { Item: FormItem } = Form

export type GetFieldDecorator = (
  id: string,
  options?: GetFieldDecoratorOptions
) => (node: ReactNode) => ReactNode

export interface Props extends GetFieldDecoratorOptions {
  inputKey: string
  label?: string
  translationPrefix: string
  span: number
  required?: boolean
  type?: string
  min?: number
  max?: number
  showHelpText?: boolean
}

const getFormInputWrapper =
  (getFieldDecorator: GetFieldDecorator): FC<Props> =>
  ({
    translationPrefix,
    inputKey,
    label,
    span,
    required = false,
    type = 'string',
    min = 0,
    showHelpText,
    children,
    valuePropName,
    ...restOptions
  }) => {
    const { t } = useTranslation()

    return (
      <Col span={span}>
        <FormItem
          className={styles.formItem}
          label={
            <>
              {t(
                `${translationPrefix}.${inputKey}.${
                  label ? `${label}.` : ''
                }label` as any
              )}
              {showHelpText && (
                <Tooltip
                  className={styles.hoverHelp}
                  placement="top"
                  title={t(`${translationPrefix}.${inputKey}.helpText` as any)}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              )}
            </>
          }
        >
          {getFieldDecorator(inputKey, {
            valuePropName: valuePropName || 'value',
            rules: [
              {
                required,
                message: required
                  ? t(`${translationPrefix}.${inputKey}.error` as any)
                  : undefined,
                type,
                whitespace: true,
                min,
              },
            ],
            ...restOptions,
          })(children)}
        </FormItem>
      </Col>
    )
  }
export default getFormInputWrapper
