import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from 'antd'
import { TextField } from '../Fields'
import FormCard from './FormCard'
import { ReactHookFormTypes } from './Card.types'

export const OrderHistoryCard: FC<ReactHookFormTypes> = ({
  control,
  errors,
}) => {
  const { t } = useTranslation()

  return (
    <FormCard title={t('pages.newCase.cards.orderHistory')}>
      <Row>
        <TextField
          fieldName="userAccount.aggregateOrderCount"
          span={8}
          control={control}
          errors={errors}
        />

        <TextField
          fieldName="userAccount.aggregateOrderDollars"
          span={8}
          control={control}
          errors={errors}
        />
      </Row>
    </FormCard>
  )
}

export default OrderHistoryCard
