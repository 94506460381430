import { FC } from 'react'
import { joinClassNames } from '@signifyd/utils'
import styles from './PageWrapper.less'

interface Props {
  backgroundColor?: string
  className?: string
}

const PageWrapper: FC<Props> = ({
  children,
  className,
  backgroundColor = 'transparent',
}) => (
  <div style={{ backgroundColor }}>
    <div className={joinClassNames([styles.pageWrapper, className])}>
      {children}
    </div>
  </div>
)

export default PageWrapper
