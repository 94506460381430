import {
  SEARCH_FIELD,
  ANALYZED_SEARCH_FIELDS,
  SearchQuery,
  SEARCH_OPERATOR,
} from '@signifyd/http'
import { notNil } from '@signifyd/utils'
import { has, isNil } from 'lodash'

export function isRangeFilter(value: unknown): boolean {
  return has(value, 'min') && has(value, 'max')
}

export function filterEmptyValues(value: any): boolean {
  // filter empty array
  if (Array.isArray(value) && !value.length) {
    return false
  }
  // filter empty strings
  if (value === '') {
    return false
  }
  // filter empty range objects
  if (isRangeFilter(value) && isNil(value.min) && isNil(value.max)) {
    return false
  }

  // Allow null but not undefined
  return notNil(value)
}

export function getOperator(
  fieldName: SEARCH_FIELD,
  value: StrOrNum | boolean
): SEARCH_OPERATOR {
  const isAnalyzed = ANALYZED_SEARCH_FIELDS.includes(fieldName as any)
  const isFuzzy = String(value).includes('*')

  if (isAnalyzed) {
    return isFuzzy ? SEARCH_OPERATOR.CONTAINS_LIKE : SEARCH_OPERATOR.CONTAINS
  }

  return isFuzzy ? SEARCH_OPERATOR.LIKE : SEARCH_OPERATOR.EQUAL
}

export function buildRangeQuery(
  { min, max }: RangeObject,
  fieldName: SEARCH_FIELD
): SearchQuery {
  if (notNil(min) && isNil(max)) {
    return {
      field: {
        fieldName,
        operator: SEARCH_OPERATOR.GTE,
        values: min,
      },
    }
  }
  if (isNil(min) && notNil(max)) {
    return {
      field: {
        fieldName,
        operator: SEARCH_OPERATOR.LTE,
        values: max,
      },
    }
  }

  return {
    field: {
      fieldName,
      operator: SEARCH_OPERATOR.RANGE,
      values: [min, max],
    },
  }
}
