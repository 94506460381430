import { getOrdersUrl } from '@signifyd/components'
import { encodeUrlHash } from 'core/utils/urlEncoder'
import { defaultSearchState } from 'store/search'

export const encodedAgentConsoleSearchUrl = (value: string): string => {
  const searchURLValues = {
    ...defaultSearchState,
    searchTerm: value,
  }

  return `${getOrdersUrl()}/search/${encodeUrlHash(searchURLValues)}`
}

export const googleSearchLink = 'https://www.google.com/search?q='

export const piplSearchLink = 'https://pipl.com/search/?q='
