import {
  CLAIM_DISPOSITION,
  CLAIM_STATUS,
  INV_GUARANTEE_DISPOSITION,
  SEARCH_FIELD,
  SEARCH_OPERATOR,
  SEARCH_ORDER,
  SearchAggregationQuery,
  SearchAggregationResponse,
  SearchQuery,
} from '@signifyd/http'
import { RangeFilter } from 'store/search'
import { ClaimData, OrderData } from '../../queries/useSearchAggregates'

const GUARANTEE_DISPOSITIONS = [
  INV_GUARANTEE_DISPOSITION.APPROVED,
  INV_GUARANTEE_DISPOSITION.DECLINED,
  INV_GUARANTEE_DISPOSITION.CANCELED,
  INV_GUARANTEE_DISPOSITION.PENDING,
  INV_GUARANTEE_DISPOSITION.IN_REVIEW,
]

export const parseOrderData = (
  aggregateCounts: SearchAggregationResponse
): OrderData => {
  const {
    fields: {
      byDisposition: { fieldCounts },
    },
  } = aggregateCounts

  const guaranteeMap = GUARANTEE_DISPOSITIONS.reduce((map, currentValue) => {
    map.set(
      currentValue,
      fieldCounts.find((fieldCount) => fieldCount.value === currentValue)
        ?.count ?? 0
    )

    return map
  }, new Map<INV_GUARANTEE_DISPOSITION, number>())

  return {
    approvedCount: guaranteeMap.get(INV_GUARANTEE_DISPOSITION.APPROVED)!,
    declinedCount: guaranteeMap.get(INV_GUARANTEE_DISPOSITION.DECLINED)!,
    canceledCount: guaranteeMap.get(INV_GUARANTEE_DISPOSITION.CANCELED)!,
    inReviewCount: guaranteeMap.get(INV_GUARANTEE_DISPOSITION.IN_REVIEW)!,
    pendingCount: guaranteeMap.get(INV_GUARANTEE_DISPOSITION.PENDING)!,
  }
}

const CLAIM_DISPOSITIONS: Array<CLAIM_DISPOSITION> = [
  CLAIM_DISPOSITION.APPROVED,
  CLAIM_DISPOSITION.DECLINED,
]

const CLAIM_STATUSES = [CLAIM_STATUS.NEED_MORE_INFO]

export const parseClaimData = (
  aggregateCounts: SearchAggregationResponse
): ClaimData => {
  const {
    fields: {
      byClaimDisposition: { fieldCounts },
      byClaimStatus: { fieldCounts: statusCounts },
    },
  } = aggregateCounts

  const claimsMap = CLAIM_DISPOSITIONS.reduce((map, currentValue) => {
    map.set(
      currentValue,
      fieldCounts.find((fieldCount) => fieldCount.value === currentValue)
        ?.count ?? 0
    )

    return map
  }, new Map<CLAIM_DISPOSITION, number>())

  const statusMap = CLAIM_STATUSES.reduce((map, currentValue) => {
    map.set(
      currentValue,
      statusCounts.find((fieldCount) => fieldCount.value === currentValue)
        ?.count ?? 0
    )

    return map
  }, new Map<CLAIM_STATUS, number>())

  return {
    approvedCount: claimsMap.get(CLAIM_DISPOSITION.APPROVED)!,
    declinedCount: claimsMap.get(CLAIM_DISPOSITION.DECLINED)!,
    needsMoreInfoCount: statusMap.get(CLAIM_STATUS.NEED_MORE_INFO)!,
  }
}

export const generateAggregateQuery = (
  dates: RangeFilter<string>,
  teamIds: Array<number>
): SearchAggregationQuery => {
  const { min, max } = dates

  const query: SearchQuery = {
    and: [
      {
        field: {
          fieldName: SEARCH_FIELD.normalizedPurchaseCreatedAt,
          operator: SEARCH_OPERATOR.RANGE,
          values: [new Date(min!), new Date(max!)],
        },
      },
    ],
  }

  if (teamIds.length) {
    query.and.push({
      field: {
        fieldName: SEARCH_FIELD.teamId,
        operator: SEARCH_OPERATOR.IN,
        values: teamIds,
      },
    })
  }

  const fields = {
    byDisposition: {
      fieldName: SEARCH_FIELD.guaranteeDisposition,
      order: SEARCH_ORDER.ASCENDING,
      size: 10,
    },
    byClaimDisposition: {
      fieldName: SEARCH_FIELD.claimDisposition,
      order: SEARCH_ORDER.ASCENDING,
      size: 10,
    },
    byClaimStatus: {
      fieldName: SEARCH_FIELD.claimStatus,
      order: SEARCH_ORDER.ASCENDING,
      size: 10,
    },
  }

  return {
    query,
    fields,
    filters: {},
  }
}

export const handleArrayChange = <T,>(
  targetValue: T,
  values: Array<T>
): Array<T> => {
  const setValues = new Set<T>(values)

  if (setValues.has(targetValue)) {
    setValues.delete(targetValue)
  } else {
    setValues.add(targetValue)
  }

  return [...setValues]
}
