import { FC, useState } from 'react'
import { Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { useFileAttacher } from '@signifyd/components'
import { ResubmitGuaranteeRequiredFields } from 'store/caseActions'
import { useResubmitGuarantee } from 'core/hooks/useResubmitGuarantee'
import ResubmitGuaranteeModalContent from './ResubmitGuaranteeModalContent'

interface Props {
  caseDetails: ResubmitGuaranteeRequiredFields
  visible: boolean
  closeModal: () => void
}

const ResubmitGuaranteeModal: FC<Props> = ({
  caseDetails,
  visible,
  closeModal,
}) => {
  const { t } = useTranslation()

  const { mutateAsync: resubmitForGuarantee, isLoading } = useResubmitGuarantee(
    caseDetails.investigationId
  )

  const [note, setNote] = useState('')
  const fileAttacher = useFileAttacher()
  const { files } = fileAttacher

  // Functions
  const onModalSubmit = async (): Promise<void> => {
    await resubmitForGuarantee({
      payload: `Case Re-Review Reason: ${note}`,
      files,
    })

    closeModal()
  }

  return (
    <Modal
      data-test-id="resubmitGuaranteeModal"
      title={t('caseActions.reSubmitGuarantee.modalTitle')}
      open={visible}
      confirmLoading={isLoading}
      okType="link"
      okText={t('caseActions.reSubmitGuarantee.okText')}
      cancelButtonProps={{ type: 'link' }}
      closable={false}
      onOk={onModalSubmit}
      okButtonProps={{
        disabled: note.replace(/\W/g, '').length < 1,
      }}
      onCancel={closeModal}
      destroyOnClose
      maskClosable={false}
    >
      <ResubmitGuaranteeModalContent
        setNote={setNote}
        note={note}
        fileAttacher={fileAttacher}
      />
    </Modal>
  )
}

export default ResubmitGuaranteeModal
