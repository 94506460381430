import { action, thunk } from 'easy-peasy'
import {
  getInvestigationSearch,
  SEARCH_FIELD,
  INV_SEARCH_VIEW,
} from '@signifyd/http'
import invSearchQueryBuilder from 'core/utils/invSearchQueryBuilder'
import { CaseDetailsModel } from './types/case.details.types'

const caseDetailsModel: CaseDetailsModel = {
  // State
  details: null,
  detailsServerError: false,

  // Getters / Setters
  setCaseDetails: action((state, payload) => {
    state.details = payload
  }),
  clearCaseDetails: action((state) => {
    state.details = null
  }),
  setServerError: action((state, payload) => {
    state.detailsServerError = payload
  }),

  // Http Stuff
  getCaseDetails: thunk(async (actions, caseId) => {
    actions.setServerError(false)

    const searchQuery = invSearchQueryBuilder({
      filters: { [SEARCH_FIELD.investigationId]: caseId },
      view: INV_SEARCH_VIEW.INVESTIGATION,
    })

    try {
      const { data } = await getInvestigationSearch(searchQuery)

      actions.setCaseDetails(data.investigations[0])
    } catch (e: any) {
      if (e.response.status >= 500) {
        actions.setServerError(true)
      }
    }
  }),
}

export default caseDetailsModel
