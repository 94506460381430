import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { CHECKOUT_CHECKPOINT_ACTION } from '@signifyd/http'
import SummaryItem from '../SummaryItem'

interface Props {
  values: Array<CHECKOUT_CHECKPOINT_ACTION>
}

const GuaranteeStatusItem: FC<Props> = ({ values }) => {
  const { t } = useTranslation()

  return (
    <SummaryItem
      label={t('filters.recommendedAction.label')}
      value={values
        .map((item) => t(`filters.recommendedAction.${item}`))
        .join(', ')}
    />
  )
}

export default GuaranteeStatusItem
