import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { INV_STATUS, InvestigationInfo, updateCase } from '@signifyd/http'
import { message } from 'antd'
import { useTranslation } from 'react-i18next'
import { useStoreActions } from 'store'

type CaseStatusRequiredFields = Pick<InvestigationInfo, 'investigationId'>

interface UpdateCaseStatusPayload extends CaseStatusRequiredFields {
  payload: INV_STATUS
}

export const useUpdateCaseStatus = (): UseMutationResult<
  void,
  void,
  UpdateCaseStatusPayload
> => {
  const { refreshCurrentCase } = useStoreActions((store) => store.currentCase)
  const { t } = useTranslation(undefined, {
    keyPrefix: 'store.caseActions.caseStatus',
  })

  return useMutation({
    mutationFn: async ({ investigationId, payload }) => {
      await updateCase(investigationId, { status: payload })
    },
    onSuccess: async () => {
      refreshCurrentCase()
      await message.success(t('apiSuccess'))
    },
    onError: async () => {
      await message.error(t('apiFailure'))
    },
  })
}
