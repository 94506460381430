import { RETURN_ATTEMPT_REASON } from '@signifyd/http'

const returnAttemptReason: Record<RETURN_ATTEMPT_REASON, string> = {
  BETTER_PRICE_AVAILABLE: 'Better price available',
  BOUGHT_BY_MISTAKE: 'Bought by mistake',
  DID_NOT_AUTHORIZE: 'Did not authorize',
  DUPLICATE_ORDER: 'Duplicate order',
  ITEM_ARRIVED_TOO_LATE: 'Item arrived too late',
  ITEM_DAMAGED: 'Item damaged',
  ITEM_NOT_RECEIVED: 'Item not received',
  NO_LONGER_NEEDED: 'No longer needed',
  SIGNIFICANTLY_NOT_AS_DESCRIBED: 'Significantly not as described',
  CANCELED: 'Canceled',
}
export default returnAttemptReason
