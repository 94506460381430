import {
  getInvestigationSearch,
  INV_SEARCH_VIEW,
  SEARCH_FIELD,
} from '@signifyd/http'
import {
  createFlexibleSearchRequest,
  getDefaultSearchRange,
} from '@signifyd/utils'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { useStoreState } from 'store'

const useUserHasAirlineOrders = (): UseQueryResult<boolean> => {
  const { currentTeams } = useStoreState((store) => store.user)

  return useQuery<boolean>(
    ['airlineSearchResults', currentTeams],
    async () => {
      const teams = currentTeams?.map((team) => team.toString())

      const filters = {
        [SEARCH_FIELD.normalizedPurchaseCreatedAt]: getDefaultSearchRange(),
        [SEARCH_FIELD.departurePortCode]: 'NOT_NULL',
        [SEARCH_FIELD.teamId]: teams,
      }

      const flexibleSearchQuery = createFlexibleSearchRequest({
        view: INV_SEARCH_VIEW.INVESTIGATION_SUMMARY,
        filters,
      })

      const { data: search } = await getInvestigationSearch(flexibleSearchQuery)

      return search.totalResults > 0
    },
    {
      onError: () => {
        return false
      },
      keepPreviousData: true,
    }
  )
}

export default useUserHasAirlineOrders
