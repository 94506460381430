import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { FULFILLMENT_METHOD, SHIPPING_METHODS } from '@signifyd/http'
import SummaryItem from '../SummaryItem'

interface Props {
  values: Array<SHIPPING_METHODS | FULFILLMENT_METHOD>
}

const ShippingMethodItem: FC<Props> = ({ values }) => {
  const { t } = useTranslation()

  const value = values
    .map((item) => t(`filters.shippingMethod.${item}`))
    .join(', ')

  return <SummaryItem label={t('filters.shippingMethod.label')} value={value} />
}

export default ShippingMethodItem
