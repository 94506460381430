import { createContext, Context } from 'react'

export enum PAGE_SIZE {
  MD_AND_SMALLER = 'MD_AND_SMALLER',
  LG_AND_LARGER = 'LG_AND_LARGER',
}

export function getPageSize(): PAGE_SIZE {
  const width = window.innerWidth

  // Do not change this value, it aligns with the antd breakpoints
  return width >= 992 ? PAGE_SIZE.LG_AND_LARGER : PAGE_SIZE.MD_AND_SMALLER
}
export const initialPageSize = getPageSize()

const PageSizeContext: Context<PAGE_SIZE> = createContext(initialPageSize)

export default PageSizeContext
