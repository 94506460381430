import { computed, Computed } from 'easy-peasy'
import { StoreModel } from 'store'
import resubmitGuaranteeModel from './caseActions.resubmitGuarantee.store'
import updateAddressModel from './caseActions.updateAddress.store'
import { ResubmitGuaranteeActionModel, UpdateAddressActionModel } from './types'

export * from './types'

interface CaseActionModel {
  updateAddress: UpdateAddressActionModel
  resubmitGuarantee: ResubmitGuaranteeActionModel
  canSeeGuaranteeActions: Computed<CaseActionModel, boolean, StoreModel>
}

export const caseActionModel: CaseActionModel = {
  // individual case actions
  updateAddress: updateAddressModel,
  resubmitGuarantee: resubmitGuaranteeModel,

  // action section visibility
  canSeeGuaranteeActions: computed(
    [(_state, storeState) => storeState.currentCase.details],
    (caseDetails) =>
      !(
        caseDetails?.guaranteeEligible === 'false' &&
        caseDetails?.guaranteeDisposition === null
      )
  ),
}
