export default {
  title: 'Order',
  name: 'Name',
  price: 'Price',
  quantity: 'Quantity',
  shippingMethod: 'Shipping method',
  agent: 'AGENT {{- name}}',
  showMore_one: 'Show {{count}} more row',
  showMore_other: 'Show {{count}} more rows',
  showLess: 'Show less',
  viewAllLocations: 'View all locations',
  paymentAndDepature: 'View payment and departure',
}
