import { useState, FocusEvent, ReactElement, ChangeEvent } from 'react'
import { isNaN, round } from 'lodash'
import { Input, Select } from 'antd'
import { InputProps } from 'antd/lib/input'
import { AmountWithCurrency } from 'core/hooks/caseActions/useClaimReimbursement/types'

interface Props extends Omit<InputProps, 'value' | 'onChange'> {
  value?: AmountWithCurrency
  onChange?: (arg: AmountWithCurrency) => void
  currencies?: Array<string>
  'data-test-id'?: string
}

const CurrencyAmountInput = ({
  onChange,
  value,
  'data-test-id': dataTestId = 'currencyAmount',
  currencies = [],
}: Props): ReactElement => {
  const [currentValue, setCurrentValue] = useState<string | undefined>(
    undefined
  )
  const triggerChange = (changedValue: {
    currencyCode?: string
    amount?: number
  }): void => {
    if (onChange) {
      onChange({
        ...(value as AmountWithCurrency),
        ...changedValue,
      })
    }
  }

  const handleNumberChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const amount = round(Number(e.target.value), 2)

    if (isNaN(amount)) {
      triggerChange({ amount: undefined })
    }

    setCurrentValue(amount.toString())
    triggerChange({ amount })
  }

  const handleCurrencyChange = (currencyCode: string): void => {
    triggerChange({ currencyCode })
  }

  const handleOnBlur = (e: FocusEvent<HTMLInputElement>): void => {
    if (e.target.value !== '') {
      // add trailing zeros for currency representation when necessary
      setCurrentValue(Number(e.target.value).toFixed(2))
    }
  }

  const prefixSelector = (
    <Select
      data-analytics-id="currency-select"
      onChange={handleCurrencyChange}
      defaultValue={value?.currencyCode}
      data-test-id={`${dataTestId}Selector`}
      style={{ width: 82 }}
      getPopupContainer={(triggerNode: HTMLElement) =>
        triggerNode.parentNode as HTMLElement
      }
      options={currencies.map((currency) => ({
        key: currency,
        label: currency,
        value: currency,
        'data-test-id': currency,
      }))}
    />
  )

  return (
    <span>
      <Input
        type="number"
        min={0}
        onBlur={handleOnBlur}
        value={currentValue}
        addonBefore={prefixSelector}
        onChange={handleNumberChange}
        data-test-id={`${dataTestId}Input`}
      />
    </span>
  )
}

export default CurrencyAmountInput
