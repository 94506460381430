import { useTranslation } from 'react-i18next'
import { Card, Row, Button } from 'antd'
import { useToggle } from 'core/utils/useToggle'
import styles from './ShowMoreContainer.less'
import { CardHeader } from '../CardHeader/CardHeader'

export interface ShowMoreContainerProps<T> {
  cardTitle: string
  data: Array<T>
  renderItem(args: T): JSX.Element
  idPrefix: string
  defaultToShow?: number
}

export const ShowMoreContainer = <T,>({
  renderItem,
  data,
  cardTitle,
  idPrefix,
  defaultToShow = 6,
}: ShowMoreContainerProps<T>): JSX.Element => {
  const { t } = useTranslation()
  const { value: shouldShowAll, toggle: toggleShowAll } = useToggle()

  const numberMoreThanMaxItems = data.length - defaultToShow
  const dataToShow = shouldShowAll ? data : data.slice(0, defaultToShow)

  const buttonId = shouldShowAll
    ? `${idPrefix}CollapseButton`
    : `${idPrefix}ExpandButton`

  const buttonText = shouldShowAll
    ? t('pages.caseReview.common.showLess')
    : t('pages.caseReview.common.showMore', {
        count: numberMoreThanMaxItems,
      })

  return (
    <Card title={<CardHeader title={cardTitle} />} size="small">
      <div className={styles.wrapper} data-test-id={`${idPrefix}Wrapper`}>
        <Row gutter={[32, 0]}>{dataToShow.map((item) => renderItem(item))}</Row>
      </div>

      {numberMoreThanMaxItems > 0 && (
        <Button
          data-test-id={buttonId}
          data-analytics-id={buttonId}
          onClick={toggleShowAll}
          type="link"
        >
          {buttonText}
        </Button>
      )}
    </Card>
  )
}
