import { FC, useState, memo } from 'react'
import { MoreOutlined } from '@ant-design/icons'
import { Dropdown } from 'antd'
import { joinClassNames } from '@signifyd/utils'
import {
  ACTION_CONTEXT,
  ClaimReimbursementRequiredFields,
} from 'core/hooks/caseActions/useClaimReimbursement/types'
import ClaimReimbursementButton from 'pages/CaseReviewPage/components/ClaimReimbursementButton'
import styles from './MoreOptionsFlyout.less'

interface Props {
  caseDetails: ClaimReimbursementRequiredFields
}

const MoreOptionsFlyout: FC<Props> = ({ caseDetails }) => {
  const [active, setActive] = useState(false)

  return (
    <Dropdown
      onOpenChange={setActive}
      menu={{
        items: [
          {
            key: 'claimReimbursementButton',
            onClick: () => setActive(false),
            label: (
              <ClaimReimbursementButton
                caseDetails={caseDetails}
                context={ACTION_CONTEXT.CaseSearch}
                hideIcon
              />
            ),
          },
        ],
      }}
      open={active}
      placement="bottomLeft"
      trigger={['click', 'contextMenu']}
    >
      <MoreOutlined
        className={joinClassNames([
          styles.dropdownIcon,
          active && styles.active,
        ])}
        rotate={90}
        data-test-id="moreOptionsFlyout"
      />
    </Dropdown>
  )
}

export default memo(MoreOptionsFlyout)
