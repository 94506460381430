import { FC } from 'react'
import { colorYonder } from '@signifyd/colors'
import { T4, Space, SearchResultsIcon } from '@signifyd/components'
import styles from './NotFound.less'

interface Props {
  title: string
}

export const NotFound: FC<Props> = ({ title, children }) => {
  return (
    <div className={styles.wrapper}>
      <SearchResultsIcon style={{ fontSize: 80 }} fill={colorYonder} />
      <Space size="lg" />
      <T4 strong>{title}</T4>
      <div className={styles.messageWrapper}>{children}</div>
    </div>
  )
}

export default NotFound
