import { FC } from 'react'
import { InvestigationInfo } from '@signifyd/http'
import { formatCurrencyNumber } from '@signifyd/utils'

interface Props {
  caseDetails: InvestigationInfo
}

const FormattedAmount: FC<Props> = ({ caseDetails }) => (
  <>
    {`${caseDetails.currency} ${formatCurrencyNumber(
      caseDetails.orderTotalAmount
    )}`}
    {caseDetails.currency !== 'USD' &&
      ` (USD ${formatCurrencyNumber(caseDetails.orderAmountInUsd)})`}
  </>
)

export default FormattedAmount
