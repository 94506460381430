import { FC } from 'react'
import { Controller } from 'react-hook-form'
import { EntitySingleSelect, ENTITY_TYPE } from '@signifyd/components'
import type { FieldProps } from './Fields.types'
import FormItemWrapper from './FormItemWrapper'
import { useGetRequiredError } from './utils'

export const TeamSelectField: FC<FieldProps> = ({
  fieldName,
  control,
  errors,
}) => {
  const errorText = useGetRequiredError(fieldName, errors)

  return (
    <FormItemWrapper errorText={errorText}>
      <Controller
        name={fieldName}
        control={control}
        render={({ field }) => (
          <div
            data-analytics-id="team-select-field"
            data-test-id="teamSelectField"
          >
            <EntitySingleSelect
              width={400}
              entityType={ENTITY_TYPE.TEAM}
              onSave={field.onChange}
              selectedKey={field.value as string}
            />
          </div>
        )}
      />
    </FormItemWrapper>
  )
}

export default TeamSelectField
