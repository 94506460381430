import { FC, useState, memo } from 'react'
import { Space } from '@signifyd/components'
import { useTranslation } from 'react-i18next'
import { CaseNote, NOTE_TYPE } from '@signifyd/http'
import { useStoreState } from 'store'
import EmptyWarning from 'core/components/EmptyWarning'
import NoteTimeline from './NoteTimeline'
import NoteFilter from './NoteFilter'
import styles from './CaseActivity.less'
import { FILTER_TYPE } from './CaseActivity.types'

const getFilteredNotes = (
  allNotes: Array<CaseNote>,
  noteFilter: FILTER_TYPE
): Array<CaseNote> =>
  allNotes.filter(
    ({ noteType }) =>
      (noteFilter === FILTER_TYPE.EVENTS &&
        noteType === NOTE_TYPE.SIGNIFYD_EVENTS) ||
      (noteFilter === FILTER_TYPE.NOTES &&
        noteType !== NOTE_TYPE.SIGNIFYD_EVENTS)
  )

export const CaseActivity: FC = () => {
  const { t } = useTranslation()

  const [noteFilter, setNoteFilter] = useState<null | FILTER_TYPE>(null)
  const allNotes = useStoreState((state) => state.currentCase.notes)
  const notesError = useStoreState((state) => state.currentCase.notesError)

  const filteredNotes = noteFilter
    ? getFilteredNotes(allNotes, noteFilter)
    : allNotes

  return (
    <div className={styles.activityPanel}>
      <div className={styles.fixed}>
        <Space size={16} />
        <NoteFilter
          noteFilter={noteFilter}
          setNoteFilter={setNoteFilter}
          filteredNotesCount={filteredNotes.length}
        />
        <Space size="sm" />
      </div>

      <div className={styles.scrollable}>
        {notesError ? (
          <EmptyWarning text={t('caseActivity.noNoteError')} />
        ) : (
          <NoteTimeline filteredNotes={filteredNotes} />
        )}
      </div>
    </div>
  )
}

export default memo(CaseActivity)
