import { thunkOn } from 'easy-peasy'
import { CaseActionsListenersModel } from './types/case.listeners.types'

const caseActionsListenersModel: CaseActionsListenersModel = {
  onCaseResubmit: thunkOn(
    (_actions, storeActions) =>
      storeActions.caseActions.resubmitGuarantee.resubmitForGuarantee,
    (actions) => actions.refreshCurrentCase()
  ),

  onUpdateAddress: thunkOn(
    (_actions, storeActions) =>
      storeActions.caseActions.updateAddress.performAction,
    (actions) => actions.pollForChanges()
  ),
}

export default caseActionsListenersModel
