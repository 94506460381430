import { FC } from 'react'
import { Card } from 'antd'
import { CardProps } from 'antd/lib/card'

type Props = CardProps

const Section: FC<Props> = ({ children, ...props }) => (
  <Card
    bordered={false}
    styles={{ body: { padding: '0 32px 32px' } }}
    {...props}
  >
    {children}
  </Card>
)

export default Section
