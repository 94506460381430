export default {
  WelcomeScreen: {
    welcomeToSignifyd: 'Welcome to Signifyd!',
    aboutUs:
      "Signifyd instantly reviews every order to determine if it's fraudulent and whether you should accept and ship.",
  },
  StartTrialScreen: {
    start14DayTrial: 'Start your 14-day trial.',
    experience:
      "During the trial, you'll have available Signifyd's Complete plan minus chargeback coverage.",
    noChargebackCoverage:
      'Chargebacks received for an order approved during the trial will not be covered by Signifyd for reimbursement.',
    toActivate:
      'To activate chargeback coverage on future orders, and become eligible for reimbursements, sign up for a plan.',
    startTrial: 'Start trial',
  },
}
