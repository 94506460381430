import { FC, useEffect, useRef } from 'react'
import { CloseOutlined, SearchOutlined } from '@ant-design/icons'
import { Divider, Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import { joinClassNames } from '@signifyd/utils'
import { Space, Text, ErrorBoundary } from '@signifyd/components'
import ResponsiveIconButton from 'core/components/ResponsiveIconButton'
import { SavedFilter } from 'store/search'
import styles from './SearchContainer.less'
import SearchSummary from '../SearchSummary'
import SavedFilters from '../SavedFilters'
import QuickViews from '../QuickViews'
import Section from '../Section'

interface Props {
  searchTerm: string
  editing: boolean
  onSearchTermChange: (search: string) => void
  isInvalidSearch: boolean
  onClear: () => any
  onFocus: () => any
  onSearch: () => any
  onQuickSearch: (key: string) => any
  onSavedFilter: (key: string) => any
  matchingSavedFilter: SavedFilter | undefined
  hasAirlineOrders: boolean
}

const SearchContainer: FC<Props> = ({
  onSearchTermChange,
  searchTerm,
  editing,
  isInvalidSearch,
  onClear,
  onFocus,
  onSearch,
  onQuickSearch,
  onSavedFilter,
  matchingSavedFilter,
  hasAirlineOrders,
}) => {
  const { t } = useTranslation()
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus()
    }
  }, [inputRef, editing])

  const placeholderText = hasAirlineOrders
    ? 'airlinePlaceholder'
    : 'placeholder'

  return (
    <>
      <Row onClick={onFocus}>
        <Text
          size="lg"
          data-analytics-id="search-bar"
          className={styles.searchBar}
          data-test-id="searchBar"
        >
          <SearchOutlined
            className={joinClassNames([styles.icon, styles.searchIcon])}
          />
          {editing ? (
            <>
              <input
                data-test-id="searchBarInput"
                className={styles.input}
                placeholder={t(`search.searchBar.${placeholderText}`)}
                value={searchTerm}
                onChange={(e) => {
                  onSearchTermChange(e.target.value)
                }}
                ref={inputRef}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    onSearch()
                  }
                }}
              />
              {!!searchTerm && (
                <CloseOutlined
                  data-test-id="clearButton"
                  className={joinClassNames([styles.icon, styles.clearIcon])}
                  onClick={() => onSearchTermChange('')}
                />
              )}
            </>
          ) : (
            <>
              <SearchSummary
                placeholderText={t(`search.searchBar.${placeholderText}`)}
              />

              <ResponsiveIconButton
                buttonClass={styles.clearButton}
                onClick={onClear}
                text={t('search.buttons.clearSearch')}
                Icon={<CloseOutlined />}
              />
            </>
          )}
        </Text>
      </Row>

      {editing && (
        <Section>
          {isInvalidSearch && (
            <>
              <Text data-test-id="invalidSearch" type="danger">
                {t('search.searchBar.invalidSearch')}
              </Text>
              <Space size={18} />
            </>
          )}

          <Divider className={styles.divider} />
          <Row>
            <Col
              xs={{ span: 21 }}
              lg={{ span: 8 }}
              className={styles.quickViews}
            >
              <QuickViews onClick={onQuickSearch} />
            </Col>
            <Col xs={{ span: 21 }} lg={{ span: 15 }}>
              <ErrorBoundary
                message={t('errorBoundaries.savedFilters')}
                className={styles.errorBoundary}
              >
                <SavedFilters
                  onClick={onSavedFilter}
                  matchingSavedFilter={matchingSavedFilter}
                />
              </ErrorBoundary>
            </Col>
          </Row>
        </Section>
      )}
    </>
  )
}

export default SearchContainer
