import { i18nInstance } from '@signifyd/components'
import { DeepLinkFactory, MENU_ICON } from '../DeepLinkDropdown.types'
import {
  encodedAgentConsoleSearchUrl,
  googleSearchLink,
} from '../DeepLinkConstants'

const getDeepLinksName: DeepLinkFactory = ({ seller, field }) => {
  const value = seller[field]

  return [
    {
      icon: MENU_ICON.SigLogoSmall,
      text: i18nInstance.t(
        'pages.caseReview.sellerDetails.deepLinks.sigSearch'
      ),
      url: encodedAgentConsoleSearchUrl(`"${value}"`),
    },
    {
      icon: MENU_ICON.GoogleSearchIcon,
      text: i18nInstance.t(
        'pages.caseReview.sellerDetails.deepLinks.googleSellerNameAddressSearch'
      ),
      url: `${googleSearchLink}${value} ${seller.address?.fullAddress}`,
    },
  ]
}

export default getDeepLinksName
