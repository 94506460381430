import { FC } from 'react'
import { Input, Row, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { Controller, Control, FieldErrorsImpl } from 'react-hook-form'
import moment from 'moment-timezone'
import { CreateClaimError } from '@signifyd/http'
import { DatePicker } from '@signifyd/components'
import { DATE_FORMAT } from 'core/constants'
import { useAdminSettings } from 'core/hooks/useAdminSettings'
import KeyValueDropdown from './KeyValueDropdown'
import styles from './ClaimReimbursementModal.less'
import { chargebackReasonOptions, shippingCarrierOptions } from './constants'
import CurrencyAmountInput from './CurrencyAmountInput'
import FileUploadDragger from './FileUploadDragger'
import { ClaimReimbursementFormSchemaType } from './ClaimReimbursementFormValidation'
import FormItemWrapper from './FormItemWrapper'

interface Props {
  investigationId: number
  control: Control<ClaimReimbursementFormSchemaType, unknown>
  errors: Partial<FieldErrorsImpl<ClaimReimbursementFormSchemaType>>
  apiErrors: CreateClaimError | null
}

const { TextArea } = Input

const ClaimReimbursementForm: FC<Props> = ({
  investigationId,
  control,
  errors,
  apiErrors,
}) => {
  const { t } = useTranslation()
  const translationPrefix = 'caseActions.reimbursement'

  const { data: adminSettings } = useAdminSettings()

  const chargebackCurrencyList =
    adminSettings?.billingPlanSettings.currencies.chargeback

  return (
    <Form layout="vertical" className={styles.reimbursementForm}>
      <Row gutter={16}>
        <FormItemWrapper
          span={12}
          labelText={t(`${translationPrefix}.trackingNumber.label`)}
          errorText={
            errors?.trackingNumber &&
            t(`${translationPrefix}.trackingNumber.error`)
          }
        >
          <Controller
            name="trackingNumber"
            control={control}
            render={({ field }) => (
              <Input
                placeholder={t(
                  `${translationPrefix}.trackingNumber.placeholder`
                )}
                data-test-id="trackingNumber"
                onChange={field.onChange}
                value={field.value}
              />
            )}
          />
        </FormItemWrapper>

        <FormItemWrapper
          span={12}
          labelText={t(`${translationPrefix}.shipper.label`)}
          errorText={errors?.shipper && t(`${translationPrefix}.shipper.error`)}
        >
          <Controller
            name="shipper"
            control={control}
            render={({ field }) => (
              <KeyValueDropdown
                showSearch
                style={{ width: 200 }}
                data-test-id="carrierSelect"
                placeholder={t(`${translationPrefix}.shipper.placeholder`)}
                name="carrier"
                options={shippingCarrierOptions}
                onChange={field.onChange}
                value={field.value}
              />
            )}
          />
        </FormItemWrapper>
      </Row>

      <Row gutter={16}>
        <FormItemWrapper
          span={12}
          labelText={t(`${translationPrefix}.chargebackRefId.label`)}
          helpText={t(`${translationPrefix}.chargebackRefId.helpText`)}
          errorText={
            errors?.chargebackRefId &&
            t(`${translationPrefix}.chargebackRefId.error`)
          }
        >
          <Controller
            name="chargebackRefId"
            control={control}
            render={({ field }) => (
              <Input
                placeholder={t(
                  `${translationPrefix}.chargebackRefId.placeholder`
                )}
                data-test-id="chargebackRefId"
                onChange={field.onChange}
                value={field.value}
              />
            )}
          />
        </FormItemWrapper>

        <FormItemWrapper
          span={12}
          labelText={t(`${translationPrefix}.issuerReportedDate.label`)}
          helpText={t(`${translationPrefix}.issuerReportedDate.helpText`)}
          errorText={
            errors?.issuerReportedDate &&
            t(`${translationPrefix}.issuerReportedDate.error`)
          }
        >
          <Controller
            name="issuerReportedDate"
            control={control}
            render={({ field }) => (
              <DatePicker
                data-test-id="issuerReportedDate"
                format={DATE_FORMAT.date}
                placeholder={t(
                  `${translationPrefix}.issuerReportedDate.placeholder`
                )}
                disabledDate={(current) => {
                  if (!current) {
                    return false
                  }

                  return current && current > moment().endOf('day') // Prevent future dates being selected
                }}
                onChange={(e) => field.onChange(e?.toISOString())}
              />
            )}
          />
        </FormItemWrapper>
      </Row>

      <Row gutter={16}>
        <FormItemWrapper
          span={12}
          labelText={t(`${translationPrefix}.processorReasonDescription.label`)}
          helpText={t(
            `${translationPrefix}.processorReasonDescription.helpText`
          )}
          errorText={
            errors?.processorReasonDescription &&
            t(`${translationPrefix}.processorReasonDescription.error`)
          }
        >
          <Controller
            name="processorReasonDescription"
            control={control}
            render={({ field }) => (
              <KeyValueDropdown
                showSearch
                name="chargeback-reason"
                data-test-id="chargebackReason"
                placeholder={t(
                  `${translationPrefix}.processorReasonDescription.placeholder`
                )}
                options={chargebackReasonOptions}
                onChange={field.onChange}
                value={field.value}
              />
            )}
          />
        </FormItemWrapper>
      </Row>

      <Row gutter={16}>
        <FormItemWrapper
          span={12}
          labelText={t(`${translationPrefix}.chargebackAmount.label`)}
          helpText={t(`${translationPrefix}.chargebackAmount.helpText`)}
          errorText={
            errors?.chargebackAmount &&
            t(`${translationPrefix}.chargebackAmount.error`)
          }
        >
          <Controller
            name="chargebackAmount"
            control={control}
            render={({ field }) => (
              <CurrencyAmountInput
                data-test-id="chargebackAmount"
                onChange={field.onChange}
                value={field.value}
                currencies={chargebackCurrencyList}
              />
            )}
          />
        </FormItemWrapper>

        <FormItemWrapper
          span={12}
          labelText={t(`${translationPrefix}.chargebackFees.label`)}
          helpText={t(`${translationPrefix}.chargebackFees.helpText`)}
          errorText={
            apiErrors?.errors?.chargebackFees?.[0] ||
            (errors?.chargebackFees &&
              t(`${translationPrefix}.chargebackFees.error`))
          }
        >
          <Controller
            name="chargebackFees"
            control={control}
            render={({ field }) => (
              <CurrencyAmountInput
                data-test-id="chargebackFees"
                onChange={field.onChange}
                value={field.value}
                currencies={chargebackCurrencyList}
              />
            )}
          />
        </FormItemWrapper>
      </Row>

      <Row>
        <FormItemWrapper
          span={24}
          labelText={t(`${translationPrefix}.chargebackNotice.label`)}
          helpText={t(`${translationPrefix}.chargebackNotice.helpText`)}
          errorText={
            errors?.chargebackNotice &&
            t(`${translationPrefix}.chargebackNotice.error`)
          }
          htmlFor="claimFormFileUploader"
        >
          <Controller
            name="chargebackNotice"
            control={control}
            render={({ field }) => (
              <FileUploadDragger
                investigationId={investigationId}
                onChange={(e) => field.onChange(e.fileList)}
              />
            )}
          />
        </FormItemWrapper>
      </Row>

      <Row>
        <FormItemWrapper
          span={24}
          labelText={t(`${translationPrefix}.comments.label`)}
        >
          <Controller
            name="comments"
            defaultValue=""
            control={control}
            render={({ field }) => (
              <TextArea
                autoSize={{ minRows: 3, maxRows: 5 }}
                data-test-id="chargebackComments"
                onChange={field.onChange}
                value={field.value}
              />
            )}
          />
        </FormItemWrapper>
      </Row>
    </Form>
  )
}

export default ClaimReimbursementForm
