import { FC, Fragment } from 'react'
import { Descriptions } from 'antd'
import { Text } from '@signifyd/components'
import { formatCurrencyNumber } from '@signifyd/utils'
import { InvestigationInfo } from '@signifyd/http'
import { useTranslation } from 'react-i18next'
import { displayShippingMethod } from 'core/utils/textTransforms'
import NoInformation from 'core/components/NoInformation'
import ShippingSummaryLabel from './ShippingSummaryLabel'
import styles from './ShippingSummary.less'

const { Item: DescriptionItem } = Descriptions

interface Props {
  caseDetails: InvestigationInfo
}

export const ShippingSummary: FC<Props> = ({ caseDetails }) => {
  const { t } = useTranslation()
  const sectionName = t('caseSummary.shipping.title')

  if (caseDetails?.shipments.length === 0) {
    return (
      <>
        <Descriptions title={sectionName} />
        <NoInformation />
      </>
    )
  }

  return (
    <>
      <Descriptions
        title={
          <ShippingSummaryLabel
            sectionName={sectionName}
            shipments={caseDetails?.shipments}
          />
        }
        colon={false}
        size="small"
      >
        {/* Shipping Header */}
        <DescriptionItem
          label={
            <Text className={styles.shippingHeader}>
              {t('caseSummary.shipping.title')}
            </Text>
          }
        >
          <Text className={styles.shippingHeader}>
            {t('caseSummary.shipping.price')}
          </Text>
        </DescriptionItem>

        {/* Shipping Details */}
        {caseDetails?.shipments.map((shipment, key) => (
          <Fragment key={key}>
            <DescriptionItem
              label={
                <span className={styles.methodLabel}>
                  {displayShippingMethod(shipment)}
                </span>
              }
              data-test-id="shippingDetails"
            >
              {`${caseDetails.currency || 'USD'} ${formatCurrencyNumber(
                shipment.shippingPrice || 0
              )}`}
            </DescriptionItem>
          </Fragment>
        ))}
      </Descriptions>
    </>
  )
}
export default ShippingSummary
