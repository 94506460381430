export default {
  user: {
    notAuthorized: 'Not authorized',
    uiPrefsFailure:
      'Apologies — our system failed momentarily. We recommend you refresh the page.',
    userTeamsFailure:
      'Apologies — our system failed momentarily. We recommend you refresh the page.',
  },
  claim: {
    apiSuccess: 'Success! Order is submitted for reimbursement.',
    apiFailure: 'Apologies — verify submission and try again',
  },
  fulfillment: {
    apiSuccess: 'Success! Updated information submitted.',
    apiFailure: 'Apologies — verify submission and try again',
  },
  search: {
    searchFailure: 'Apologies — verify search and try again',
    searchTermsExceededError:
      'Maximum number of searchable terms exceeded. Reduce selection to 1000 or less.',
    populateFailure: "Something's not right — verify search and try again",
    searchLimitNotification: {
      message: 'Limited results',
      description:
        'Too many orders to display. To view all available orders, use the download button below.',
    },
    searchLimitNotificationAdmin: {
      message: 'Limited results',
      description:
        'Too many results to show. To display results, we recommend refining the search further.',
    },
  },
  languages: {
    languageChangeNotification: {
      message: 'Language updated',
      description:
        'Your language preference was also saved for your next visit — only currently available for Console and Decision Center.',
    },
  },
  policy: {
    getPolicyDetailsFailure:
      'Apologies — our system is unable to retrieve policies at this time. Try again.',
  },
  savedFilter: {
    createFailure: 'Apologies — verify name given and try again',
    deleteFailure: 'Apologies — try removing your saved search again',
  },
  case: {
    getCaseFailure:
      'Apologies — our system is unable to retrieve policies at this time. Try again.',
    getCaseEntriesFailure:
      'Apologies — our system is unable to retrieve policies at this time. Try again.',
    getCaseAnalysisFailure:
      'Apologies — our system is unable to retrieve policies at this time. Try again.',
    getCaseOrderFailure:
      'Apologies — our system is unable to retrieve policies at this time. Try again.',
    getCaseDetailsFailure:
      'Case Details failed to load. Please refresh to try again.',
  },
  notes: {
    getNoteFailure: 'Case notes failed to load. Refresh to try again.',
    addNoteFailure: 'Apologies — try adding note again',
    apiNoteDeleteSuccess: 'Note deleted',
    deleteNoteFailure: 'Apologies — try deleting note again',
  },
  caseActions: {
    updateAddress: {
      testCase: "Address can't be updated because it's a test order",
      trialCase: "Address can't be updated because you're on a trial",
      hasClaim: "Address can't be updated because the order has a claim",
      tooOld: 'Order older than {{days}} days and cannot be updated',
      notGuaranteed: 'To update the address, first submit for guarantee',
      alreadyUpdated: 'Order already updated or resubmitted',
      apiSuccess: 'Success! Order address updated.',
      apiFailure:
        'Apologies — verify the information provided and try again. If the problem persists please contact support.',
      notAllowed: 'Order not eligible to update the address',
    },
    submitGuarantee: {
      tooOld:
        "Order can't be submitted for guarantee because it's older than {{days}} days",
      requiresDisposition:
        'Please mark this order as good before submitting for guarantee ',
      apiSuccess: 'Success! Order address submitted for guarantee.',
      notEligible: 'Order ineligible for guarantee',
      apiFailure: 'Apologies — try changing the order status again',
    },
    resubmitGuarantee: {
      apiSuccess: 'Success! The order has been resubmitted for guarantee.',
      hasClaim: 'Order cannot be resubmitted when the order has a claim',
      apiNoteFailure: 'Failed trying to add notes',
      apiFailure: 'Failed trying update the order status',
      reason: 'Order ineligible to be resubmitted for guarantee',
      reason_TEST_CASE: 'Test orders cannot be resubmitted for guarantee',
      reason_TRIAL_CASE:
        'Order submitted during trial and cannot be resubmitted for guarantee',
      reason_CASE_OUTDATED:
        'Order is too old and cannot be resubmitted for guarantee',
      reason_GUARANTEED_PAYMENTS_CASE_EXISTS:
        'Order is already guaranteed and cannot be resubmitted for guarantee',
      reason_INELIGIBLE_GUARANTEE_STATUS:
        'Only orders that have been declined may be resubmitted for guarantee',
      reason_ABOVE_MAX_ORDER_AMOUNT:
        'Order amount is too high and cannot be resubmitted for guarantee',
      reason_BELOW_MIN_ORDER_AMOUNT:
        'Order amount is too low and cannot be resubmitted for guarantee',
      reason_EMPTY_ORDER_AMOUNT: `Orders without an amount cannot be resubmitted for guarantee`,
      reason_DUPLICATE_ORDER_EXISTS:
        'Order was already guaranteed in previous investigation',
      reason_TOO_MANY_UPDATES:
        'Order has too many decisions and cannot be resubmitted for guarantee',
      reason_SANCTIONED_COUNTRY:
        'Order IP geolocation or delivery country is restricted and cannot be resubmitted for guarantee',
      reason_INELIGIBLE_CUSTOMER:
        'Account ineligible to resubmit orders for guarantee',
      reason_INELIGIBLE_PARTNER:
        'Partner account ineligible to resubmit orders for guarantee',
    },
    claimReimbursement: {
      testOrder: 'Test orders are not covered by Signifyd for reimbursement',
      merchantPolicyRule:
        'Order not covered by Signifyd for reimbursement because it was accepted by a merchant policy. Go to Activity for the merchant policy used to accept this order.',
      notApproved:
        "Order can't be reimbursed because it wasn't approved by Signifyd",
      notAllowed: "Oops — you're not allowed to perform this action",
    },
    orderFeedback: {
      hasClaim:
        'Feedback for this order is not accepted because it has a claim',
      apiSuccess: 'Success! Feedback on the order was added',
      apiFailure: 'Apologies — try submitting order feedback again',
    },
    caseStatus: {
      apiSuccess: 'Success! Status of this order changed.',
      apiFailure: 'Failed trying update the order status',
    },
  },
}
