import moment from 'moment-timezone'
import { ArkOwlEmailHost, ArkOwlDataBreach } from '@signifyd/http'
import { map, filter, min } from 'lodash'
import {
  ArkOwlEmailHostState,
  ArkOwlOldestBreach,
} from '../types/case.arkOwl.types'

export function getArkOwlEmailHost(
  emailHost?: ArkOwlEmailHost
): ArkOwlEmailHostState | undefined {
  if (!emailHost) {
    return undefined
  }

  return {
    id: emailHost.id,
    registrar: emailHost.registrar,
    registrant: emailHost.registrant?.organization,
    registered: emailHost.registered,
    expires: emailHost.expires,
    registeredTo: emailHost.registrant?.name,
    street: emailHost.registrant?.street1,
    city: emailHost.registrant?.city,
    state: emailHost.registrant?.state,
    postalCode: emailHost.registrant?.postal_code,
    email: emailHost.registrant?.email,
    phone: emailHost.registrant?.phone,
    fax: emailHost.registrant?.fax,
  }
}

export function getArkOwlOldestBreach(
  arkOwlBreaches: Array<ArkOwlDataBreach>
): ArkOwlOldestBreach | undefined {
  const oldestTimestamp = min(map(arkOwlBreaches, 'breach_date'))
  const [oldestBreach] = filter(
    arkOwlBreaches,
    (breach) => breach.breach_date === oldestTimestamp
  )

  return oldestBreach
    ? {
        name: oldestBreach.name,
        date: `${moment(moment.unix(oldestBreach.breach_date)).format(
          'MMM Do, YYYY'
        )}`,
      }
    : undefined
}
