import { FC, RefObject } from 'react'
import { Form } from '@ant-design/compatible'
import { Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { INV_GUARANTEE_DISPOSITION, SEARCH_FIELD } from '@signifyd/http'
import { FilterState } from 'store/search'

interface Props {
  value: Array<INV_GUARANTEE_DISPOSITION>
  updateFilterFn: (update: Partial<FilterState>) => void
  scrollRef?: RefObject<HTMLDivElement>
}

const excludedValues = [
  INV_GUARANTEE_DISPOSITION.ESCALATED,
  INV_GUARANTEE_DISPOSITION.PROCESSING_REGUARANTEE,
  INV_GUARANTEE_DISPOSITION.UNDEFINED_DISPOSITION,
] as const
type ExcludedDispoition = (typeof excludedValues)[number]

export const guaranteeDispositionOptionValues = Object.values(
  INV_GUARANTEE_DISPOSITION
).filter(
  (disposition) => !excludedValues.includes(disposition as ExcludedDispoition)
) as Array<Exclude<INV_GUARANTEE_DISPOSITION, ExcludedDispoition>>

const GuaranteeStatusSelectFilter: FC<Props> = ({
  value,
  updateFilterFn,
  scrollRef,
}) => {
  const { t } = useTranslation()

  const handleChange = (value: Array<INV_GUARANTEE_DISPOSITION>): void => {
    updateFilterFn({
      [SEARCH_FIELD.guaranteeDisposition]: value,
    })
  }

  const dedupedOptions = [...new Set(guaranteeDispositionOptionValues)]

  return (
    <Form.Item label={t('filters.guaranteeStatus.label')}>
      <Select
        mode="multiple"
        allowClear
        placeholder={t('filters.placeholder.selectOneOrMore')}
        value={value}
        onChange={handleChange}
        data-analytics-id="filter-guarantee-select"
        data-test-id="filterGuaranteeSelect"
        getPopupContainer={(triggerNode: HTMLElement) => {
          return scrollRef?.current ?? (triggerNode.parentNode as HTMLElement)
        }}
        options={[
          ...dedupedOptions.map((option) => ({
            key: option,
            label: t(`filters.guaranteeStatus.${option}`),
            value: option,
            'data-analytics-id': `filter-guarantee-selected-${option}`,
            'data-test-id': `filterGuaranteeSelected-${option}`,
          })),
          /* Unrequested Guarantee Filter */
          {
            label: t('filters.guaranteeStatus.NULL'),
            value: 'NULL',
            'data-analytics-id': 'filter-guarantee-selected-null',
            'data-test-id': 'filterGuaranteeSelectedNull',
          },
        ]}
      />
    </Form.Item>
  )
}

export default GuaranteeStatusSelectFilter
