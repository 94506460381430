import { Statistic } from 'antd'
import { CheckCircleFilled } from '@ant-design/icons'
import { FC } from 'react'
import { joinClassNames } from '@signifyd/utils'
import { Text } from '@signifyd/components'
import styles from './SubTotal.less'

interface Props {
  value: string | number
  title: JSX.Element
  onClick: () => void
  isSelected: boolean
  icon?: JSX.Element
  id: string
}

const SubTotal: FC<Props> = ({
  icon,
  title,
  value,
  onClick,
  isSelected,
  id,
}) => (
  <div
    className={joinClassNames([styles.item, isSelected && styles.active])}
    onClick={onClick}
    data-test-id={`aggregatePanel${id}`}
    data-analytics-id={`aggregate-panel-${id}`}
  >
    <Statistic
      className={styles.statistic}
      value={value}
      title={
        <Text className={styles.title}>
          {icon} {title}
        </Text>
      }
      valueRender={(value) => (
        <div className={styles.value} data-test-id={`${id}Value`}>
          {value}
          {isSelected && <CheckCircleFilled className={styles.icon} />}
        </div>
      )}
    />
  </div>
)

export default SubTotal
