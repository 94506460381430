import { Popover } from 'antd'
import { Return } from '@signifyd/http'
import { ReturnCheckpointMiniIcon } from '@signifyd/components'
import styles from './ReturnTag.less'
import ReturnWithPolicy from './ReturnWithPolicy'
import NoMatchTag from '../NoMatchTag'

interface Props {
  returns: Array<Return>
}

const ReturnTag: React.FC<Props> = ({ returns }) => {
  if (!returns.length) {
    return null
  }

  const [firstReturn, ...rest] = returns

  return (
    <div className={styles.returnTagWrapper}>
      {firstReturn.decision ? (
        <ReturnWithPolicy decision={firstReturn.decision} />
      ) : (
        <NoMatchTag icon={ReturnCheckpointMiniIcon} />
      )}
      <Popover
        content={
          <>
            {rest.map((returnItem) =>
              returnItem.decision ? (
                <ReturnWithPolicy
                  key={returnItem.returnId}
                  decision={returnItem.decision}
                />
              ) : (
                <NoMatchTag
                  key={returnItem.returnId}
                  icon={ReturnCheckpointMiniIcon}
                />
              )
            )}
          </>
        }
      >
        {rest.length > 0 && `+ ${rest.length}`}
      </Popover>
    </div>
  )
}

export default ReturnTag
