export const savedFilterKey = 'savedFilters'

export const STATIC_NOTES = {
  CASE_RE_REVIEW: 'CASE_RE_REVIEW',
  RE_REVIEW_HEADING: 'Case Re-Review Reason:',
  CHARGEBACK_PENDING: 'CHARGEBACK_PENDING',
  GUARANTEE_BULK_RULE: 'GUARANTEE_BULK_RULE',
  ORDER_REVIEW_CHECKLIST: 'OR_CL',
  CHARGEBACK_SUBMITTED_HEADING: 'Chargeback Submitted',
}

export enum CUSTOMER_APPROVELIST_DECISION_REASONS {
  APPROVE_APPROVELIST_DEL_ADDRESS = 'APPROVE_WHITELIST_DEL_ADDRESS',
  APPROVE_APPROVELIST_CONFIRM_EMAIL = 'APPROVE_WHITELIST_CONFIRM_EMAIL',
}
