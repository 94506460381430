import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@signifyd/components'
import { Event } from '@signifyd/http'
import { Card, Checkbox, Flex, Timeline } from 'antd'
import { EyeOutlined } from '@ant-design/icons'
import EventRecord from '../EventRecord/EventRecord'
import styles from '../EventViewer.less'
import { tagColors } from '../event.utils'

interface Props {
  events?: Array<Event>
  selectedEvent: Event | null
  setSelectedEvent: (event: Event | null) => void
  isComparingEvents: boolean
  compareEvents: Array<Event>
  setIsComparingEvents: (isComparingEvents: boolean) => void
  setCompareEvents: (events: Array<Event>) => void
}

const EventTimeline: FC<Props> = ({
  events,
  selectedEvent,
  setSelectedEvent,
  isComparingEvents,
  setIsComparingEvents,
  compareEvents,
  setCompareEvents,
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'eventViewer' })
  const [showTags, setShowTags] = useState(false)

  if (!events) {
    return <Text>{t('noEvents')}</Text>
  }

  const addInvestigationForComparison = (event: Event): void => {
    const isSelected = compareEvents.findIndex(
      (activeEvent) =>
        activeEvent.investigationEventId.uuid ===
        event.investigationEventId.uuid
    )

    if (isSelected === -1) {
      setCompareEvents([...compareEvents, event])
    } else {
      setCompareEvents(compareEvents.toSpliced(isSelected, 1))
    }
  }

  const checkboxIsEnabled = (event: Event): boolean => {
    const eventIsSelected = compareEvents.find(
      (activeEvent) =>
        activeEvent.investigationEventId.uuid ===
        event.investigationEventId.uuid
    )

    return compareEvents.length >= 2 && !eventIsSelected
  }

  const getEventIndicator = (event: Event): JSX.Element | undefined => {
    if (!isComparingEvents) {
      const activeEvent =
        event.investigationEventId.uuid ===
        selectedEvent?.investigationEventId.uuid

      return activeEvent ? <EyeOutlined size={40} /> : undefined
    }

    return (
      <Checkbox
        data-test-id={`compareCheckbox-${event.investigationEventId.uuid}`}
        onClick={() => addInvestigationForComparison(event)}
        disabled={checkboxIsEnabled(event)}
        checked={compareEvents.some(
          (activeEvent) =>
            activeEvent.investigationEventId.uuid ===
            event.investigationEventId.uuid
        )}
      />
    )
  }

  const items = events?.map((event) => {
    return {
      color: tagColors[event.tags[0]],
      dot: getEventIndicator(event),
      children: (
        <EventRecord
          key={event.investigationEventId.uuid}
          event={event}
          selectedEvent={selectedEvent}
          setSelectedEvent={setSelectedEvent}
          showTags={showTags}
          isComparingEvents={isComparingEvents}
        />
      ),
    }
  })

  return (
    <Card
      size="small"
      title={
        <Flex justify="space-between" align="center">
          <Text size="lg" weight="semibold">
            {t('events')}
          </Text>

          <div>
            <Checkbox
              data-test-id="compareCheckbox"
              onChange={() => setIsComparingEvents(!isComparingEvents)}
            >
              {t('compare')}
            </Checkbox>

            <Checkbox
              data-test-id="tagCheckbox"
              onChange={() => setShowTags(!showTags)}
            >
              {t('tags')}
            </Checkbox>
          </div>
        </Flex>
      }
    >
      <Timeline className={styles.timeline} items={items} />
    </Card>
  )
}

export default EventTimeline
