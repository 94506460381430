import { FC, memo } from 'react'
import { useStoreState } from 'store'
import { DATE_FORMAT } from 'core/constants'
import { formatDateTimezone } from 'core/utils/date.utils'

interface Props {
  date?: string
}

const FormattedDate: FC<Props> = ({ date }) => {
  const timeZone = useStoreState((state) => state.user.timeZone.name)

  return date ? (
    <>{formatDateTimezone({ date, timeZone, format: DATE_FORMAT.dateTime })}</>
  ) : null
}

export default memo(FormattedDate)
