import { FC } from 'react'
import { Form } from '@ant-design/compatible'
import { Col } from 'antd'
import cx from 'classnames'

import styles from './FormItemWrapper.less'

export interface FormInputWrapperProps {
  span?: number
  labelText?: string
  errorText?: string
  required?: boolean
}

export const FormInputWrapper: FC<FormInputWrapperProps> = ({
  span,
  labelText,
  errorText,
  children,
  required,
}) => (
  <Col span={span} className={styles.col}>
    <Form.Item
      label={
        labelText && (
          <>
            {labelText}
            {required && <span className={styles.required}> *</span>}
          </>
        )
      }
      className={cx([styles.formItem, !!errorText && 'has-error'])}
    >
      {children}
      {!!errorText && <div className="ant-form-explain">{errorText}</div>}
    </Form.Item>
  </Col>
)

export default FormInputWrapper
