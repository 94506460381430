import store from './store'
import search from './search'
import filters from './filters'
import pages from './pages/index'
import caseActivity from './caseActivity'
import caseActions from './caseActions'
import caseIntelligence from './caseIntelligence'
import caseSummary from './caseSummary'
import claimsBanner from './claimsBanner'
import googleMaps from './googleMaps'
import onboardingScreen from './onboardingScreen'
import policyTag from './policyTag'
import pluginUpdatedNotification from './pluginUpdatedNotification'
import guaranteeTag from './guaranteeTag'
import recommendationTag from './recommendationTag'
import returnPolicy from './returnPolicy'
import returnTag from './returnTag'
import returnAttemptReason from './returnAttemptReason'
import refundMethod from './refundMethod'
import checkpointAction from './checkpointAction'
import errorBoundaries from './errorBoundaries'
import teamSelect from './teamSelect'
import orderAttributeLists from './orderAttributeLists'
import ekataIntelligence from './ekataIntelligence'
import airline from './airline'
import aggregates from './aggregates'
import eventViewer from './eventViewer'

export default {
  claimsBanner,
  errorBoundaries,
  store,
  search,
  filters,
  pages,
  caseActions,
  caseActivity,
  caseIntelligence,
  caseSummary,
  googleMaps,
  onboardingScreen,
  policyTag,
  pluginUpdatedNotification,
  guaranteeTag,
  recommendationTag,
  returnAttemptReason,
  refundMethod,
  checkpointAction,
  returnPolicy,
  returnTag,
  teamSelect,
  orderAttributeLists,
  ekataIntelligence,
  airline,
  aggregates,
  eventViewer,
}
