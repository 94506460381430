export default {
  travelDetails: 'Travel details',
  tripDetails: 'Airline trip',
  passengers: 'Passengers',
  ticketNumber: 'Ticket number',
  passengerName: 'Passenger name',
  email: 'Email',
  ticketStatus: 'Ticket status',
  ancillaries: 'Ancillaries',
  membershipLast4: 'Membership last 4',
  idDocument: 'ID document',
  idOrigin: 'ID origin',
  idLast4: 'ID last 4',
  idExpiration: 'ID Expiration',
  routeCode: 'Route Code',
  departure: 'Departure',
  arrival: 'Arrival',
  bookingClass: 'Booking Class',
  bookingClass_ECONOMY: 'Economy',
  bookingClass_PREMIUM_ECONOMY: 'Premium economy',
  bookingClass_BUSINESS: 'Business',
  bookingClass_FIRST_CLASS: 'First class',
  airlineTrip: '{{departurePortCode}} to {{arrivalPortCode}}',
  ancillaryPurchases: 'Ancillary purchases include: {{ancillaries}}',
}
