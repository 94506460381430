import { FC } from 'react'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { Col, Form } from 'antd'
import cx from 'classnames'
import { Text } from '@signifyd/components/src/components/Typography'
import styles from './FormItemWrapper.less'

export interface FormInputWrapperProps {
  span: number
  labelText: string
  helpText?: string
  errorText?: string
  htmlFor?: string
}

export const FormInputWrapper: FC<FormInputWrapperProps> = ({
  span,
  labelText,
  helpText,
  errorText,
  htmlFor,
  children,
}) => (
  <Col span={span}>
    <Form.Item
      tooltip={
        helpText && {
          title: helpText,
          icon: <QuestionCircleOutlined />,
        }
      }
      label={labelText}
      className={cx([styles.formItem, !!errorText && 'has-error'])}
      htmlFor={htmlFor}
    >
      {children}
      {!!errorText && (
        <Text type="danger" className={styles.formErrorText}>
          {errorText}
        </Text>
      )}
    </Form.Item>
  </Col>
)

export default FormInputWrapper
