import { FC, ReactNode, useContext } from 'react'
import { Button } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import PageSizeContext, { PAGE_SIZE } from 'core/components/PageSizeContext'

interface Props extends ButtonProps {
  text: string
  buttonClass?: string
  Icon: ReactNode
}

const ResponsiveIconButton: FC<Props> = ({
  onClick,
  buttonClass,
  Icon,
  text,
  loading,
  ...rest
}) => {
  const pageSize = useContext(PageSizeContext)
  const isLargeSize = pageSize === PAGE_SIZE.LG_AND_LARGER

  return (
    <Button
      type="link"
      className={buttonClass}
      onClick={onClick}
      loading={loading}
      {...rest}
    >
      {!loading && Icon}
      {isLargeSize && text}
    </Button>
  )
}

export default ResponsiveIconButton
