export default {
  title: 'Account',
  number: 'Number',
  orderCount: 'Aggregate order count',
  orderAmount: 'Aggregate order amount',
  createdDate: 'Creation date',
  lastUpdated: 'Last update date',
  lastOrder: 'Last order ID',
  membership: 'Membership ID',
}
