import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Descriptions } from 'antd'
import { CaseEntriesResolvedState } from 'store/currentCase/types/case.entries.types'
import CopyWrapper from 'core/components/CopyWrapper'
import NoInformation from 'core/components/NoInformation'

const { Item: DescriptionItem } = Descriptions

interface Props {
  caseEntries: CaseEntriesResolvedState
}

const Organization: FC<Props> = ({ caseEntries }) => {
  const { t } = useTranslation()
  const { asnCarrier, ipRegistree, creditCardIssuer } = caseEntries

  const noInfo = !asnCarrier && !ipRegistree && !creditCardIssuer

  return (
    <>
      <Descriptions
        title={t('pages.caseReview.details.organization')}
        colon={false}
        size="small"
      >
        {asnCarrier?.entityName && (
          <DescriptionItem label={t('pages.caseReview.details.ASNCarrier')}>
            <CopyWrapper text={asnCarrier.entityName}>
              {asnCarrier.entityName}
            </CopyWrapper>
          </DescriptionItem>
        )}
        {ipRegistree?.entityName && (
          <DescriptionItem label={t('pages.caseReview.details.ipRegistree')}>
            <CopyWrapper text={ipRegistree.entityName}>
              {ipRegistree.entityName}
            </CopyWrapper>
          </DescriptionItem>
        )}
        {creditCardIssuer?.entityName && (
          <DescriptionItem
            label={t('pages.caseReview.details.creditCardIssuer')}
          >
            <CopyWrapper text={creditCardIssuer.entityName}>
              {creditCardIssuer.entityName}
            </CopyWrapper>
          </DescriptionItem>
        )}
      </Descriptions>
      {noInfo && <NoInformation />}
    </>
  )
}

export default Organization
