import {
  listChargebackSettings,
  CLAIM_PAYOUT_PREFERENCE,
  ChargebackSettings,
} from '@signifyd/http'
import { useQuery, UseQueryResult } from '@tanstack/react-query'

export interface ChargebackSettingsPayload {
  isPostRepresentment: boolean
  data: ChargebackSettings
}

export const useChargebackSettings = (
  teamId?: number
): UseQueryResult<ChargebackSettingsPayload> => {
  return useQuery({
    queryKey: ['chargebackSettings', teamId],
    queryFn: async () => {
      const { data } = await listChargebackSettings(teamId!)

      const isPostRepresentment =
        data.claimPayoutPreference ===
        CLAIM_PAYOUT_PREFERENCE.POST_REPRESENTMENT

      return { data, isPostRepresentment }
    },
    enabled: !!teamId,
  })
}
