import { action, thunk } from 'easy-peasy'
import { getCaseAnalysis } from '@signifyd/http'
import { CaseAnalysisModel, ANALYSIS_STATE } from './types/case.analysis.types'

const caseAnalysisModel: CaseAnalysisModel = {
  // State
  analysisData: null,
  analysisState: ANALYSIS_STATE.ERROR,

  // Getters / Setters
  setCaseAnalysis: action((state, payload) => {
    state.analysisData = payload.analysisData
    state.analysisState = payload.analysisState
  }),
  clearCaseAnalysis: action((state) => {
    state.analysisData = null
    state.analysisState = ANALYSIS_STATE.ERROR
  }),

  // Http Stuff
  getCaseAnalysis: thunk((actions, caseId) => {
    return getCaseAnalysis(caseId).then(({ status, data }) => {
      if (status === 200) {
        return actions.setCaseAnalysis({
          analysisState: ANALYSIS_STATE.PROCESSED,
          analysisData: data,
        })
      }

      if (status === 202) {
        return actions.setCaseAnalysis({
          analysisState: ANALYSIS_STATE.PROCESSING,
          analysisData: null,
        })
      }

      if (status === 204) {
        return actions.setCaseAnalysis({
          analysisState: ANALYSIS_STATE.POLICY_ONLY,
          analysisData: null,
        })
      }

      return actions.setCaseAnalysis({
        analysisState: ANALYSIS_STATE.ERROR,
        analysisData: null,
      })
    })
  }),
}

export default caseAnalysisModel
