import { action, thunk, thunkOn } from 'easy-peasy'
import { message, notification } from 'antd'
import {
  getInvestigationSearch,
  INV_SEARCH_VIEW,
  SEARCH_FIELD,
} from '@signifyd/http'
import { i18nInstance } from '@signifyd/components'
import { antSort2SigSort } from 'core/utils/antSort2SigSort'
import invSearchQueryBuilder from 'core/utils/invSearchQueryBuilder'
import { stripDateAndEncode } from 'core/utils/stripDateAndEncode'
import { encodeUrlHash, decodeUrlHash } from 'core/utils/urlEncoder'
import { getDefaultDateRange } from 'core/utils/date.utils'
import { SearchResultsModel, SearchFilterState } from './types'

const searchModel: SearchResultsModel = {
  // State
  results: [],
  searching: false,
  totalResults: null,
  currentPage: 1,
  pageSize: 25,

  // Getters & Setters
  setSearchResults: action((state, investigations) => {
    state.results = investigations
  }),

  setSearchingStatus: action((state, payload) => {
    state.searching = payload
  }),

  setTotalResults: action((state, payload) => {
    state.totalResults = payload
  }),

  setCurrentPage: action((state, payload) => {
    state.currentPage = payload
  }),

  setPageSize: action((state, payload) => {
    state.pageSize = payload
  }),

  updateSearchResult: action((state, payload) => {
    state.results = state.results.map((result) => {
      if (result.investigationId === payload.investigationId) {
        return payload
      }

      return result
    })
  }),

  searchCasesWithSavedFilters: thunk((actions, { navigate }, { getState }) => {
    const { searchTerm, filters, sort, savedFilters } = getState()
    const hash = stripDateAndEncode(searchTerm, filters, sort)
    const foundFilter = Object.values(savedFilters).find((filter) => {
      return filter.searchHash === hash
    })

    actions.searchCases({ savedFilterKey: foundFilter?.title, navigate })
  }),

  searchCases: thunk(
    (
      actions,
      { quickSearchKey, saveAs, savedFilterKey, navigate },
      { getState }
    ) => {
      const { searchTerm, filters, sort } = getState()
      // TODO RTS-2713 temporary fix before going live, save correct filters
      if (saveAs) {
        const hash = stripDateAndEncode(searchTerm, filters, sort)

        actions.createSavedFilter({ name: saveAs.trim(), searchHash: hash })
      }

      const encodedHash =
        quickSearchKey ||
        savedFilterKey ||
        encodeUrlHash<SearchFilterState>({
          searchTerm,
          filters,
          sort,
        })

      // This is logic to ensure you can perform the same search consecutively
      const newSearchPath = `/orders/search/${encodedHash}`
      if (window.location.pathname === newSearchPath) {
        actions.populateAndSearch(encodedHash)
      } else {
        navigate(newSearchPath, { replace: true })
      }

      return encodedHash
    }
  ),

  // Thunks: Http Stuff
  populateAndSearch: thunk((actions, searchString, { getState }) => {
    try {
      const savedFilterMatch = getState().getSavedFilterMatch(searchString)
      const savedFilter =
        savedFilterMatch.selectedSavedFilter ||
        savedFilterMatch.selectedQuickView

      const decodedState = decodeUrlHash<SearchFilterState>(
        savedFilter ? savedFilter.searchHash : searchString
      )

      actions.populateState({
        ...decodedState,
        ...savedFilterMatch,
      })
      actions.getSearchResults()
    } catch {
      message.error(i18nInstance.t('store.search.populateFailure'))
    }
  }),

  getSearchResults: thunk((actions, _, { getState, getStoreState }) => {
    const { searchTerm, filters, sort, pageSize, currentPage } = getState()
    const { currentTeams, currentUser } = getStoreState().user

    const searchLimitNotificationKey = currentUser?.isAdmin
      ? 'searchLimitNotificationAdmin'
      : 'searchLimitNotification'

    try {
      const delimiter = searchTerm.includes(' ') ? ' ' : ','
      const splitSearch = searchTerm.split(delimiter)

      const ELASTIC_SEARCH_TERM_CAP = 1000

      if (splitSearch.length >= ELASTIC_SEARCH_TERM_CAP) {
        message.error(i18nInstance.t('store.search.searchTermsExceededError'))

        return Promise.reject(
          new Error(i18nInstance.t('store.search.searchTermsExceededError'))
        )
      }
    } catch {
      message.error(i18nInstance.t('store.search.searchFailure'))
    }

    const dateFilters = {
      ...filters[SEARCH_FIELD.normalizedPurchaseCreatedAt],
    }

    const defaultDateRange = getDefaultDateRange()

    if (dateFilters.min === null) {
      dateFilters.min = defaultDateRange.min
    }
    if (dateFilters.max === null) {
      dateFilters.max = defaultDateRange.max
    }

    const searchQuery = invSearchQueryBuilder({
      searchTerm,
      filters: {
        ...filters,
        [SEARCH_FIELD.normalizedPurchaseCreatedAt]: dateFilters,
        [SEARCH_FIELD.teamId]: currentTeams,
      },
      sort: antSort2SigSort(sort),
      pagination: { currentPage, size: pageSize },
      view: INV_SEARCH_VIEW.INVESTIGATION_SUMMARY,
    })

    actions.setSearchResults([])

    actions.setSearchingStatus(true)

    const searchAndPopulateStore = (): Promise<void> =>
      getInvestigationSearch(searchQuery).then(
        ({ data: { totalResults, investigations } }) => {
          if (investigations.length) {
            actions.setSearchResults(investigations)
          }

          actions.setTotalResults(totalResults)

          // UI only gets the max of 10000 to display to the user due to elastic limits, if it is above the limit, show a user warning
          if (totalResults >= 10_000) {
            notification.open({
              key: searchLimitNotificationKey,
              message: i18nInstance.t(
                `store.search.${searchLimitNotificationKey}.message`
              ),
              description: i18nInstance.t(
                `store.search.${searchLimitNotificationKey}.description`
              ),
              duration: 0,
              // top: 80, // This is to lower the notification so it is not all the way in the top of the DOM
            })
          } else {
            notification.destroy(searchLimitNotificationKey)
          } // Close notification if search limit is within limit
        }
      )

    return searchAndPopulateStore()
      .catch(() => {
        message.error(i18nInstance.t('store.search.searchFailure'))
      })
      .finally(() => actions.setSearchingStatus(false))
  }),

  getAndUpdateSearchResult: thunk((actions, caseId) => {
    return getInvestigationSearch(
      invSearchQueryBuilder({
        filters: { [SEARCH_FIELD.investigationId]: caseId },
        view: INV_SEARCH_VIEW.INVESTIGATION,
      })
    )
      .then(({ data: { investigations } }) => {
        actions.updateSearchResult(investigations[0])
      })
      .catch(() => {
        message.error(i18nInstance.t('store.search.searchFailure'))
      })
  }),

  onSort: thunkOn(
    (actions) => actions.setSortOrder,
    (actions) => actions.getSearchResults()
  ),
}

export default searchModel
