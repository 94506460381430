import { FC } from 'react'
import SubTotal from './SubTotal'
import styles from './AggregateContainer.less'

export interface SubTotal {
  value: number | string
  title: JSX.Element
  onClick: () => void
  isSelected: boolean
  icon?: JSX.Element
  id: string
}

interface Props {
  subTotals: Array<SubTotal>
}

const AggregateContainer: FC<Props> = ({ subTotals }) => (
  <div className={styles.aggregates}>
    {subTotals.map(({ icon, title, value, onClick, isSelected, id }) => (
      <SubTotal
        key={id}
        icon={icon}
        title={title}
        value={value}
        isSelected={isSelected}
        onClick={onClick}
        id={id}
      />
    ))}
  </div>
)

export default AggregateContainer
