import { action } from 'easy-peasy'
import { SEARCH_FIELD } from '@signifyd/http'
import { merge, cloneDeep } from 'lodash'
import { SortState, SearchFilterModel, SearchFilterState } from './types'

export const defaultSearchFilters = {
  [SEARCH_FIELD.normalizedPurchaseCreatedAt]: {
    min: null,
    max: null,
  },
  [SEARCH_FIELD.orderTotalAmount]: { min: null, max: null },
  [SEARCH_FIELD.guaranteeDisposition]: [],
  [SEARCH_FIELD.signifydScore]: { min: null, max: null },
  [SEARCH_FIELD.claimDisposition]: [],
  [SEARCH_FIELD.claimStatus]: [],
  [SEARCH_FIELD.caseStatus]: [],
  [SEARCH_FIELD.caseType]: [],
  [SEARCH_FIELD.investigationReviewDisposition]: [],
  [SEARCH_FIELD.authorizationGatewayStatus]: [],
  [SEARCH_FIELD.isTestInvestigation]: [],
  [SEARCH_FIELD.guaranteeRecommendedAction]: [],
  [SEARCH_FIELD.shippingMethod]: [],
  [SEARCH_FIELD.recommendedAction]: [],
  [SEARCH_FIELD.returnDecisionCheckpointAction]: [],
  [SEARCH_FIELD.guaranteeRecommendedActionRuleId]: '',
  [SEARCH_FIELD.recommendedActionRuleId]: '',
  [SEARCH_FIELD.returnRecommendedActionRuleId]: '',
}

export const defaultSortState: SortState = {
  by: SEARCH_FIELD.normalizedPurchaseCreatedAt,
  order: 'descend',
}

export const defaultSearchState: SearchFilterState = {
  searchTerm: '',
  filters: {
    ...defaultSearchFilters,
  },
  sort: defaultSortState,
}

const searchModel: SearchFilterModel = {
  // State
  ...defaultSearchState,

  // Getters / Setters
  setSearchTerm: action((state, payload) => {
    state.searchTerm = payload
  }),

  setFilterValue: action((state, payload) => {
    Object.assign(state.filters, payload)
  }),

  setSortOrder: action((state, { by, order }) => {
    state.sort = { by, order }
  }),

  populateState: action((state, newState) => {
    Object.assign(state, merge(cloneDeep(defaultSearchState), newState))
  }),
}

export default searchModel
