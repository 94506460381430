import { FC } from 'react'
import { Controller } from 'react-hook-form'
import { Select } from 'antd'
import type { FieldProps } from './Fields.types'
import FormItemWrapper from './FormItemWrapper'
import { useGetLabelText, useGetRequiredError } from './utils'

export const SelectField: FC<FieldProps> = ({
  fieldName,
  options,
  control,
  errors,
  span,
  required,
  disabled,
  loading,
  allowClear = false,
}) => {
  const labelText = useGetLabelText(fieldName)
  const errorText = useGetRequiredError(fieldName, errors)

  return (
    <FormItemWrapper
      span={span}
      labelText={labelText}
      errorText={errorText}
      required={required}
    >
      <Controller
        name={fieldName}
        control={control}
        render={({ field }) => (
          <Select
            showSearch
            onChange={field.onChange}
            value={field.value}
            allowClear={allowClear}
            loading={loading}
            popupMatchSelectWidth={false}
            disabled={disabled}
            data-analytics-id={`select-field-${fieldName}`}
            data-test-id={`selectField-${fieldName}`}
            options={options}
          />
        )}
      />
    </FormItemWrapper>
  )
}

export default SelectField
