import { thunk, action } from 'easy-peasy'
import { message } from 'antd'
import {
  replaceGuaranteeDisposition,
  INV_GUARANTEE_DISPOSITION,
  createCaseNote,
  getResubmitStatus as getResubmitStatusHTTP,
  RESUBMIT_STATUS,
  attachFileToInvestigationNote,
} from '@signifyd/http'
import { i18nInstance } from '@signifyd/components'
import { STATIC_NOTES } from 'store/constants'
import { ResubmitGuaranteeActionModel } from './types'
import { disableAction, enableAction, hideAction } from './caseActions.utils'

type TranslationKeys = 'apiSuccess' | 'apiFailure' | 'apiNoteFailure'

const getTranslation = (translationKey: TranslationKeys): string =>
  i18nInstance.t(`store.caseActions.resubmitGuarantee.${translationKey}`)

const resubmitGuaranteeModel: ResubmitGuaranteeActionModel = {
  isLoading: false,
  setLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  resubmitStatus: undefined,
  setResubmitStatus: action((state, payload) => {
    const { status, reason } = payload

    if (status === RESUBMIT_STATUS.HIDDEN) {
      state.resubmitStatus = hideAction()

      return
    }

    if (status === RESUBMIT_STATUS.DISABLED) {
      const translatedReason = i18nInstance.t(
        `store.caseActions.resubmitGuarantee.reason`,
        {
          context: reason,
        }
      )

      state.resubmitStatus = disableAction(translatedReason)

      return
    }

    state.resubmitStatus = enableAction()
  }),

  // This call triggers three separate API calls, two to create notes and one to actually update the case
  // with the new disposition.
  resubmitForGuarantee: thunk(
    async (actions, { caseDetails, payload, files }) => {
      actions.setLoading(true)

      try {
        await replaceGuaranteeDisposition(
          caseDetails.investigationId,
          INV_GUARANTEE_DISPOSITION.PENDING
        )
      } catch {
        message.error(getTranslation('apiFailure'))

        return
      }

      try {
        await createCaseNote(
          caseDetails.investigationId,
          STATIC_NOTES.CASE_RE_REVIEW
        )

        const { data: createdNote } = await createCaseNote(
          caseDetails.investigationId,
          payload
        )

        const attachFileCalls = files.map((file) =>
          attachFileToInvestigationNote({
            file,
            fileName: file.name,
            investigationId: caseDetails.investigationId,
            noteId: createdNote.noteId,
          })
        )

        await Promise.all(attachFileCalls)

        message.success(getTranslation('apiSuccess'))
      } catch {
        message.error(getTranslation('apiNoteFailure'))
      }

      actions.setLoading(false)
    }
  ),

  getResubmitStatus: thunk(async ({ setResubmitStatus }, caseDetails) => {
    try {
      const { data } = await getResubmitStatusHTTP(caseDetails.investigationId)

      setResubmitStatus(data)
    } catch {
      message.error(getTranslation('apiFailure'))
    }
  }),
}

export default resubmitGuaranteeModel
