import { useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query'
import {
  AdminSettingsBillingPlanResponse,
  getBillingPlanAdminSettings,
} from '@signifyd/http'
import { useEffect } from 'react'

const MINUTE = 60 * 1000

const adminSettingsQueryKey = 'adminSettings'

const adminSettingsQuery = {
  queryKey: [adminSettingsQueryKey],
  queryFn: async () => {
    const { data } = await getBillingPlanAdminSettings()

    return data
  },
}

const useAdminSettings =
  (): UseQueryResult<AdminSettingsBillingPlanResponse> => {
    return useQuery({
      ...adminSettingsQuery,
      keepPreviousData: true,
      staleTime: 5 * MINUTE,
      cacheTime: 10 * MINUTE,
    })
  }

const usePrefetchAdminSettings = (): void => {
  const queryClient = useQueryClient()

  useEffect(() => {
    queryClient.prefetchQuery(adminSettingsQuery)
  }, [queryClient])
}

export { useAdminSettings, usePrefetchAdminSettings }
