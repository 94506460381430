import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { INV_CASE_STATUS, INV_STATUS, InvestigationInfo } from '@signifyd/http'
import { CloseSquareOutlined, FolderOpenOutlined } from '@ant-design/icons'
import { useUpdateCaseStatus } from 'core/queries/useUpdateCaseStatus'
import { getEnableActionState } from 'core/hooks/caseActions/utils'
import ActionButton from '../ActionButton'

interface Props {
  caseDetails: InvestigationInfo
}

export const CaseStatusButton: FC<Props> = ({
  caseDetails: { caseStatus, investigationId },
}) => {
  const { t } = useTranslation()
  const { isLoading, mutate: updateCaseStatus } = useUpdateCaseStatus()

  const closeCaseStatusIcon =
    caseStatus === INV_CASE_STATUS.OPEN ? (
      <CloseSquareOutlined />
    ) : (
      <FolderOpenOutlined />
    )

  return (
    <ActionButton
      onClick={() => {
        updateCaseStatus({
          investigationId,
          payload:
            caseStatus === INV_CASE_STATUS.OPEN
              ? INV_STATUS.DISMISSED
              : INV_STATUS.OPEN,
        })
      }}
      actionState={getEnableActionState()}
      isLoading={isLoading}
      icon={closeCaseStatusIcon}
      text={
        caseStatus === INV_CASE_STATUS.OPEN
          ? t('caseActions.closeCase')
          : t('caseActions.openCase')
      }
    />
  )
}

export default CaseStatusButton
