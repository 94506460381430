import { thunkOn } from 'easy-peasy'
import { ResultsActionsListenersModel } from './types'

const resultsActionListenersModel: ResultsActionsListenersModel = {
  onCurrentTeams: thunkOn(
    (_actions, storeActions) => storeActions.user.setCurrentTeams,
    (actions) => {
      actions.getSearchResults()
    }
  ),
}

export default resultsActionListenersModel
