import { FC, memo, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import { Text, Space } from '@signifyd/components'
import PageSizeContext, { PAGE_SIZE } from 'core/components/PageSizeContext'
import { useStoreState, useStoreActions } from 'store'
import { SavedFilter } from 'store/search'
import SavedFilterItem from './SavedFilterItem'
import styles from './SavedFilters.less'

interface Props {
  onClick: (key: string) => void
  matchingSavedFilter: SavedFilter | undefined
}

const SavedFilters: FC<Props> = ({ onClick, matchingSavedFilter }) => {
  const { t } = useTranslation()

  const savedFilters = useStoreState((state) => state.search.savedFilters)
  const deleteSavedFilter = useStoreActions(
    (actions) => actions.search.deleteSavedFilter
  )

  const pageSize = useContext(PageSizeContext)
  const isLargeSize = pageSize === PAGE_SIZE.LG_AND_LARGER
  const savedFiltersArray = Object.entries(savedFilters)

  return (
    <>
      {!isLargeSize && (
        <div data-test-id="savedFiltersLineBreak">
          <Space size="sm" />
        </div>
      )}
      <Text size="md" transform="uppercase">
        {t('search.quickSearch.savedFilters')}
      </Text>{' '}
      <Tooltip
        arrow={{ pointAtCenter: true }}
        overlayClassName={styles.savedFiltersTooltipOverlay}
        title={
          <Text size="sm" className={styles.savedFiltersTooltip}>
            {t('search.quickSearch.savedFiltersTooltip')}{' '}
            <b>{t('search.quickSearch.savedFiltersDateNote')}</b>
          </Text>
        }
        placement="topRight"
      >
        <QuestionCircleOutlined />
      </Tooltip>
      <div className={styles.wrapper}>
        {savedFiltersArray.length === 0 && (
          <Text size="md" className={styles.noSavedFilters}>
            {t('search.quickSearch.noSavedFilters')}
          </Text>
        )}
        {savedFiltersArray.map(([key, { title }]) => (
          <SavedFilterItem
            onClick={onClick}
            onDelete={deleteSavedFilter}
            itemKey={key}
            title={title}
            key={key}
            flex={isLargeSize}
            isCurrentFilter={title === matchingSavedFilter?.title}
          />
        ))}
      </div>
    </>
  )
}
export default memo(SavedFilters)
