import {
  CheckpointActionTag,
  i18nInstance,
  ReturnCheckpointMiniIcon,
} from '@signifyd/components'
import { Tooltip } from 'antd'
import { Decision } from '@signifyd/http'
import { FC } from 'react'
import ReturnPolicy from 'pages/SearchResultsPage/components/SearchResultsTable/ReturnPolicy'
import styles from './ReturnTag.less'

interface Props {
  decision: Decision
}

const ReturnWithPolicy: FC<Props> = ({ decision }) => {
  const { checkpointAction, checkpointActionPolicy, policies } = decision

  return (
    <Tooltip
      title={
        <div className={styles.tooltip}>
          <span>
            {i18nInstance.t(
              `returnTag.tooltip.returnSetTo.${checkpointAction}`
            )}
          </span>
          <span>
            <ReturnPolicy
              policies={policies}
              checkpointActionPolicy={checkpointActionPolicy}
            />
          </span>
        </div>
      }
    >
      <div>
        <CheckpointActionTag
          action={checkpointAction}
          icon={ReturnCheckpointMiniIcon}
        />
      </div>
    </Tooltip>
  )
}

export default ReturnWithPolicy
